<template>
  <v-dialog v-model="dialog" max-width="400px" @click:outside="onClose">
    <template v-slot:activator="{ on }">
      <v-btn large :disabled="disableStatus" :color="color" v-on="on" data-test="activate-button">
        {{ buttonText }} 
      </v-btn>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title class="subtitle-2">How many {{ toolbarText }} would you like to add</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="close-button">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12">
              <v-row dense class="mt-2">
                <v-col cols="12" md="8">
                  <v-text-field
                    dense
                    name="inputQuantity"
                    type="number"
                    hide-details
                    v-model="inputQuantity"
                    @keypress.enter.stop="onCreate"
                    onInput="if (this.value < 0) this.value = 0;"
                    data-test="input-quantity"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    block
                    :disabled="inputQuantity <= 0"
                    color="secondary"
                    @click="onCreate"
                    data-test="create-button"
                    >Add</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "InputQtyTextField",
  props: {
    buttonText: {
      type: String,
      default: "Input Quantity",
    },
    color: {
      type: String,
      default: "secondary",
    },
    disableStatus: {
      type: Boolean,
      default: true,
    },
    toolbarText: {
      type: String,
      default: "How many would you like to add?",
    },
  },
  data() {
    return {
      dialog: false,
      inputQuantity: 0,
    };
  },
  methods: {
    onClose() {
      this.inputQuantity = 0;
      this.dialog = false; 
    },
    onCreate() {
      this.$emit("onAcceptInputQuantity", this.inputQuantity);
      this.onClose();
    },
  },
};
</script>