export default () => ({
  fixture_design: {
    design_file_packages: [],
    fixture_config: {
      pk: null,
      description: null,
      center_coordinate: null,
    },
    dut_config: {
      testpoints: [],
      guide_pins: [],
    },
  },
  //invoice: {},
  //customers: [],
  fd_state: [],
  ir_state: [],
  fa_state: [],
  design_file_options: [],
  design_file_packages: [],
})