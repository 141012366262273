import store from "@/store/index.js";

export const DesignRoutes = {
  path: "/design-packages",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "project/:id",
      name: "design-packages",
      component: () => import("./views/DesignPackagePage.vue"),
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of Design Package Page");
        try {
          await store.dispatch("fixturedesign/getFixtureDesignState");
          await Promise.all([
            store.dispatch("fixturedesign/getFixtureDesign", {
              params: { project_id: to.params.id },
            }),
            //store.dispatch("fixturedesign/getDesignFileOptions"),
            store.dispatch("dutconfigs/getFixtureDesignOptions"),
            store.dispatch("projects/fetchProject", to.params.id),
          ]);
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            "Failed to process request (Design File Package), try later."
          );
        }
      },
    },
  ]
};