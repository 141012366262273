<template>
  <div>
    <v-form ref="form" class="my-5 mx-3 font-weight-light">
      <v-text-field
        label="Email"
        name="email"
        prepend-icon="mdi-email"
        type="text"
        v-model.lazy="email"
        :rules="rules.email"
        ref="usernameFocus"
        autocomplete="off"
        data-cy="auth-input-email"
      />
      <v-text-field
        label="Password"
        name="password"
        prepend-icon="mdi-key-variant"
        v-model.lazy="password"
        :rules="rules.password"
        @keyup.enter="onSignIn"
        :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="togglePassword = !togglePassword"
        :type="togglePassword ? 'text' : 'password'"
        data-cy="auth-input-password"
      />
    </v-form>
    <v-row class="mx-3">
      <p
        class="font-weight-regular moduleLink"
        @click="gotoLink('/reset-password')"
      >
        Forgot password?
      </p>
    </v-row>
    <v-row class="px-5 my-5">
      <v-btn
        dark
        class="font-weight-medium propercase"
        color="primary"
        @click="onSignIn"
        block
        data-cy="auth-sign-in-button"
      >
        Sign in
      </v-btn>
    </v-row>
    <v-row class="mx-3">
      <p>
        Create new account?
        <span
          class="pl-2 font-weight-regular moduleLink"
          @click="gotoLink('/signup')"
          data-cy="auth-sign-up-button"
          >Sign Up!</span
        >
      </p>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "SignIn",
  data() {
    return {
      DEFAULT_ROUTE: "quotes",
      togglePassword: false,
      authError: false,
      error: null,
      show: false,
      email: null,
      password: null,
      rules: {
        email: [
          (v) => !!v || "Email is required",
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Email is invalid";
          },
        ],
        password: [(v) => !!v || "Password is required"],
      },
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  methods: {
    gotoLink(link) {
      this.$router.push(link);
    },
    async onSignIn() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.authError = false;
      const actionPayload = {
        password: this.password,
        email: this.email,
      };
      try {
        await this.$store.dispatch("auth/signIn", actionPayload);
        this.$store.dispatch("ui/newUpdate", true);
        this.$store.dispatch("ui/getBannerMessage");
        if (
          this.$route.query.redirect &&
          !this.$route.query.redirect.includes("auth")
        ) {
          this.$router.replace(this.$route.query.redirect);
        } else {
          this.$router.replace({ name: this.DEFAULT_ROUTE });
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to authenticate, try later."
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch("ui/snackBar", null);
    this.$store.dispatch("ui/loading", null);
    this.$store.dispatch("auth/clearModuleData", null);
  },
};
</script>
