export default {
  SET_TEST_POINTS(state, results) {
    state.test_points.push(...results.map((o) => ({
      ...o,
      x: o.x.toFixed(4),
      y: o.y.toFixed(4),
      min_spacing: o.min_spacing.toFixed(4),
      receptaclePk: o.receptacle ? o.receptacle.pk : o.receptacle,
      pitch: o.receptacle ? o.receptacle.pitch : null,
      probePk: o.probe ? o.probe.pk : o.probe,
      tip_style: o.probe ? o.probe.tip_style : null,
      layerPk: o.layer,
      typePk: o.type,
      edited: false,
      x_mils: (o.x * (1 / 0.0254)).toFixed(4),
      y_mils: (o.y * (1 / 0.0254)).toFixed(4),
      min_spacing_mils: (o.min_spacing * (1 / 0.0254)).toFixed(4),
    })));
    state.count = state.test_points.length;
  },
  UPDATE_TEST_POINT(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      Object.assign(record, payload);
    } else {
      state.test_points.push(payload);
    }
  },
  UPDATE_RECEPTACLE(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      if (+record.receptaclePk !== +payload.data.pk) {
        record["probe"] = null;
        record["probePk"] = null;
        record["tip_style"] = null;
      }
      record["receptacle"] = payload.data.name;
      record["receptaclePk"] = payload.data.pk;
      record["pitch"] = payload.data.pitch;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_CURRENT_TP(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      const clonedTP = JSON.parse(JSON.stringify(record));
      const current = state.current_tp.find(
        (element) => element.pk == payload.pk
      );
      if (current) {
        Object.assign(current, clonedTP);
      } else {
        state.current_tp.push(clonedTP);
      }
    }
  },
  UPDATE_PROBE(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["probe"] = payload.data.name;
      record["probePk"] = payload.data.pk;
      record["tip_style"] = payload.data.tip_style;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_LAYER(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["layer"] = payload.data.name;
      record["layerPk"] = payload.data.pk;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_TYPE(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["type"] = payload.data.name;
      record["typePk"] = payload.data.pk;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_DUT_CONFIG(state, { record, dutPk }) {
    const recorded = state.test_points.find(
      (element) => element.pk == record.pk
    );
    if (recorded) {
      recorded["dut_config"] = dutPk;
    }
  },
  UPDATE_COORDINATE(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record[payload.data.propsKey] = (payload.data[payload.data.propsKey]).toFixed(4);
      record[`${payload.data.propsKey}_mils`] = (payload.data[payload.data.propsKey] * (1 / 0.0254)).toFixed(4);
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_COORDINATE_MILS(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record[payload.data.propsKey] = (payload.data[payload.data.propsKey]).toFixed(4);
      record[payload.data.propsKey.split("_")[0]] = (payload.data[payload.data.propsKey] * 0.0254).toFixed(4);
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_DIRECT_SYNC(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record[payload.data.propsKey] = payload.data[payload.data.propsKey];
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_MIN_SPACING(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["min_spacing"] = (payload.min_spacing * 1).toFixed(4);
      record["min_spacing_mils"] = (payload.min_spacing * (1 / 0.0254)).toFixed(4);
    }
  },
  UPDATE_MIN_SPACING_MILS(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["min_spacing_mils"] = (payload.min_spacing * 1).toFixed(4);
      record["min_spacing"] = (payload.min_spacing * 0.0254).toFixed(4);
    }
  },
  UPDATE_EDITED(state) {
    state.edited_tp = JSON.parse(JSON.stringify(state.test_points));
    state.current_tp = JSON.parse(JSON.stringify(state.test_points));
  },
  UPDATE_EDITED_BY_ID(state, payload) {
    const record = state.edited_tp.find((element) => element.pk == payload.pk);
    if (record) {
      Object.assign(record, payload);
    }
  },
  DELETE_TESTPOINT(state, id) {
    const record = state.test_points.find((element) => element.pk == id);
    if (record) {
      state.test_points.splice(state.test_points.indexOf(record), 1);
    }
  },
  INIT_EDITED(state, payload) {
    const record = state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["edited"] = !record["edited"];
    }
  },
  INIT_TEST_POINTS(state) {
    state.test_points.splice(0);
    state.edited_tp.splice(0);
    state.current_tp.splice(0);
    state.count = null;
  },
  RESTORE_TEST_POINT(state, pk) {
    const record = state.edited_tp.find((element) => element.pk == pk);
    if (record) {
      const testpoint = state.test_points.find((element) => element.pk == pk);
      if (testpoint) {
        Object.assign(testpoint, record);
        testpoint["edited"] = false;
      }
    }
  },
  RESTORE_ALL_TEST_POINTS(state) {
    state.test_points = JSON.parse(JSON.stringify(state.edited_tp));
  },
  REVERT_CELL_OBJECT(state, { type, pk }) {
    const record = state.current_tp.find((element) => element.pk == pk);
    if (record) {
      const valType = record[type]
      const testpoint = state.test_points.find((element) => element.pk == pk);
      if (testpoint) {
        testpoint[type] = valType;
      }
    }
  },
};
