<template>
  <v-badge :content="itemCount" bordered overlap color="warning"
    ><v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
          v-if="useIcon"
          large
          color="primary"
          :class="marginClass"
          v-on="on"
          >{{ viewIcon }}</v-icon
        >
        <v-avatar
          v-else
          :color="color"
          size="30"
          :class="marginClass"
          v-on="on"
        >
          <span class="white--text text-caption">{{ viewIcon }}</span>
        </v-avatar>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </v-badge>
</template>
<script>
export default {
  name: "BadgeCounter",
  props: [
    "itemCount",
    "tooltipText",
    "viewIcon",
    "useIcon",
    "leftMargin",
    "color",
  ],
  computed: {
    marginClass() {
      return `ml-${this.leftMargin}`;
    },
  },
};
</script>
