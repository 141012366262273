import store from "@/store/index.js";

export const CompanyRoutes = {
  path: "/company-profile",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "/",
      name: "company-profile",
      component: () => import("./views/CompanyPage.vue"),
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of Company Page");
        try {
          await store.dispatch(
            "projects/fetchUserById",
            store.getters["auth/user"].pk
          );
          await store.dispatch("projects/fetchCompanies");
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      },
    },
  ],
};
