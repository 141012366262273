import jwtInterceptor from "@/helpers/interceptor";

export default {
  getTestPoints(params) {
    return jwtInterceptor.get(process.env.VUE_APP_TESTPOINTS_URL, params);
  },
  createTestPoint(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_TESTPOINTS_URL, payload);
  },
  patchTestPoint(id, data) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_TESTPOINTS_URL}${id}/`, data);
  },
  deleteTestPointById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_TESTPOINTS_URL}${id}/`);
  }
}
