export default () => ({
  test_pop: true,
  step_pop: true,
  miniVariant: false,
  loading: {
    status: false,
    message: null
  },
  snackBar: {
    status: false,
    message: null,
  },
  snackLink: {
    status: false,
    message1: null,
    message2: null,
    link: null,
  },
  error_page: false,
  circular_loading: false,
  circular_value: 0,
  render_event: false,
  new_update: true,
  new_invoice: true,
  project_search: {
    type: null,
    value: null,
  },
  dutconfig_id: null,
  fd_state_longlist: [
    {
      pk: 1,
      description: "Any updates to this fixture design will be reflected below.",
    },
    { pk: 2,
      description: "This design is now Read Only. Any updates made by FixturFab during the internal review will be reflected below.",
    },
    {
      pk: 3,
      description: "This design has been finalized and will soon be fabricated and assembled.",
    },
    {
      pk: 4,
      description: "The assembled fixture has been shipped!",
    }
  ],
  project_search_string: '',
  user_and_company: {
    full_name: "",
    company_request: "",
    billing_address_request: "",
    shipping_same_as_billing_request: null,
    shipping_address_request: "",
  },
  fixture_details: {
    project_name: null,
    number_of_unique_duts: null,
    dut_name_1: null,
    dut_name_2: null,
    project_timeline: null,
    project_timeline_details: null,
    estimated_volumes: null,
    project_type: null,
    fixture_wiring: null,
    test_instrumentation_selection_and_integration: false,
    test_software_development: false,
    custom_back_plate: false,
    number_of_fixtures: null,
    panelized_fixture: false,
    duts_in_panel: null,
    duts_per_row: null,
    duts_per_column: null,
    panel_details: null,
    additional_services_details: "",
  },
  dut_ui_details: {
    dut_name_0: null,
    pcb_width_0: null,
    pcb_height_0: null,
    pcb_thickness_0: null,
    flex_board_0: false,
    dual_sided_probing_0: false,
    test_point_file_0: null,
    step_file_0: null,
    gerber_zip_0: null,
    zipped_gerbers_0: null,
    odb_zip_0: null,
    ipc_zip_0: null,
    schematic_file_0: null,
    other_file_0: null,
    additional_comments_0: "",
    dut_name_1: null,
    pcb_width_1: null,
    pcb_height_1: null,
    pcb_thickness_1: null,
    flex_board_1: false,
    dual_sided_probing_1: false,
    test_point_file_1: null,
    step_file_1: null,
    gerber_zip_1: null,
    zipped_gerbers_1: null,
    odb_zip_1: null,
    ipc_zip_1: null,
    schematic_file_1: null,
    other_file_1: null,
    additional_comments_1: "",
  },
  quote_tabs: [
    {
      title: "User and Company",
      subtitle: "."
    },
    {
      title: "Quote Setup",
      subtitle: "."
    },
    {
      title: "Fixture Details",
      subtitle: "."
    },
  ],
  project_tabs: [
    {
      title: "User and Company",
      subtitle: "."
    },
    {
      title: "Project Setup",
      subtitle: "."
    }
  ],
  project_details: {
    project_name: null,
    number_of_duts: null,
    dut_name_1: null,
    dut_name_2: null,
  },
  redirect_fullpath: null,
  billing_address: {
    name: null,
    company_name: null,
    email: null,
    phone: null,
    street1: null,
    street2: null,
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  shipping_address: {
    name: null,
    company_name: null,
    email: null,
    phone: null,
    street1: null,
    street2: null,
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  shipping_same_as_billing: true,
  background_loading: false,
  hover_text: null,
  banner_message: null,
})