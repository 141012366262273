import PressurePinService from "./endpoints";

export default {
  async getPressurePinList(context, { params, dutParams, fdId }) {
    try {
      context.commit("INIT_PRESSURE_PINS");
      const init_response = await PressurePinService.getPressurePins({
        params: { dc_pk: dutParams.params.dc_pk, page: 1 },
      });
      context.commit("SET_PRESSURE_PINS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await PressurePinService.getPressurePins({
            params: { dc_pk: dutParams.params.dc_pk, page: i },
          });
          context.commit("SET_PRESSURE_PINS", response.data.results);
        }
      }
      context.dispatch("initAssembly", { params, fdId });
      context.dispatch("updateEdited");
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updatePressurePins(context, { payload, dutId, projectId, fdId }) {
    context.dispatch("ui/loading", "Updating", { root: true });
    try {
      if (payload.editMode) {
        await PressurePinService.patchPressurePin(
          payload.data.pk,
          payload.data
        );
      } else {
        await PressurePinService.createPressurePin(payload.data);
      }
      await context.dispatch("getPressurePinList", {
        params: { params: { project_id: projectId } },
        dutParams: { params: { dc_pk: dutId } },
        fdId,
      });
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "updatePressurePins" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async updateInline(context, { payload, projectId, fdId }) {
    context.dispatch("ui/loading", "Saving", { root: true });
    try {
      const patchPayload = {
        pk: payload.data.pk,
        project: payload.data.project,
        name: payload.data.name,
        x: payload.data.x,
        y: payload.data.y,
        pressure_pin_assembly: payload.data.pressure_pin_assemblyPk,
        enabled: payload.data.enabled,
      };
      await PressurePinService.patchPressurePin(patchPayload.pk, patchPayload);
      context.dispatch("initEdited", payload.data);
      context.dispatch("updateEditedById", payload.data);
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "updateInline-PP" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async updateAllEdited(context, { arrayList, projectId, fdId, pressurepins }) {
    context.dispatch("ui/loading", "Saving", { root: true });
    try {
      await context.dispatch("dutconfigs/updateTestPointsBlob", arrayList, { root: true });
      await context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "updateAllEdited-PP" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
    pressurepins.forEach((pressurepin) => {
      const payload = {
        editMode: true,
        data: {
          pk: pressurepin.pk,
          project: pressurepin.project,
          name: pressurepin.name,
          x: pressurepin.x,
          y: pressurepin.y,
          pressure_pin_assembly: pressurepin.pressure_pin_assemblyPk,
          enabled: pressurepin.enabled,
        },
      };
      context.dispatch("initEdited", payload.data);
      context.dispatch("updateEditedById", pressurepin);
    });
  },
  async deletePressurePin(context, { payload, dutId, projectId, fdId }) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await PressurePinService.deletePressurePinById(payload.pk);
      await context.dispatch("getPressurePinList", {
        params: { params: { project_id: projectId } },
        dutParams: { params: { dc_pk: dutId } },
        fdId,
      });
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "deletePressurePin" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async deleteSelectedPressurePins(context, { payload, dutId, projectId, fdId }) {
    context.dispatch("ui/loading", "Deleting Selected Pressure Pins", { root: true });
    try {
      for (let i = 0; i < payload.length; i++) {
        await PressurePinService.deletePressurePinById(payload[i].pk);
      }
      await context.dispatch("getPressurePinList", {
        params: { params: { project_id: projectId } },
        dutParams: { params: { dc_pk: dutId } },
        fdId,
      });
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "deleteSelectedPressurePins" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  deleteAllPressurePins(context) {
    context.commit("INIT_PRESSURE_PINS");
  },
  restorePressurePin(context, pk) {
    context.commit("RESTORE_PRESSURE_PIN", pk);
  },
  restoreAllPressurePins(context) {
    context.commit("RESTORE_ALL_PRESSURE_PINS");
  },
  initEdited(context, payload) {
    context.commit("INIT_EDITED", payload);
  },
  updatePressurePin(context, payload) {
    context.commit("UPDATE_PRESSURE_PIN", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_PP", payload);
  },
  updateDirectSync(context, payload) {
    context.commit("UPDATE_DIRECT_SYNC", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_PP", payload);
  },
  updateCoordinate(context, payload) {
    context.commit("UPDATE_COORDINATE", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_PP", payload);
  },
  updateCoordinateMils(context, payload) {
    context.commit("UPDATE_COORDINATE_MILS", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_PP", payload);
  },
  updateDutConfig(context, payload) {
    context.commit("UPDATE_DUT_CONFIG", payload);
  },
  updateEdited(context, payload) {
    context.commit("UPDATE_EDITED", payload);
  },
  updateEditedById(context, payload) {
    context.commit("UPDATE_EDITED_BY_ID", payload);
  },
  initAssembly(context) {
    const dutPk = context.rootGetters["dutconfigs/dutConfigs"].pk;
    context.state.pressure_pins.forEach(async (record) => {
      if (!record.pressure_pin_assembly) {
        context.dispatch("autoAssembly", record);
        context.dispatch("updateDutConfig", { record, dutPk });
        const actionPayload = {
          pk: record.pk,
          project: record.project,
          pressure_pin_assembly: record.pressure_pin_assemblyPk,
          dut_config: dutPk,
        };
        try {
          await PressurePinService.patchPressurePin(
            actionPayload.pk,
            actionPayload
          );
        } catch (err) {
          context.dispatch("errorHandler", err);
        }
      } else {
        context.dispatch("namedAssembly", record);
      }
    });
  },
  autoAssembly(context, record) {
    const assemblyOptions = context.rootGetters[
      "dutconfigs/pressurePins"
    ].filter((element) => {
      return element.fixtures.includes(
        context.rootGetters["fixturedesign/fixtureConfig"].pk
      );
    });
    const assemblyPayload =
      assemblyOptions.length > 0
        ? {
            pk: record.pk,
            data: {
              pk: assemblyOptions[0].pk,
              name: assemblyOptions[0].description,
              edited: record.edited,
            },
          }
        : {
            pk: record.pk,
            data: {
              pk: null,
              name: null,
              edited: record.edited,
            },
          };
    context.dispatch("updatePressurePin", assemblyPayload);
  },
  namedAssembly(context, record) {
    const assembly = context.rootGetters["dutconfigs/pressurePins"].find(
      (element) => element.pk == record.pressure_pin_assemblyPk
    );
    const assemblyPayload = {
      pk: record.pk,
      data: {
        pk: record.pressure_pin_assemblyPk,
        name: assembly ? assembly.description : null,
        edited: record.edited,
      },
    };
    context.dispatch("updatePressurePin", assemblyPayload);
  },
  revertCellObject(context, payload) {
    context.commit("REVERT_CELL_OBJECT", payload);
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
