export default {
  SET_QUOTES(state, payload) {
    state.quote_list.push(...payload);
  },
  SET_QUOTE(state, payload) {
    state.quote = payload;
  },
  SET_QUOTE_STATUS(state, payload) {
    state.quote_status.splice(0);
    state.quote_status.push({ pk: 1, description: "All" });
    state.quote_status.push(
      ...payload.actions.POST.status.choices.map((o, index) => ({
        pk: index + 2,
        description: o.display_name,
      }))
    );
    state.project_timeline = payload.actions.POST.project_timeline.choices.map(
      (o) => ({
        pk: o.value,
        description: o.display_name,
      })
    );
    state.project_type = payload.actions.POST.project_type.choices.map((o) => ({
      pk: o.value,
      description: o.display_name,
    }));
    state.project_type.pop();
    state.fixture_wiring = payload.actions.POST.fixture_wiring.choices.map(
      (o) => ({
        pk: o.value,
        description: o.display_name,
      })
    );
  },
  SET_BILLING_ADDRESS(state, payload) {
    state.billing_address = payload;
  },
  SET_SHIPPING_ADDRESS(state, payload) {
    state.shipping_address = payload;
  },
  SET_DUT_DETAILS_OPTIONS(state, payload) {
    state.dut_units.splice(0);
    state.dut_units = payload.actions.POST.units.choices.map((o) => ({
      pk: o.value,
      description: o.display_name,
    }));
  },
  SET_FIELD_DESCRIPTIONS(state, payload) {
    state.field_descriptions = payload;
  },
  SET_DUT_FIELD_DESCRIPTIONS(state, payload) {
    state.dut_field_descriptions = payload;
  },
  CLEAR_QUOTES(state) {
    state.quote_list.splice(0);
  },
  DELETE_QUOTE(state, id) {
    const record = state.quote_list.find((element) => element.id == id);
    if (record) {
      state.quote_list.splice(state.quote_list.indexOf(record), 1);
    }
  },
  SET_DUT_DETAILS(state, payload) {
    state.dut_details = payload;
    if (payload.length > 0) {
      state.gerber_files.push(payload[0].gerber_layer_file);
    }
    if (payload.length > 1) {
      state.gerber_files.push(payload[1].gerber_layer_file);
    }
  },
  INIT_GERBER_FILES_BY_DUT(state, payload) {
    state.gerber_files.splice(payload,1);
  },
  INIT_GERBER_LAYER_FILES(state) {
    state.gerber_files.splice(0);
  },
  SET_DUT_DETAILS_VIEW(state, payload) {
    state.dut_details_view = payload;
  },
  ADD_DUT_DETAILS(state, payload) {
    state.dut_details.push(payload);
  },
  UPDATE_DUT_DETAIL(state, payload) {
    const record = state.dut_details.find(
      (element) => element.id == payload.id
    );
    if (record) {
      Object.assign(record, payload);
    }
  },
  DELETE_DUT_DETAIL(state, index) {
    state.dut_details[index] = {
      dut_name: null,
    };
  },
  UPDATE_QUOTE_LIST(state, payload) {
    const record = state.quote_list.find((element) => element.id == payload.id);
    if (record) {
      Object.assign(record, payload);
    }
  },
  SET_GERBER_FILES(state, payload) {
    state.dut_configs.push(payload);
    state.pcb_stackup.push(
      payload.pcb_stackup.length > 0 ? JSON.parse(payload.pcb_stackup) : null
    );
  },
  SET_SORTED_DUT(state, payload) {
    if (Object.keys(payload.fixture_design_details).length > 0) {
      state.sorted_dut = Object.values(
        payload.fixture_design_details
      )[0]?.dut_configs.sort((a, b) => (a > b ? 1 : -1));
    }
  },
  SET_PARSED_FILE(
    state,
    {
      test_points,
      guide_pins,
      pressure_pins,
      holes,
      dut_data,
      missing_optional_headers,
    }
  ) {
    state.parsed_json_blob = {
      test_points,
      guide_pins,
      pressure_pins,
      holes,
      dut_data,
      missing_optional_headers,
    };
    state.parsed_test_points = test_points;
    state.parsed_guide_pins = guide_pins;
    state.parsed_pressure_pins = pressure_pins;
    state.parsed_holes = holes;
    state.parsed_dut_data = dut_data;
    state.parsed_missing_headers = missing_optional_headers;
    state.blank_svg.top.units = dut_data.units;
    state.blank_svg.top.width = dut_data.dut_length;
    state.blank_svg.top.height = dut_data.dut_width;
    state.blank_svg.top.viewBox[2] = dut_data.dut_length * 1e3;
    state.blank_svg.top.viewBox[3] = dut_data.dut_width * 1e3;
  },
  SET_DUT_CONFIGS(state, payload) {
    state.dut_configs.push(payload);
  },
  RECOMPUTE_UOM(state, unitSelected) {
    state.parsed_test_points.forEach((testpoint) => {
      (testpoint.x = testpoint.x
        ? testpoint.x * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
        : testpoint.x),
        (testpoint.y = testpoint.y
          ? testpoint.y * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
          : testpoint.y),
        (testpoint.min_spacing = testpoint.min_spacing
          ? testpoint.min_spacing * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
          : testpoint.min_spacing);
    });
    state.parsed_guide_pins.forEach((guidepin) => {
      (guidepin.x = guidepin.x
        ? guidepin.x * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
        : guidepin.x),
        (guidepin.y = guidepin.y
          ? guidepin.y * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
          : guidepin.y);
    });
    state.parsed_pressure_pins.forEach((pressurepin) => {
      (pressurepin.x = pressurepin.x
        ? pressurepin.x * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
        : pressurepin.x),
        (pressurepin.y = pressurepin.y
          ? pressurepin.y * (unitSelected === 1 ? 0.0254 : 1 / 0.0254)
          : pressurepin.y);
    });
  },
  INIT_DUT_CONFIGS(state) {
    state.dut_configs = [];
  },
  INIT_PARSED_FILES(state) {
    state.parsed_json_blob = {};
    state.parsed_test_points = [];
    state.parsed_guide_pins = [];
    state.parsed_pressure_pins = [];
    state.parsed_holes = [];
    state.parsed_dut_data = {};
    state.parsed_missing_headers = [];
  },
  INIT_GERBER_FILES(state) {
    state.gerber_files = [];
    state.dut_configs = [];
    state.pcb_stackup = [];
  },
  INIT_QUOTE_AND_DUT(state) {
    state.quote = {};
    state.dut_details = [
      {
        dut_name: null,
      },
      {
        dut_name: null,
      },
    ];
    state.gerber_files = [];
    state.sorted_dut = [];
    state.parsed_json_blob = {};
    state.parsed_test_points = [];
    state.parsed_guide_pins = [];
    state.parsed_pressure_pins = [];
    state.parsed_holes = [];
    state.parsed_dut_data = {};
    state.parsed_missing_headers = [];
    state.pcb_stackup = [];
    state.dut_configs = [];
    state.billing_address = null;
    state.shipping_address = null;
  },
  RESET(state) {
    state.quote_list = [];
    state.quote_status = [];
    state.project_timeline = [];
    state.project_type = [];
    state.fixture_wiring = [];
    state.field_descriptions = {};
    state.dut_field_descriptions = {};
    state.quote = {};
    state.dut_details = [
      {
        dut_name: null,
      },
      {
        dut_name: null,
      },
    ];
    state.dut_details_view = [];
    state.gerber_files = [];
    state.sorted_dut = [];
    state.parsed_json_blob = {};
    state.parsed_test_points = [];
    state.parsed_guide_pins = [];
    state.parsed_pressure_pins = [];
    state.parsed_holes = [];
    state.parsed_dut_data = {};
    state.parsed_missing_headers = [];
    state.pcb_stackup = [];
    state.blank_svg = {
      top:{
        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 0 0" style="enable-background:new 0 0 0 0;" xml:space="preserve">
          <g>
          </g>
          </svg>`,
        attributes: {
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          ["xmlns:xlink"]: "http://www.w3.org/1999/xlink",
          ["stroke-linecap"]: "round",
          ["stroke-linejoin"]: "round",
          ["stroke-width"]: 0,
          ["fill-rule"]: "evenodd",
          ["clip-rule"]: "evenodd",
          id: "orRjq2U5RysF_top",
          viewBox: "0 0 0 0",
        },
        defs: [],
        viewBox: [0, 0, 0, 0],
        width: 0,
        height: 0,
        units: "mm",
      },
      bottom:{
        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 0 0" style="enable-background:new 0 0 0 0;" xml:space="preserve">
          <g>
          </g>
          </svg>`,
        attributes: {
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          ["xmlns:xlink"]: "http://www.w3.org/1999/xlink",
          ["stroke-linecap"]: "round",
          ["stroke-linejoin"]: "round",
          ["stroke-width"]: 0,
          ["fill-rule"]: "evenodd",
          ["clip-rule"]: "evenodd",
          id: "orRjq2U5RysF_bot",
          viewBox: "0 0 0 0",
        },
        defs: [],
        viewBox: [0, 0, 0, 0],
        width: 0,
        height: 0,
        units: "mm",
      },
    };
    state.dut_configs = [];
    state.dut_units = [];
  },
  SET_ADDRESSES(state, payload) {
    state.addresses.push(...payload);
  },
  INIT_QUOTES_LIST(state) {
    state.quote_list.splice(0);
  },
  INIT_ADDRESSES(state) {
    state.addresses.splice(0);
  },
};
