import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#002F36',
        secondary: '#005C69',
        tertiary: '#023618',
        accent: '#757575',
        alert: '#5E0035',
        info: '#020202',
        success: '#4CAF50',
        warning: '#950952'
      },
    },
  },
});
