<template>
  <v-card class="mx-auto" height="300" outlined @click.native="clickHandler" data-test="card">
    <v-card-title>
      <v-alert
        class="body-2 white--text mt-n8"
        color="primary"
        dense
        data-test="alert"
        >{{ type }}</v-alert
      >
    </v-card-title>
    <v-layout
      v-if="item.pk"
      align="center"
      class="justify-center pa-4 mt-n12"
      row
    >
      <item-view>
        <template v-slot:image>
          <v-img
            :src="item.thumbnail"
            :aspect-ratio="16 / 9"
            contain
            class="ma-1"
          ></v-img>
        </template>
        <template v-slot:name>
          <p class="line-clamp body1 text-center alert--text px-1" data-test="name">
            {{ item.name }}
          </p>
        </template>
        <template v-slot:description>
          <p class="line-clamp2 caption text-center primary--text px-1" data-test="description">
            {{ item.description }}
          </p>
        </template>
      </item-view>
    </v-layout>
    <v-layout
      v-else
      fill-height
      class="align-center justify-center pa-4 mt-n8"
      row
    >
      <span class="body-1 warning--text" data-test="alt-message"
        >[ Click here to select {{ type }}]</span
      >
    </v-layout>
  </v-card>
</template>
<script>
export default {
  name: "ItemContainer",
  components: {
    ItemView: () => import("./ItemView.vue"),
  },
  props: {
    type: String,
    item: Object,
  },
  methods: {
    clickHandler() {
      this.$emit("clickHandler");
    }
  }
};
</script>
