export default {
  quotes(state) {
    return state.quote_list;
  },
  quote(state) {
    return state.quote;
  },
  quoteStatus(state) {
    return state.quote_status;
  },
  projectTimeline(state) {
    return state.project_timeline;
  },
  projectType(state) {
    return state.project_type;
  },
  fixtureWiring(state) {
    return state.fixture_wiring;
  },
  fieldDescriptions(state) {
    return state.field_descriptions;
  },
  dutFieldDescriptions(state) {
    return state.dut_field_descriptions;
  },
  dutDetails(state) {
    return state.dut_details;
  },
  dutDetailsView(state) {
    return state.dut_details_view;
  },
  gerberFiles(state) {
    return state.gerber_files;
  },
  sortedDut(state) {
    return state.sorted_dut;
  },
  parsedJsonBlob(state) {
    return state.parsed_json_blob;
  },
  parsedTestPoints(state) {
    return state.parsed_test_points;
  },
  parsedGuidePins(state) {
    return state.parsed_guide_pins;
  },
  parsedPressurePins(state) {
    return state.parsed_pressure_pins;
  },
  parsedHoles(state) {
    return state.parsed_holes;
  },
  parsedDutData(state) {
    return state.parsed_dut_data;
  },
  parsedMissingHeaders(state) {
    return state.parsed_missing_headers;
  },
  pcbStackup(state) {
    return state.pcb_stackup;
  },
  blankSvg(state) {
    return state.blank_svg;
  },
  dutConfigs(state) {
    return state.dut_configs;
  },
  dutUnits(state) {
    return state.dut_units;
  },
  billingAddress(state) {
    return state.billing_address;
  },
  shippingAddress(state) {
    return state.shipping_address;
  },
  addresses(state) {
    return state.addresses;
  },
};
