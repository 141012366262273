import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/baseui'
import './plugins/filters'
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://000aa86f6c2c80b3bbe8d84f802d33c1@o285738.ingest.sentry.io/4505725366697984",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://design-platform.fixturfab.com"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
