export default {
  SET_DUT_CONFIGS(state, payload) {
    state.dut_configs = payload;
    if (payload.pcb_stackup.length > 0) state.pcb_stackup = JSON.parse(payload.pcb_stackup);
  },
  SET_GUIDE_PINS(state, payload) {
    state.guidepins.push(...payload);
  },
  SET_PRESSURE_PINS(state, payload) {
    state.pressurepins.push(...payload);
  },
  SET_RECEPTACLES(state, payload) {
    state.receptacles.push(...payload);
  },
  SET_PROBES(state, payload) {
    state.probes.push(...payload);
  },
  SET_TEST_POINT_OPTIONS(state, payload) {
    state.testpoint_layers = payload.actions.POST.layer.choices.map((o) => ({
      pk: o.value,
      description: o.display_name,
    }));
    state.testpoint_types = payload.actions.POST.type.choices.map((o) => ({
      pk: o.value,
      description: o.display_name,
    }));
  },
  SET_GUIDE_PIN_OPTIONS(state, payload) {
    state.guidepin_layers = payload.actions.POST.layer.choices.map((o) => ({
      pk: o.value,
      description: o.display_name,
    }));
  },
  SET_FIXTURE_DESIGN_OPTIONS(state, payload) {
    state.render_settings = payload.actions.POST.render_setting.choices.map(
      (o) => ({
        pk: o.value,
        description: o.display_name,
      })
    );
  },
  SET_ALL_DUT_CONFIGS(state, payload) {
    state.dut_configs_list.push(...payload); //= payload;
  },
  UPDATE_DUT_CONFIGS(state, payload) {
    const record = state.dut_configs_list.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      Object.assign(record, payload);
    }
  },
  SET_GERBER_FILES(state, payload) {
    state.gerber_files = payload;
  },
  SET_PCB_STACKUP(state, payload) {
    state.pcb_stackup = payload;
    //state.pp_stackup = payload;
  },
  SET_PCB_STACKUP2(state, payload) {
    state.pp_stackup = payload;
  },
  SAVE_ZIP_GERBER_FILES(state, payload) {
    const record = state.zip_gerber_files.find(
      (element) => element.side == payload.side
    );
    if (record) {
      record["data"].push(payload["data"][0]);
    } else {
      state.zip_gerber_files.push(payload);
    }
  },
  SET_PARSED_FILE(state, { test_points, guide_pins, pressure_pins, holes, dut_data, missing_optional_headers }) {
    state.parsed_json_blob = { test_points, guide_pins, pressure_pins, holes, dut_data, missing_optional_headers };
    state.parsed_test_points = test_points;
    state.parsed_guide_pins = guide_pins;
    state.parsed_pressure_pins = pressure_pins;
    state.parsed_holes = holes;
    state.parsed_dut_data = dut_data;
    state.parsed_missing_headers = missing_optional_headers;
    if (Object.keys(dut_data).length > 0) {
      state.blank_svg.top.units = dut_data.units;
      state.blank_svg.top.width = dut_data.dut_length;
      state.blank_svg.top.height = dut_data.dut_width;
      state.blank_svg.top.viewBox[2] = dut_data.dut_length * 1e3;
      state.blank_svg.top.viewBox[3] = dut_data.dut_width * 1e3;
    }
  },
  SET_DUT_DETAILS_OPTIONS (state, payload) {
    state.dut_units.splice(0);
    state.dut_units = payload.actions.POST.units.choices.map(
      (o) => ({
        pk: o.value,
        description: o.display_name,
      })
    );
  },
  SET_DUT_FIELD_DESCRIPTIONS(state, payload) {
    state.dut_field_descriptions = payload;
  },
  SET_QUOTE(state, payload) {
    state.quote = payload;
  },
  SET_DUT_DETAILS(state, payload) {
    state.dut_details = payload;
  },
  INIT_QUOTE_AND_DUT(state) {
    state.quote = {};
    state.dut_details = {};
    state.gerber_files = [];
    state.parsed_json_blob = {};
    state.parsed_test_points = [];
    state.parsed_guide_pins = [];
    state.parsed_pressure_pins = [];
    state.parsed_holes = [];
    state.parsed_dut_data = {};
    state.parsed_missing_headers = [];
    state.pcb_stackup = {};
    state.dut_configs = {};
  },
  INIT_PARSED_FILES(state) {
    state.parsed_json_blob = {};
    state.parsed_test_points = [];
    state.parsed_guide_pins = [];
    state.parsed_pressure_pins = [];
    state.parsed_holes = [];
    state.parsed_dut_data = {};
    state.parsed_missing_headers = [];
  },
  INIT_ZIP_GERBER_FILES(state) {
    state.zip_gerber_files.splice(0);
  },
  INIT_GERBER_FILES(state) {
    state.gerber_files.splice(0);
  },
  INIT_FIXTURE_RENDER_STATE(state) {
    state.dut_configs = {};
    state.testpoint_layers.splice(0);
    state.testpoint_types.splice(0);
    state.render_settings.splice(0);
    state.pcb_stackup = {};
  },
  INIT_ALL_STATE(state) {
    state.dut_configs = {};
    state.guidepins.splice(0);
    state.pressurepins.splice(0);
    state.receptacles.splice(0);
    state.probes.splice(0);
    state.testpoint_layers.splice(0);
    state.testpoint_types.splice(0);
    state.guidepin_layers.splice(0);
    state.render_settings.splice(0);
    //state.dut_configs_list.splice(0);
    state.gerber_files.splice(0);
    state.pcb_stackup = {};
    state.zip_gerber_files = [];
    state.parsed_json_blob = {};
    state.parsed_test_points = [];
    state.parsed_guide_pins = [];
    state.parsed_pressure_pins = [];
    state.parsed_holes = [];
    state.parsed_dut_data = {};
    state.parsed_missing_headers = [];
    state.blank_svg = {
      top:{
        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 0 0" style="enable-background:new 0 0 0 0;" xml:space="preserve">
          <g>
          </g>
          </svg>`,
        attributes: {
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          ["xmlns:xlink"]: "http://www.w3.org/1999/xlink",
          ["stroke-linecap"]: "round",
          ["stroke-linejoin"]: "round",
          ["stroke-width"]: 0,
          ["fill-rule"]: "evenodd",
          ["clip-rule"]: "evenodd",
          id: "orRjq2U5RysF_top",
          viewBox: "0 0 0 0",
        },
        defs: [],
        viewBox: [0, 0, 0, 0],
        width: 0,
        height: 0,
        units: "mm",
      },
      bottom:{
        svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 0 0" style="enable-background:new 0 0 0 0;" xml:space="preserve">
          <g>
          </g>
          </svg>`,
        attributes: {
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          ["xmlns:xlink"]: "http://www.w3.org/1999/xlink",
          ["stroke-linecap"]: "round",
          ["stroke-linejoin"]: "round",
          ["stroke-width"]: 0,
          ["fill-rule"]: "evenodd",
          ["clip-rule"]: "evenodd",
          id: "orRjq2U5RysF_bot",
          viewBox: "0 0 0 0",
        },
        defs: [],
        viewBox: [0, 0, 0, 0],
        width: 0,
        height: 0,
        units: "mm",
      },
    };
    state.dut_units.splice(0);
    state.dut_field_descriptions = {};
    state.quote = {};
    state.dut_details = {};
  },
  INIT_GUIDE_PINS(state) {
    state.guidepins.splice(0);
  },
  INIT_PRESSURE_PINS(state) {
    state.pressurepins.splice(0);
  },
  INIT_RECEPTACLES(state) {
    state.receptacles.splice(0);
  },
  INIT_PROBES(state) {
    state.probes.splice(0);
  },
  INIT_DUT_CONFIGS_LIST(state) {
    state.dut_configs_list.splice(0);
  },
};
