export default {
  projects(state) {
    return state.project_list;
  },
  fixturesConfigs(state) {
    return state.fixtures_configs;
  },
  connectionTypes(state) {
    return state.connection_types;
  },
  tipStyles(state) {
    return state.tip_styles;
  },
  selProject(state) {
    return state.selected.project;
  },
  usersList(state) {
    return state.users_list;
  },
  projectUser(state) {
    return state.user;
  },
  company(state) {
    return state.company;
  },
  companyList(state) {
    return state.company_list;
  },
  dutConfigs(state) {
    return state.dut_configs;
  },
  dutDetails(state) {
    return state.dut_details;
  },
  gerberFiles(state) {
    return state.gerber_files;
  },
  pcbStackup(state) {
    return state.pcb_stackup;
  },
  quote(state) {
    return state.quote;
  },
};
