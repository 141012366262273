import jwtInterceptor from "@/helpers/interceptor";

export default {
  getGuidePins(params) {
    return jwtInterceptor.get(process.env.VUE_APP_GUIDE_PINS_URL, params);
  },
  createGuidePin(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_GUIDE_PINS_URL, payload);
  },
  patchGuidePin(id, data) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_GUIDE_PINS_URL}${id}/`, data);
  },
  deleteGuidePinById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_GUIDE_PINS_URL}${id}/`);
  }
}
