export default {
  updateSVGDrawingTP(context, payload) {
    context.commit("UPDATE_TESTPOINTS", payload);
  },
  updateSVGDrawingGP(context, payload) {
    context.commit("UPDATE_GUIDEPINS", payload);
  },
  updateSVGDrawingPP(context, payload) {
    context.commit("UPDATE_PRESSUREPINS", payload);
  },
  updateSVGDrawingPG(context, payload) {
    context.commit("UPDATE_PROBE_GROUP", payload);
  },
  updateSVGDrawingRG(context, payload) {
    context.commit("UPDATE_RECEPTACLE_GROUP", payload);
  },
  updateSVGDrawingGPG(context, payload) {
    context.commit("UPDATE_GUIDEPIN_GROUP", payload);
  },
  updateSVGDrawingPPG(context, payload) {
    context.commit("UPDATE_PRESSUREPIN_GROUP", payload);
  },
  updateProbeColor(context, { probePk, index, hex }) {
    context.commit("UPDATE_PROBE_COLOR", { probePk, index, hex })
  },
  updateReceptacleColor(context, { receptaclePk, index, hex }) {
    context.commit("UPDATE_RECEPTACLE_COLOR", { receptaclePk, index, hex })
  },
  updateGuidepinColor(context, { guidepinPk, index, hex }) {
    context.commit("UPDATE_GUIDEPIN_COLOR", { guidepinPk, index, hex })
  },
  updatePressurepinColor(context, { pressurepinPk, index, hex }) {
    context.commit("UPDATE_PRESSUREPIN_COLOR", { pressurepinPk, index, hex })
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
