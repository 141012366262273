export default {
  data() {
    return {
      selected: null,
      bottomDialog: false,
      confirmAction: null,
      confirmMessage: null,
    };
  },
  methods: {
    showDialog({ id = null, nextAction, message }) {
      this.confirmAction = nextAction;
      this.confirmMessage = message;
      this.selected = id;
      this.bottomDialog = true;
    },
    stopDialog() {
      this.bottomDialog = false;
    },
  }
}