<template>
  <v-overlay color="#FFFFFF" :value="isLoading">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="15"
          :value="value"
          color="primary"
        >
          {{ value }}
        </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "ProgressCircular",
  props: {
    isLoading: Boolean,
    value: Number,
  },
};
</script>

<style></style>
