import store from "@/store/index.js";

export const SvgRoutes = {
  path: "/svg-drawing",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "project/:id/dutconfig/:dutId/fixturedesign/:fdId",
      name: "svg-drawing",
      component: () => import("./views/SvgDrawing.vue"),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of SVG Drawing Page");
        try {
          await store.dispatch("projects/fetchProject", to.params.id);
          await Promise.all([
            store.dispatch("fixturedesign/getFixtureDesign", {
              params: { project_id: to.params.id }
            }),
            store.dispatch("dutconfigs/getSvgDrawingComponents", {
              params: {
                params: { project_id: to.params.id },
              },
              dutParams: {
                params: { dc_pk: to.params.dutId },
              },
              dutId: to.params.dutId,
              fdId: to.params.fdId,
            }),
          ]);
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            "Failed to process request (Assembly Drawing), try later."
          );
        }
      },
    },
  ],
};
