export default {
  data() {
    return {
      showMenu: false,
      xCoord: 0,
      yCoord: 0,
      nextRoute: null,
      nextAction: null,
      buttonText: null,
    };
  },
  methods: {
    activateMenu({ nextRoute, nextAction, event, buttonText = null }) {
      event.preventDefault();
      this.showMenu = false;
      this.xCoord = event.clientX;
      this.yCoord = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.nextRoute = nextRoute;
      this.nextAction = nextAction;
      this.buttonText = buttonText;
    },
    subMenuClickHandler(payload) {
      this.showMenu = !this.showMenu;
      this[payload](this.nextRoute);
      this.nextRoute = this.nextAction = null;
    },
    subMenuCopyUrl(payload) {
      this[`${payload}CopyUrl`](this.nextRoute);
    },
  }
}