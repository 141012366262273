export default {
  user(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return !!state.user && !!state.user?.token;
  },
  isAdmin(state) {
    return state.user ? state.user.is_staff : false;
  },
};