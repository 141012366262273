<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        dark
        top
        left
        color="warning ml-2 my-2"
        @click="gotoLink(nextRoute)"
        v-on="on"
      >
        <v-icon>{{ navIcon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "NavigationButton",
  props: ["tooltipText", "nextRoute", "navIcon"],
  methods: {
    gotoLink(link) {
      this.$router.push({ name: link });
    },
  },
};
</script>
