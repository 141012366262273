<template>
  <v-card
    flat
    class="mx-5"
    min-width="300"
    max-width="300"
  >
    <slot name="image"></slot>
    <slot name="name"></slot>
    <slot name="description"></slot>
  </v-card>
</template>
<script>
export default {
  name: "ItemView",
};
</script>
