<template>
  <v-snackbar
    v-model="snackBanner"
    color="alert"
    max-width="1200"
    top
    timeout="-1"
    class="ma-0 pa-0"
  >
    <v-row dense class="ma-0 pa-0">
      <v-col class="d-flex justify-center align-center">
        <span class="mt-5" v-html="bannerMessage"></span>
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-icon color="amber" v-bind="attrs" @click="closeBanner"
        >mdi-close</v-icon
      >
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: "SnackAnnouncement",
  props: {
    snackBanner: {
      type: Boolean,
      default: true,
    },
    bannerMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  methods: {
    closeBanner() {
      this.$emit("closeBanner");
    },
  },
};
</script>
