export default {
  labelGroup(state) {
    return state.label_group;
  },
  testpointPlot(state) {
    return state.testpoint_plot;
  },
  guidepinPlot(state) {
    return state.guidepin_plot;
  },
  pressurepinPlot(state) {
    return state.pressurepin_plot;
  },
  probeGroup(state) {
    return state.probe_group;
  },
  receptacleGroup(state) {
    return state.receptacle_group;
  },
  guidepinGroup(state) {
    return state.guidepin_group;
  },
  pressurepinGroup(state) {
    return state.pressurepin_group;
  },
};