import store from "@/store/index.js";

export const ProfileRoutes = {
  path: "/user-profile",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "/",
      name: "user-profile",
      component: () => import("./views/ProfilePage.vue"),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of Profile Page");
        try {
          await store.dispatch(
            "projects/fetchUserById",
            store.getters["auth/user"].pk
          );
          store.getters["projects/projectUser"].groups.length > 0 &&
            (await store.dispatch("projects/fetchCompanyById", {
              id: store.getters["projects/projectUser"].groups[0].id,
            }));
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      },
    },
  ],
};
