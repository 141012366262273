export default {
  SET_PROJECTS(state, payload) {
    state.project_list.push(...payload);
  },
  CLEAR_PROJECTS(state) {
    state.project_list = [];
  },
  SET_FIXTURES_CONFIGS(state, payload) {
    state.fixtures_configs.push(...payload);
  },
  SET_CONNECTION_TYPES(state, payload) {
    state.connection_types.push(...payload);
  },
  SET_TIP_STYLES(state, payload) {
    state.tip_styles.push(...payload);
  },
  SET_PROJECT(state, payload) {
    state.selected.project = payload;
  },
  CLEAR_PROJECT(state) {
    state.selected.project = {
      fixture_design_details: {},
    };
  },
  SET_USERS_LIST(state, payload) {
    state.users_list.push(...payload);
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_COMPANY(state, payload) {
    state.company = payload;
    const record = state.company_list.find(
      (element) => element.id == payload.id
    );
    if (record) {
      Object.assign(record, payload);
    }
  },
  ADD_PROJECTS(state, payload) {
    state.project_list.push(...payload);
  },
  INIT_USER_AND_COMPANY(state) {
    state.user = state.company = null;
  },
  INIT_COMPANY(state) {
    state.company = null;
  },
  SET_COMPANY_LIST(state, payload) {
    state.company_list.push(...payload);
  },
  ADD_TO_COMPANY_LIST(state, payload) {
    state.company_list.push(payload);
  },
  INSERT_DC_TO_LIST(state, payload) {
    const record = state.project_list.find(
      (element) => element.pk == payload.project
    );
    if (record) {
      Object.assign(record.dut_config_details, {
        [payload.pk]: { name: payload.name },
      });
    }
  },
  INIT_DUT_CONFIGS(state) {
    state.dut_configs.splice(0);
  },
  SET_DUT_CONFIGS(state, payload) {
    const record = state.dut_configs.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      Object.assign(record, payload);
    } else {
      state.dut_configs.push(payload);
    }
  },
  INIT_DUT_DETAILS(state) {
    state.dut_details = [];
  },
  SET_DUT_NAME(state, { name, index }) {
    state.dut_details[index].dut_name = name;
  },
  SET_DUT_DETAILS(state, { payload, index }) {
    const record = state.dut_details.find(
      (element) => element.id == payload.id
    );
    if (record) {
      Object.assign(record, payload);
    } else {
      state.dut_details.push(payload);
    }
    if (state.gerber_files[index]) {
      state.gerber_files[index].splice(
        0,
        state.gerber_files[index].length,
        ...payload.gerber_layer_file
      );
    } else {
      state.gerber_files.push(payload.gerber_layer_file);
    }
  },
  DELETE_DUT_DETAIL(state, id) {
    const record = state.dut_details.findIndex((element) => element.id === id);
    if (record) {
      state.dut_details.splice(record, 1);
    }
  },
  SET_GERBER_FILES(state, payload) {
    state.gerber_files.push(payload.individual_gerber_files);
    state.pcb_stackup.push(
      payload.pcb_stackup.length > 0 ? JSON.parse(payload.pcb_stackup) : null
    );
  },
  INIT_GERBER_FILES(state) {
    state.gerber_files.splice(0);
    state.pcb_stackup.splice(0);
  },
  SET_QUOTE(state, payload) {
    state.quote = payload;
  },
  INIT_ALL_DUTS(state) {
    state.dut_configs.splice(0);
    state.dut_details.splice(0);
    state.gerber_files.splice(0);
    state.pcb_stackup.splice(0);
    state.quote = {};
  },
  REMOVE_SINGLE_PROJECT(state, payload) {
    const record = state.project_list.findIndex(
      (element) => element.pk === payload
    );
    if (record) {
      state.project_list.splice(record, 1);
    }
  },
  INIT_GERBER_LAYER_FILES(state) {
    state.gerber_files.splice(0);
  },
  RESET(state) {
    state.project_list = [];
    state.fixtures_configs = [];
    state.connection_types = [];
    state.tip_styles = [];
    state.selected = {
      project: {
        fixture_design_details: {},
      },
    };
    state.users_list = [];
    state.user = null;
    state.company = null;
    state.company_list = [];
    state.dut_configs = [];
    state.dut_details = [];
    state.gerber_files = [];
    state.pcb_stackup = [];
    state.quote = {};
  },
  INIT_USERS_LIST(state) {
    state.users_list.splice(0);
  },
  INIT_COMPANY_LIST(state) {
    state.company_list.splice(0);
  },
  INIT_FIXTURES_CONFIGS(state) {
    state.fixtures_configs.splice(0);
  },
  INIT_CONNECTION_TYPES(state) {
    state.connection_types.splice(0);
  },
  INIT_TIP_STYLES(state) {
    state.tip_styles.splice(0);
  },
};
