export default {
  test_pop(state) {
    return state.test_pop;
  },
  step_pop(state) {
    return state.step_pop;
  },
  miniVariant(state) {
    return state.miniVariant;
  },
  loading(state) {
    return state.loading;
  },
  snackBar(state) {
    return state.snackBar;
  },
  snackLink(state) {
    return state.snackLink;
  },
  errorPage(state) {
    return state.error_page;
  },
  circularLoading(state) {
    return state.circular_loading;
  },
  circularValue(state) {
    return state.circular_value;
  },
  renderEvent(state) {
    return state.render_event;
  },
  newUpdate(state) {
    return state.new_update;
  },
  newInvoice(state) {
    return state.new_invoice;
  },
  projectSearch(state) {
    return state.project_search;
  },
  dutConfigId(state) {
    return state.dutconfig_id;
  },
  fdStateLongList(state) {
    return state.fd_state_longlist;
  },
  projectSearchString(state) {
    return state.project_search_string;
  },
  userCompany(state) {
    return state.user_and_company;
  },
  quoteTabs(state) {
    return state.quote_tabs;
  },
  fixtureDetails(state) {
    return state.fixture_details;
  },
  dutUiDetails(state) {
    return state.dut_ui_details;
  },
  projectTabs(state) {
    return state.project_tabs;
  },
  projectDetails(state) {
    return state.project_details;
  },
  redirectFullpath(state) {
    return state.redirect_fullpath;
  },
  billingAddress(state) {
    return state.billing_address;
  },
  shippingAddress(state) {
    return state.shipping_address;
  },
  shippingSameAsBilling(state) {
    return state.shipping_same_as_billing;
  },
  backgroundLoading(state) {
    return state.background_loading;
  },
  hoverText(state) {
    return state.hover_text;
  },
  bannerMessage(state) {
    return state.banner_message?.message;
  },
};