export default {
  SET_GUIDE_PINS(state, results) {
    state.guide_pins.push(...results.map((o) => ({
      ...o,
      x: o.x.toFixed(4),
      y: o.y.toFixed(4),
      diameter: o.diameter.toFixed(4),
      layerPk: o.layer,
      guide_pin_assemblyPk: o.guide_pin_assembly
        ? o.guide_pin_assembly.pk
        : o.guide_pin_assembly,
      edited: false,
      x_mils: (o.x * (1 / 0.0254)).toFixed(4),
      y_mils: (o.y * (1 / 0.0254)).toFixed(4),
      diameter_mils: (o.diameter * (1 / 0.0254)).toFixed(4),
    })));
    state.count = state.guide_pins.length;
  },
  UPDATE_LAYER(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record["layer"] = payload.data.name;
      record["layerPk"] = payload.data.pk;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_GUIDE_PIN(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record["guide_pin_assembly"] = payload.data.name;
      record["guide_pin_assemblyPk"] = payload.data.pk;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_DIRECT_SYNC(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record[payload.data.propsKey] = payload.data[payload.data.propsKey];
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_COORDINATE(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record[payload.data.propsKey] = (payload.data[payload.data.propsKey]).toFixed(4);
      record[`${payload.data.propsKey}_mils`] = (payload.data[payload.data.propsKey] * (1 / 0.0254)).toFixed(4);
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_COORDINATE_MILS(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record[payload.data.propsKey] = (payload.data[payload.data.propsKey]).toFixed(4);
      record[payload.data.propsKey.split("_")[0]] = (payload.data[payload.data.propsKey] * 0.0254).toFixed(4);
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_DUT_CONFIG(state, { record, dutPk }) {
    const recorded = state.guide_pins.find(
      (element) => element.pk == record.pk
    );
    if (recorded) {
      recorded["dut_config"] = dutPk;
    }
  },
  UPDATE_EDITED(state) {
    state.edited_gp = JSON.parse(JSON.stringify(state.guide_pins));
    state.current_gp = JSON.parse(JSON.stringify(state.guide_pins));
  },
  UPDATE_EDITED_BY_ID(state, payload) {
    const record = state.edited_gp.find((element) => element.pk == payload.pk);
    if (record) {
      Object.assign(record, payload);
    }
  },
  INIT_EDITED(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record["edited"] = !record["edited"];
    }
  },
  INIT_GUIDE_PINS(state) {
    state.guide_pins.splice(0);
    state.edited_gp.splice(0);
    state.current_gp.splice(0);
    state.count = null;
  },
  RESTORE_GUIDE_PIN(state, pk) {
    const record = state.edited_gp.find((element) => element.pk == pk);
    if (record) {
      const guidepin = state.guide_pins.find((element) => element.pk == pk);
      if (guidepin) {
        Object.assign(guidepin, record);
        guidepin["edited"] = false;
      }
    }
  },
  RESTORE_ALL_GUIDE_PINS(state) {
    state.guide_pins = JSON.parse(JSON.stringify(state.edited_gp));
  },
  REVERT_CELL_OBJECT(state, { type, pk }) {
    const record = state.current_gp.find((element) => element.pk == pk);
    if (record) {
      const guidepin = state.guide_pins.find((element) => element.pk == pk);
      if (guidepin) {
        guidepin[type] = record[type];
      }
    }
  },
  UPDATE_CURRENT_GP(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      const clonedGP = JSON.parse(JSON.stringify(record));
      const current = state.current_gp.find(
        (element) => element.pk == payload.pk
      );
      if (current) {
        Object.assign(current, clonedGP);
      } else {
        state.current_gp.push(clonedGP);
      }
    }
  },
  UPDATE_COORD_PLOT(state, payload) {
    const record = state.guide_pins.find((element) => element.pk == payload.pk);
    if (record) {
      record.x = payload.x;
      record.y = payload.y;
      record.edited = true;
    }
  },
};
