import jwtInterceptor from "@/helpers/interceptor";

export default {
  getTPCBConfig(params) {
    return jwtInterceptor.get(process.env.VUE_APP_TPCB_CONFIG_URL, params);
  },
  postTPCBConfig(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_TPCB_CONFIG_URL, payload);
  },
  getConnectors() {
    return jwtInterceptor.get(process.env.VUE_APP_CONNECTORS_URL);
  },
  getConnectorBlocks(params) {
    return jwtInterceptor.get(process.env.VUE_APP_CONNECTOR_BLOCKS_URL, params);
  },
  postConnectorBlock(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_CONNECTOR_BLOCKS_URL, payload);
  },
  patchConnectorBlock({ pk, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_CONNECTOR_BLOCKS_URL}${pk}/`, data);
  },
  deleteConnectorBlock(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_CONNECTOR_BLOCKS_URL}${id}/`);
  },
  patchPinBlock({ pk, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_PIN_BLOCKS_URL}${pk}/`, data);
  },
  deleteTPCBById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_TPCB_CONFIG_URL}${id}/`);
  },
}
