import store from "@/store/index.js";

export const ConfigDutRoutes = {
  path: "/configure",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "project/:id/dutconfig/:did",
      name: "configure",
      component: () => import("./views/ConfigurePage.vue"),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of DUT Config Page");
        try {
          await store.dispatch("fixturedesign/getFixtureDesign", {
            params: { project_id: to.params.id },
          });
          await Promise.all([
            store.dispatch("dutconfigs/getDutConfigComponents", {
              params: {
                params: { project_id: to.params.id },
              },
              dutParams: {
                params: { dc_pk: to.params.did },
              },
              dutId: to.params.did,
              fdId: store.getters["fixturedesign/fixtureDesign"].pk,
            }),
            store.dispatch("fixturedesign/getFixtureDesignState"),
            store.dispatch("projects/fetchProject", to.params.id),
            store.dispatch("quotes/getDutFieldDescriptions"),
          ]);
          if (store.getters["dutconfigs/dutConfigs"].dut_details) {
            await store.dispatch(
              "dutconfigs/getDutDetailsById",
              store.getters["dutconfigs/dutConfigs"].dut_details
            );
          } else {
            const response = await store.dispatch("dutconfigs/saveDutDetail", {
              editMode: false,
              id: null,
              data: {
                user: store.getters["dutconfigs/dutConfigs"].user,
                dut_name: store.getters["dutconfigs/dutConfigs"].name,
              },
            });
            await store.dispatch("dutconfigs/updateDutConfigs", {
              pk: store.getters["dutconfigs/dutConfigs"].pk,
              data: { dut_details: response.id },
            });
          }

          /* store.getters["dutconfigs/dutConfigs"].individual_gerber_files
            .length > 0 && (await store.dispatch("dutconfigs/getPCBStackup")); */
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      },
    },
  ],
};
