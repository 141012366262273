import axios from "axios";
import store from "../store/index";
import router from "../router/index";

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const jwtInterceptor = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

jwtInterceptor.interceptors.request.use( async (config) => {
  const authToken = store.getters["auth/user"].token;
  if (authToken == null) {
    return config;
  }
  const jwtPayload = parseJwt(store.getters["auth/user"].token);
  if (jwtPayload.exp < (Date.now() / 1000)) {
      console.log("ACCESS TOKEN WAS RENEWED!");
      const url = process.env.VUE_APP_BASE_URL + "/dj-rest-auth/token/refresh/";
      const response = await axios.post(url, { refresh: store.getters["auth/user"].refreshToken });
      store.dispatch("auth/updateToken", response.data);
      config.headers[
        "Authorization"
      ] = `Bearer ${response.data.access}`;
  } else {
    config.headers.common["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const refreshToken = store.getters["auth/user"].refreshToken;
      const url =
        process.env.VUE_APP_BASE_URL + "/dj-rest-auth/token/refresh/";
      
      try {
        const response = await axios.post(url, { refresh: refreshToken });
        store.dispatch("auth/updateToken", response.data);
        error.config.headers[
          "Authorization"
        ] = `Bearer ${response.data.access}`;
        return axios(error.config);
      } catch (err) {
        store.commit("ui/SNACK_BAR", "Your session has expired, please SIGN IN to continue.");
        store.dispatch("auth/clearUserData");
        store.dispatch("ui/initAllUI");
        router.push({name: "signin", query: store.getters["ui/redirectFullpath"] ? { redirect: store.getters["ui/redirectFullpath"] } : {}});
        store.dispatch("ui/initAllUI");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;
