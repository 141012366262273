export default {
  SET_PRESSURE_PINS(state, results) {
    state.pressure_pins.push(...results.map((o) => ({
      ...o,
      x: o.x.toFixed(4),
      y: o.y.toFixed(4),
      pressure_pin_assemblyPk: o.pressure_pin_assembly
        ? o.pressure_pin_assembly.pk
        : o.pressure_pin_assembly,
      edited: false,
      x_mils: (o.x * (1 / 0.0254)).toFixed(4),
      y_mils: (o.y * (1 / 0.0254)).toFixed(4),
    })));
    state.count = results.length;
  },
  UPDATE_PRESSURE_PIN(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["pressure_pin_assembly"] = payload.data.name;
      record["pressure_pin_assemblyPk"] = payload.data.pk;
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_DIRECT_SYNC(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record[payload.data.propsKey] = payload.data[payload.data.propsKey];
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_DUT_CONFIG(state, { record, dutPk }) {
    const recorded = state.pressure_pins.find(
      (element) => element.pk == record.pk
    );
    if (recorded) {
      recorded["dut_config"] = dutPk;
    }
  },
  UPDATE_EDITED(state) {
    state.edited_pp = JSON.parse(JSON.stringify(state.pressure_pins));
    state.current_pp = JSON.parse(JSON.stringify(state.pressure_pins));
  },
  UPDATE_EDITED_BY_ID(state, payload) {
    const record = state.edited_pp.find((element) => element.pk == payload.pk);
    if (record) {
      Object.assign(record, payload);
    }
  },
  INIT_EDITED(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record["edited"] = !record["edited"];
    }
  },
  INIT_PRESSURE_PINS(state) {
    state.pressure_pins.splice(0);
    state.edited_pp.splice(0);
    state.current_pp.splice(0);
    state.count = null;
  },
  RESTORE_PRESSURE_PIN(state, pk) {
    const record = state.edited_pp.find((element) => element.pk == pk);
    if (record) {
      const pressurepin = state.pressure_pins.find(
        (element) => element.pk == pk
      );
      if (pressurepin) {
        Object.assign(pressurepin, record);
        pressurepin["edited"] = false;
      }
    }
  },
  RESTORE_ALL_PRESSURE_PINS(state) {
    state.pressure_pins = JSON.parse(JSON.stringify(state.edited_pp));
  },
  REVERT_CELL_OBJECT(state, { type, pk }) {
    const record = state.current_pp.find((element) => element.pk == pk);
    if (record) {
      const pressurepin = state.pressure_pins.find(
        (element) => element.pk == pk
      );
      if (pressurepin) {
        pressurepin[type] = record[type];
      }
    }
  },
  UPDATE_CURRENT_PP(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      const clonedPP = JSON.parse(JSON.stringify(record));
      const current = state.current_pp.find(
        (element) => element.pk == payload.pk
      );
      if (current) {
        Object.assign(current, clonedPP);
      } else {
        state.current_pp.push(clonedPP);
      }
    }
  },
  UPDATE_COORD_PLOT(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record.x = payload.x;
      record.y = payload.y;
      record.edited = true;
    }
  },
  UPDATE_COORDINATE(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record[payload.data.propsKey] = (payload.data[payload.data.propsKey]).toFixed(4);
      record[`${payload.data.propsKey}_mils`] = (
        payload.data[payload.data.propsKey] *
        (1 / 0.0254)
      ).toFixed(4);
      record["edited"] = payload.data.edited || false;
    }
  },
  UPDATE_COORDINATE_MILS(state, payload) {
    const record = state.pressure_pins.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      record[payload.data.propsKey] = (payload.data[payload.data.propsKey]).toFixed(4);
      record[payload.data.propsKey.split("_")[0]] = (
        payload.data[payload.data.propsKey] * 0.0254
      ).toFixed(4);
      record["edited"] = payload.data.edited || false;
    }
  },
};
