<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 16 : active ? 4 : 0"
      :flat="!active"
      outlined
      class="mx-auto"
      min-width="300"
      max-width="300"
      data-test="card"
      @click="
        toggle({
          pk,
          name,
          description,
          thumbnail,
        })
      "
    >
      <v-img
        :src="thumbnail"
        :aspect-ratio="16 / 9"
        contain
        class="ma-1"
        data-test="image"
      ></v-img>
      <p
        class="line-clamp body-1 text-center alert--text px-1"
        data-test="name"
      >
        {{ name }}
      </p>
      <p
        class="line-clamp2 caption text-center primary--text px-1"
        data-test="description"
      >
        {{ description }}
      </p>
    </v-card>
  </v-hover>
</template>
<script>
export default {
  name: "ItemCard",
  props: {
    pk: Number,
    name: String,
    description: String,
    thumbnail: String,
    selected: Object,
  },
  computed: {
    active() {
      return this.selected.pk === this.pk;
    },
  },
  methods: {
    toggle(payload) {
      this.$emit("toggle", payload);
    },
  },
};
</script>
