import CommonService from "./endpoints";

export default {
  loading(context, message) {
    context.commit("LOADING", message);
  },
  snackBar(context, message) {
    context.commit("SNACK_BAR", message);
  },
  snackLink(context, payload) {
    context.commit("SNACK_LINK", payload);
  },
  circularLoading(context) {
    context.commit("CIRCULAR_LOADING");
  },
  circularValue(context, payload) {
    context.commit("CIRCULAR_VALUE", payload);
  },
  newUpdate(context, value) {
    context.commit("NEW_UPDATE", value);
  },
  projectSearch(context, payload) {
    context.commit("PROJECT_SEARCH", payload);
  },
  dutConfigId(context, value) {
    context.commit("DUT_CONFIG_ID", value);
  },
  toggleInvoice(context) {
    context.commit("TOGGLE_INVOICE");
  },
  projectSearchString(context, value) {
    context.commit("PROJECT_SEARCH_STRING", value);
  },
  updateQuoteUser(context, payload) {
    context.commit("UPDATE_QUOTE_USER", payload);
  },
  updateQuoteTabs(context, payload) {
    context.commit("INIT_QUOTE_TABS");
    context.commit("UPDATE_QUOTE_TABS", payload);
  },
  updateQuoteTabsDut(context, payload) {
    context.commit("UPDATE_QUOTE_TABS_DUT", payload);
  },
  updateQuoteFixtureDetails(context, payload) {
    context.commit("UPDATE_QUOTE_FIXTURE_DETAILS", payload);
  },
  updateQuoteDutDetails(context, payload) {
    context.commit("UPDATE_QUOTE_DUT_DETAILS", payload);
  },
  updateQuoteFromAPI(context, payload) {
    context.commit("UPDATE_QUOTE_FROM_API", payload);
  },
  updateBillingAddress(context, payload) {
    context.commit("UPDATE_BILLING_ADDRESS", payload);
  },
  updateShippingAddress(context, payload) {
    context.commit("UPDATE_SHIPPING_ADDRESS", payload);
  },
  updateShippingSame(context, payload) {
    context.commit("UPDATE_SHIPPING_SAME", payload);
  },
  initQuoteTabs(context) {
    context.commit("INIT_QUOTE_TABS");
  },
  initUIDetails(context) {
    context.commit("INIT_UI_DETAILS");
  },
  initProjectTabs(context) {
    context.commit("INIT_PROJECT_TABS");
  },
  updateProjectTabs(context, payload) {
    context.commit("INIT_PROJECT_TABS");
    context.commit("UPDATE_PROJECT_TABS", payload);
  },
  updateProjectDetails(context, payload) {
    context.commit("UPDATE_PROJECT_DETAILS", payload);
  },
  initAllUI(context) {
    context.commit("INIT_ALL_UI")
  },
  updateRedirectFullpath(context, payload) {
    context.commit("UPDATE_REDIRECT_FULLPATH", payload)
  },
  backgroundLoading(context, payload) {
    context.commit("BACKGROUND_LOADING", payload);
  },
  hoverText(context, payload) {
    context.commit("SET_HOVER_TEXT", payload);
  },
  bannerMessage(context, payload) {
    context.commit("SET_BANNER_MESSAGE", payload);
  },
  async getBannerMessage(context) {
    try {
      const response = await CommonService.getBannerMessage();
      console.log(response);
      response.data.results.length > 0 && context.commit("SET_BANNER_MESSAGE", response.data.results[0]);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  errorHandler(context, err) {
    console.log(err);
  },
};
