import jwtInterceptor from "@/helpers/interceptor";

export default {
  saveFixtureDesign(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_FIXTURE_DESIGN_URL, payload);
  },
  patchFixtureDesign({ pk, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/`, data);
  },
  genFixtureDesign(pk) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/generate/`);
  },
  getFixtureDesign(params) {
    return jwtInterceptor.get(process.env.VUE_APP_FIXTURE_DESIGN_URL, params);
  },
  getFixtureBOM(pk) {
    return jwtInterceptor.get(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/bom/`);
  },
  /* generateInvoice(pk) {
    return jwtInterceptor.post(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/invoice/`);
  }, */
  /* generateByAdmin({ fdPk, userPk }) {
    return jwtInterceptor.post(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${fdPk}/invoice/${userPk}/`);
  }, */
  /* getInvoice(pk) {
    return jwtInterceptor.get(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/invoice/`);
  }, */
  /* finalizeInvoice(pk) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/invoice/finalize/`);
  }, */
  /* patchInvoice(pk) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/invoice/`);
  }, */
  /* deleteInvoice(pk) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_FIXTURE_DESIGN_URL}${pk}/invoice/`);
  }, */
  /* getCustomers() {
    return jwtInterceptor.get(process.env.VUE_APP_CUSTOMERS_URL);
  }, */
  /* postCustomer(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_CUSTOMERS_URL, payload);
  }, */
  getFixtureDesignOptions() {
    return jwtInterceptor.options(process.env.VUE_APP_FIXTURE_DESIGN_URL);
  },
  getDesignFileOptions() {
    return jwtInterceptor.options(process.env.VUE_APP_DESIGN_FILE_PACKAGE_URL);
  },
  getDesignFilePackage(params) {
    return jwtInterceptor.get(process.env.VUE_APP_DESIGN_FILE_PACKAGE_URL, params);
  },
  postDesignFilePackage(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_DESIGN_FILE_PACKAGE_URL, payload);
  },
  patchDesignFilePackage({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_DESIGN_FILE_PACKAGE_URL}${id}/`, data);
  },
  deleteDesignFilePackage(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_DESIGN_FILE_PACKAGE_URL}${id}/`);
  },
}
