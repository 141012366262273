<template>
  <v-container id="auth-signin" fluid tag="section">
    <div v-if="isAuthenticated" />
    <v-row v-else justify="center" align-content="center">
      <v-col cols="12" lg="3" sm="6" class="mt-16" align="center">
        <v-card class="mt-16 text-center" max-width="400" outlined>
          <v-img
            class="rounded-circle elevation-6 mt-n12 d-inline-block"
            :src="require('@/assets/authlogo.png')"
            width="128"
          />
          <v-card-text class="text-center">
            <v-row class="my-2" justify="center">
              <a href="https://fixturfab.com"><v-img
                :src="require('@/assets/logo_fixturfab_black_large.jpg')"
                max-width="120"
                contain
                position="center center"
              /></a>
            </v-row>
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SignIn",
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    }
  },
};
</script>