<template>
  <v-menu
    :open-on-hover="isShown"
    close-on-click
    top
    offset-y
    max-width="500"
    class="rounded-lg"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <v-card>
      <v-list three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <slot name="subtitle"></slot>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <slot name="sublink" ></slot>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn class="blue--text" icon>
              <v-icon large>mdi-information-variant</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: "InputPopover",
  props: ["uiGetter"],
  computed: {
    isShown() {
      return this.$store.getters[this.uiGetter];
    }
  },
  methods: {
    toggleMenu() {
      this.$store.commit(this.uiGetter);
    }
  }
}
</script>