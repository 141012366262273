import store from "@/store/index.js";

export const ProjectRoutes = {
  path: "/projects",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "/",
      name: "projects",
      component: () => import("./views/ProjectIndex.vue"),
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "create",
      name: "project-create",
      component: () => import("./views/CreateProject.vue"),
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of Project Create");
        try {
          store.dispatch("projects/initAllDuts");
          store.dispatch("ui/initProjectTabs");
          store.dispatch("ui/initUIDetails");
          store.dispatch("projects/initUserCompany");
          await store.dispatch("projects/getFixturesConfigs");
          await Promise.all([
            store.dispatch("quotes/getDutFieldDescriptions"),
            store.dispatch("quotes/getDutDetailsOptions"),
            store.dispatch(
              "projects/fetchUserById",
              store.getters["auth/user"].pk
            ),
          ]);
          if (store.getters["projects/projectUser"].groups.length > 0) {
            await store.dispatch("projects/fetchCompanyById", {
              id: store.getters["projects/projectUser"].groups[0].id,
            });
          }
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request (Projects), try later."
          );
        }
      },
    },
    {
      path: "edit/:id",
      name: "project-edit",
      component: () => import("./views/EditProject.vue"),
      props: true,
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of selected Project");
        try {
          store.dispatch("projects/initAllDuts");
          store.dispatch("ui/initProjectTabs");
          store.dispatch("ui/initUIDetails");
          store.dispatch("projects/initUserCompany");
          await store.dispatch("projects/fetchProjectFlow", to.params.id);
          await Promise.all([
            store.dispatch("fixturedesign/getFixtureDesign", {
              params: { project_id: to.params.id },
            }),
            store.dispatch("projects/getFixturesConfigs"),
            store.dispatch(
              "ui/updateProjectTabs",
              store.getters["projects/selProject"]
            ),
            store.dispatch("quotes/getDutFieldDescriptions"),
            store.dispatch("quotes/getDutDetailsOptions"),
            store.dispatch(
              "projects/fetchUserById",
              store.getters["projects/selProject"].user
            ),
          ]);
          if (
            Object.keys(store.getters["projects/selProject"].dut_config_details)
              .length > 0
          ) {
            for (
              let i = 0;
              i <
              Object.keys(
                store.getters["projects/selProject"].dut_config_details
              ).length;
              i++
            ) {
              await store.dispatch(
                "projects/getDutConfigsById",
                Object.keys(
                  store.getters["projects/selProject"].dut_config_details
                )[i]
              );
            }
          }
          if (store.getters["fixturedesign/fixtureDesign"].quote) {
            const quoteRes = await store.dispatch(
              "projects/getQuote",
              store.getters["fixturedesign/fixtureDesign"].quote
            );
            // Billing and Shipping Address
            if (quoteRes.data.billing_address) {
              await store.dispatch("quotes/getQuoteAddress", { id: quoteRes.data.billing_address, isBilling: true });
            }
            if (quoteRes.data.shipping_address) {
              await store.dispatch("quotes/getQuoteAddress", { id: quoteRes.data.shipping_address, isBilling: false });
            }
          }
          if (store.getters["projects/projectUser"].groups.length > 0) {
            await store.dispatch("projects/fetchCompanyById", {
              id: store.getters["projects/projectUser"].groups[0].id,
            });
          }
          if (store.getters["projects/dutConfigs"].length > 0) {
            for (
              let i = 0;
              i < store.getters["projects/dutConfigs"].length;
              i++
            ) {
              if (store.getters["projects/dutConfigs"][i].dut_details) {
                await store.dispatch("projects/getDutDetailsById", {
                  id: store.getters["projects/dutConfigs"][i].dut_details,
                  index: i,
                });
              } else {
                const response = await store.dispatch(
                  "projects/saveDutDetail",
                  {
                    editMode: false,
                    id: null,
                    data: {
                      user: store.getters["projects/dutConfigs"][i].user,
                      dut_name: store.getters["projects/dutConfigs"][i].name,
                    },
                  }
                );
                await store.dispatch("projects/updateDutConfigs", {
                  pk: store.getters["projects/dutConfigs"][i].pk,
                  data: { dut_details: response.id },
                });
              }
            }
          }
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request (Projects), try later."
          );
        }
      },
    },
  ],
};
