<template>
  <v-toolbar flat min-width="100" class="mt-5 text--warning">
    <v-autocomplete
      :items="items"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      :multiple="allowMultiple"
      :loading="loading"
      v-model="selection"
      chips
      hide-details
      hide-no-data
      single-line
      @focus="$emit('loadData', dataType)"
      @change="
        filterQuotes({
          type: dataType,
          params: { [dataKey]: selection },
        })
      "
      :disabled="isSelectDisabled"
      data-test="input-select"
    >
      <template slot="item" slot-scope="data">
        <span data-test="display-text">{{ data.item[itemText] }}</span>
      </template>
      <template v-slot:prepend>
        <v-icon color="warning">mdi-magnify</v-icon>
      </template>
    </v-autocomplete>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          color="warning"
          :disabled="!selection"
          @click="clearFilters"
          v-on="on"
          data-test="clear-filter-button"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>Clear Filter</span>
    </v-tooltip>
  </v-toolbar>
</template>
<script>
export default {
  name: "SearchUserSelect",
  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    isSelectDisabled: {
      type: Boolean,
      default: false,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: null,
    };
  },
  methods: {
    clearFilters() {
      this.$emit("clearFilters");
    },
    filterQuotes(payload) {
      if (this.allowMultiple) return;
      this.$emit("filterQuotes", payload);
    },
    updateSelection(payload) {
      this.selection = payload;
    },
  },
};
</script>
