import axios from "axios"
import jwtInterceptor from "@/helpers/interceptor";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  signIn(credentials) {
    return apiClient.post(`${process.env.VUE_APP_AUTH_URL}login/`, credentials);
  },
  signUp(credentials) {
    return apiClient.post(`${process.env.VUE_APP_AUTH_URL}registration/`, credentials);
  },
  getUserById(payload) {
    return jwtInterceptor.get(`${process.env.VUE_APP_USERS_URL + payload.id}`, payload.config);
  },
  getUserByPk(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_USERS_URL + id}/`);
  },
  resetPassword(email) {
    return apiClient.post(`${process.env.VUE_APP_AUTH_URL}password/reset/`, email);
  },
  resetConfirm(credentials) {
    return apiClient.post(`${process.env.VUE_APP_AUTH_URL}password/reset/confirm/`, credentials);
  },
  saveUser(data) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_AUTH_URL}user/`, data);
  },
  changePassword(password) {
    return jwtInterceptor.post(`${process.env.VUE_APP_AUTH_URL}password/change/`, password);
  },
}
