import store from "@/store/index.js";

export const RenderRoutes = {
  path: "/render",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "project/:id",
      name: "render",
      component: () => import("./views/FixtureConfigPage.vue"),
      props: true,
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of 3D Render Page");
        try {
          await store.dispatch("fixturedesign/getFixtureDesignState");
          await Promise.all([
            store.dispatch("dutconfigs/getAllDutConfigs", {
              params: { project_id: to.params.id },
            }),
            store.dispatch("fixturedesign/getFixtureDesign", {
              params: { project_id: to.params.id },
            }),
          ]);
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            "Failed to process request (3D Render), try later."
          );
        }
      },
    },
  ]
};
