import jwtInterceptor from "@/helpers/interceptor";

export default {
  createDutConfigs(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_DUT_CONFIGS_URL, payload);
  },
  getDutConfigsById(pk) {
    return jwtInterceptor.get(`${process.env.VUE_APP_DUT_CONFIGS_URL + pk}/`);
  },
  patchDutConfigs({ pk, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_DUT_CONFIGS_URL + pk}/`, data);
  },
  deleteDutById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_DUT_CONFIGS_URL}${id}/`);
  },
  getGuidePins() {
    return jwtInterceptor.get(process.env.VUE_APP_GP_ASSEMBLY_URL);
  },
  getPressurePins() {
    return jwtInterceptor.get(process.env.VUE_APP_PP_ASSEMBLY_URL);
  },
  getReceptacles() {
    return jwtInterceptor.get(process.env.VUE_APP_RECEPTACLES_URL);
  },
  getProbes() {
    return jwtInterceptor.get(process.env.VUE_APP_PROBES_URL);
  },
  getTestPointOptions() {
    return jwtInterceptor.options(process.env.VUE_APP_TESTPOINTS_URL);
  },
  getGuidePinOptions() {
    return jwtInterceptor.options(process.env.VUE_APP_GUIDE_PINS_URL);
  },
  getFixtureDesignOptions() {
    return jwtInterceptor.options(process.env.VUE_APP_DESIGN_FILE_PACKAGE_URL);
  },
  fetchAllDutConfigs(params) {
    return jwtInterceptor.get(process.env.VUE_APP_DUT_CONFIGS_URL, params);
  },
  createGerberFile(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_DUT_CONFIGS_GERBER_URL, payload);
  },
  deleteGerberById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_DUT_CONFIGS_GERBER_URL}${id}/`);
  },
  parseTestPointFile({ data, dut_id }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_DUT_CONFIGS_URL + dut_id}/parse-file/`, data);
  },
  patchBulkGerbers({ project_id, dut_id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_PROJECTS_URL + project_id}/dut-configs/${dut_id}/dut-config-gerber-files`, data);
  },
  postTestPoints({ project_id, dut_id, data, data_type }) {
    return jwtInterceptor.post(`${process.env.VUE_APP_PROJECTS_URL + project_id}/dut-configs/${dut_id}/${data_type}`, data);
  },
  patchTestPoints({ project_id, dut_id, data, data_type }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_PROJECTS_URL + project_id}/dut-configs/${dut_id}/${data_type}`, data);
  },
}
