import TestPointService from "./endpoints";

export default {
  async getTestPoints(context, { params, dutParams, fdId }) {
    try {
      context.commit("INIT_TEST_POINTS");
      const init_response = await TestPointService.getTestPoints({
        params: { dc_pk: dutParams.params.dc_pk, page: 1 },
      });
      console.log("TPs", init_response.data);
      context.commit("SET_TEST_POINTS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await TestPointService.getTestPoints({
            params: { dc_pk: dutParams.params.dc_pk, page: i },
          });
          context.commit("SET_TEST_POINTS", response.data.results);
        }
      }
      context.dispatch("clearRecepProbe", { params, fdId, forced: false });
      context.dispatch("updateEdited");
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateTestPoint(context, { payload, dutId, projectId, fdId }) {
    context.dispatch("ui/loading", "Updating", { root: true });
    try {
      if (payload.editMode) {
        await TestPointService.patchTestPoint(payload.data.pk, payload.data);
      } else {
        await TestPointService.createTestPoint(payload.data);
      }
      await context.dispatch("getTestPoints", {
        params: { params: { project_id: projectId } },
        dutParams: { params: { dc_pk: dutId } },
        fdId,
      });
      /* context.dispatch("clearRecepProbe"); */
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "updateTestPoint" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async patchTestPoint(context, payload) {
    try {
      await TestPointService.patchTestPoint(payload.pk, payload);
      /* context.dispatch("ui/newUpdate", true, { root: true }); */
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateInline(context, { payload, projectId, fdId }) {
    context.dispatch("ui/loading", "Saving", { root: true });
    try {
      const patchPayload = {
        pk: payload.data.pk,
        project: payload.data.project,
        ref: payload.data.ref,
        name: payload.data.name,
        net: payload.data.net,
        x: payload.data.x,
        y: payload.data.y,
        min_spacing: payload.data.min_spacing,
        layer: payload.data.layerPk,
        type: payload.data.typePk,
        receptacle: payload.data.receptaclePk,
        probe: payload.data.probePk,
        enabled: payload.data.enabled,
      };
      await TestPointService.patchTestPoint(patchPayload.pk, patchPayload);
      context.dispatch("initEdited", payload.data);
      context.dispatch("updateEditedById", payload.data);
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "updateInLine-TP" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async updateAllEdited(context, { arrayList, projectId, fdId, testpoints }) {
    context.dispatch("ui/loading", "Saving", { root: true });
    try {
      await context.dispatch("dutconfigs/updateTestPointsBlob", arrayList, {
        root: true,
      });
      await context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "updateAllEdited-TP" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
    testpoints.forEach((testpoint) => {
      const payload = {
        editMode: true,
        data: {
          pk: testpoint.pk,
          project: testpoint.project,
          ref: testpoint.ref,
          name: testpoint.name,
          net: testpoint.net,
          x: testpoint.x,
          y: testpoint.y,
          min_spacing: testpoint.min_spacing,
          layer: testpoint.layerPk,
          type: testpoint.typePk,
          receptacle: testpoint.receptaclePk,
          probe: testpoint.probePk,
          enabled: testpoint.enabled,
        },
      };
      context.dispatch("initEdited", payload.data);
      context.dispatch("updateEditedById", testpoint);
    });
  },
  async deleteTestPoint(context, { payload, dutId, projectId, fdId }) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await TestPointService.deleteTestPointById(payload.pk);
      await context.dispatch("getTestPoints", {
        params: { params: { project_id: projectId } },
        dutParams: { params: { dc_pk: dutId } },
        fdId,
      });
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "deleteTestPoint" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async deleteSelectedTestPoints(context, { payload, dutId, projectId, fdId }) {
    context.dispatch("ui/loading", "Deleting Selected Test Points", {
      root: true,
    });
    try {
      for (let i = 0; i < payload.length; i++) {
        await TestPointService.deleteTestPointById(payload[i].pk);
      }
      await context.dispatch("getTestPoints", {
        params: { params: { project_id: projectId } },
        dutParams: { params: { dc_pk: dutId } },
        fdId,
      });
      context.dispatch(
        "fixturedesign/newUpdate",
        { projectId, fdId, value: true, from: "deleteSelectedTestPoints" },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  deleteAllTestPoints(context) {
    context.commit("INIT_TEST_POINTS");
    context.dispatch("guidepins/deleteAllGuidePins", null, { root: true });
    context.dispatch("pressurepins/deleteAllPressurePins", null, {
      root: true,
    });
  },
  restoreTestPoint(context, pk) {
    context.commit("RESTORE_TEST_POINT", pk);
  },
  restoreAllTestPoints(context) {
    context.commit("RESTORE_ALL_TEST_POINTS");
  },
  initEdited(context, payload) {
    context.commit("INIT_EDITED", payload);
  },
  updateReceptacle(context, payload) {
    context.commit("UPDATE_RECEPTACLE", payload);
    context.dispatch("autoProbe", { payload: payload, inline: false });
  },
  inlineReceptacle(context, payload) {
    context.commit("UPDATE_RECEPTACLE", payload);
    context.commit("UPDATE_CURRENT_TP", payload);
    context.dispatch("autoProbe", { payload: payload, inline: true });
  },
  updateOnlyReceptacle(context, payload) {
    context.commit("UPDATE_RECEPTACLE", payload);
  },
  updateProbe(context, payload) {
    context.commit("UPDATE_PROBE", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_TP", payload);
  },
  updateLayer(context, payload) {
    context.commit("UPDATE_LAYER", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_TP", payload);
  },
  updateType(context, payload) {
    context.commit("UPDATE_TYPE", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_TP", payload);
  },
  updateDutConfig(context, payload) {
    context.commit("UPDATE_DUT_CONFIG", payload);
  },
  updateCoordinate(context, payload) {
    context.commit("UPDATE_COORDINATE", payload);
    context.dispatch("updateMinSpacing", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_TP", payload);
  },
  updateCoordinateMils(context, payload) {
    context.commit("UPDATE_COORDINATE_MILS", payload);
    context.dispatch("updateMilsMinSpacing", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_TP", payload);
  },
  updateDirectSync(context, payload) {
    context.commit("UPDATE_DIRECT_SYNC", payload);
    if (payload.data.edited) context.commit("UPDATE_CURRENT_TP", payload);
  },
  updateEdited(context) {
    context.commit("UPDATE_EDITED");
  },
  updateEditedById(context, payload) {
    context.commit("UPDATE_EDITED_BY_ID", payload);
  },
  updateMinSpacing(context, payload) {
    const test_points = context.state.test_points.filter(
      (element) => element.pk != payload.pk
    );
    let xy_spacing = null;
    test_points.forEach((testpoint) => {
      const x = testpoint.x - payload.data.x;
      const y = testpoint.y - payload.data.y;
      const ms = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
      if (!xy_spacing || (ms < xy_spacing && ms != 0)) {
        xy_spacing = ms.toFixed(4);
      }
    });
    const commitPayload = { pk: payload.pk, min_spacing: xy_spacing };
    context.commit("UPDATE_MIN_SPACING", commitPayload);
    context.dispatch("refreshReceptacle", payload);
  },
  updateMilsMinSpacing(context, payload) {
    const test_points = context.state.test_points.filter(
      (element) => element.pk != payload.pk
    );
    let xy_spacing = null;
    test_points.forEach((testpoint) => {
      const x = testpoint.x_mils - payload.data.x_mils;
      const y = testpoint.y_mils - payload.data.y_mils;
      const ms = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
      if (!xy_spacing || (ms < xy_spacing && ms != 0)) {
        xy_spacing = ms.toFixed(4);
      }
    });
    const commitPayload = { pk: payload.pk, min_spacing: xy_spacing };
    context.commit("UPDATE_MIN_SPACING_MILS", commitPayload);
    context.dispatch("refreshReceptacle", payload);
  },
  refreshReceptacle(context, payload) {
    const record = context.state.test_points.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
      context.dispatch("autoReceptacle", { record, pcount: 0 });
    }
  },
  async clearRecepProbe(context, { forced }) {
    console.log("Forced:", forced);
    const dutPk = context.rootGetters["dutconfigs/dutConfigs"].pk;
    context.state.test_points.forEach(async (record) => {
      context.dispatch("autoLayer", record);
      if (!record.receptacle) {
        context.dispatch("updateDutConfig", { record, dutPk });
        if (record.requested_receptacle_type) {
          const receptaclePayload = {
            pk: record.pk,
            data: {
              name: record.requested_receptacle_type,
              pk: null,
              pitch: null,
            },
          };
          context.dispatch("updateOnlyReceptacle", receptaclePayload);
        }
      } else {
        context.dispatch("namedReceptacle", record);
      }
      if (!record.probe) {
        if (record.requested_probe_type) {
          const probePayload = {
            pk: record.pk,
            data: {
              name: record.requested_probe_type,
              pk: null,
              tip_style: null,
            },
          };
          context.dispatch("updateProbe", probePayload);
        }
      } else {
        context.dispatch("namedProbe", record);
      }
    });
  },
  autoReceptacle(context, { record, pcount }) {
    let receptacleOptions;
    if (
      context.rootGetters["fixturedesign/fixtureConfig"].development_fixture &&
      pcount > 0
    ) {
      receptacleOptions = context.rootGetters["dutconfigs/receptacles"]
        .filter((element) => {
          return (
            element.connection_type ===
              context.rootGetters["fixturedesign/connectionType"] &&
            element.pitch < 2.54 &&
            element.fixtures.includes(
              context.rootGetters["fixturedesign/fixtureConfig"].pk
            )
          );
        })
        .sort((a, b) => (a.pitch < b.pitch ? 1 : -1));
    } else {
      receptacleOptions = context.rootGetters["dutconfigs/receptacles"]
        .filter((element) => {
          return (
            element.connection_type ===
              context.rootGetters["fixturedesign/connectionType"] &&
            element.pitch <= record.min_spacing &&
            element.pitch < 3.175 &&
            element.fixtures.includes(
              context.rootGetters["fixturedesign/fixtureConfig"].pk
            )
          );
        })
        .sort((a, b) => (a.pitch < b.pitch ? 1 : -1));
    }
    const receptaclePayload =
      receptacleOptions.length > 0
        ? {
            pk: record.pk,
            data: {
              pk: receptacleOptions[0].pk,
              name: receptacleOptions[0].description,
              pitch: receptacleOptions[0].pitch,
              edited: record.edited,
            },
          }
        : {
            pk: record.pk,
            data: {
              pk: null,
              name: null,
              pitch: null,
              edited: record.edited,
            },
          };
    context.dispatch("updateReceptacle", receptaclePayload);
  },
  namedReceptacle(context, record) {
    const receptacle = context.rootGetters["dutconfigs/receptacles"].find(
      (element) => element.pk == record.receptaclePk
    );
    const receptaclePayload = {
      pk: record.pk,
      data: {
        pk: record.receptaclePk,
        name: receptacle ? receptacle.description : null,
        pitch: receptacle ? receptacle.pitch : null,
        edited: record.edited,
      },
    };
    context.dispatch("updateOnlyReceptacle", receptaclePayload);
  },
  namedProbe(context, record) {
    const probe = context.rootGetters["dutconfigs/probes"].find(
      (element) => element.pk == record.probePk
    );
    if (probe) {
      const probePayload = {
        pk: record.pk,
        data: {
          pk: record.probePk,
          name: probe ? probe.description : null,
          tip_style: probe ? probe.tip_style : null,
          edited: record.edited,
        },
      };
      context.dispatch("updateProbe", probePayload);
    }
  },
  autoProbe(context, { payload, inline }) {
    if (!payload.data.pk) return;
    let withTipStyle = true;
    let probeOptions = context.rootGetters["dutconfigs/probes"].filter(
      (element) => {
        return (
          element.tip_style === context.rootGetters["fixturedesign/tipStyle"] &&
          element.receptacles.includes(payload.data.pk)
        );
      }
    );
    if (probeOptions.length > 0) {
      context.dispatch("patchProbe", {
        payload,
        selectedProbe: probeOptions[0],
        inline,
        withTipStyle,
      });
    } else {
      withTipStyle = false;
      probeOptions = context.rootGetters["dutconfigs/probes"].filter(
        (element) => {
          return element.receptacles.includes(payload.data.pk);
        }
      );
      if (probeOptions.length > 0) {
        context.dispatch("patchProbe", {
          payload,
          selectedProbe: probeOptions[0],
          inline,
          withTipStyle,
        });
      }
    }
  },
  patchProbe(context, { payload, selectedProbe }) {
    const probePayload = {
      pk: payload.pk,
      data: {
        pk: selectedProbe.pk,
        name: selectedProbe.description,
        tip_style: selectedProbe.tip_style,
        edited: payload.data.edited,
      },
    };
    context.dispatch("updateProbe", probePayload);
    
  },
  autoLayer(context, record) {
    const layer = context.rootGetters["dutconfigs/testpointLayers"].find(
      (element) => element.pk == record.layerPk
    );
    const layerPayload = {
      pk: record.pk,
      data: {
        pk: record.layerPk,
        name: layer ? layer.description : null,
        edited: record.edited,
      },
    };
    context.dispatch("updateLayer", layerPayload);

    const typer = context.rootGetters["dutconfigs/testpointTypes"].find(
      (element) => element.pk == record.typePk
    );
    const typePayload = {
      pk: record.pk,
      data: {
        pk: record.typePk,
        name: typer ? typer.description : null,
        edited: record.edited,
      },
    };
    context.dispatch("updateType", typePayload);
  },
  revertCellObject(context, payload) {
    context.commit("REVERT_CELL_OBJECT", payload);
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
