export default () => ({
  quote_list: [],
  quote_status: [],
  project_timeline: [],
  project_type: [],
  fixture_wiring: [],
  field_descriptions: {},
  dut_field_descriptions: {},
  quote: {},
  dut_details: [
    {
      dut_name: null,
    },
    {
      dut_name: null,
    },
  ],
  dut_details_view: [],
  gerber_files: [],
  sorted_dut: [],
  parsed_json_blob: {},
  parsed_test_points: [],
  parsed_guide_pins: [],
  parsed_pressure_pins: [],
  parsed_holes: [],
  parsed_dut_data: {},
  parsed_missing_headers: [],
  pcb_stackup: [],
  blank_svg: {
    top:{
      svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 0 0" style="enable-background:new 0 0 0 0;" xml:space="preserve">
        <g>
        </g>
        </svg>`,
      attributes: {
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        ["xmlns:xlink"]: "http://www.w3.org/1999/xlink",
        ["stroke-linecap"]: "round",
        ["stroke-linejoin"]: "round",
        ["stroke-width"]: 0,
        ["fill-rule"]: "evenodd",
        ["clip-rule"]: "evenodd",
        id: "orRjq2U5RysF_top",
        viewBox: "0 0 0 0",
      },
      defs: [],
      viewBox: [0, 0, 0, 0],
      width: 0,
      height: 0,
      units: "mm",
    },
    bottom:{
      svg: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 0 0" style="enable-background:new 0 0 0 0;" xml:space="preserve">
        <g>
        </g>
        </svg>`,
      attributes: {
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        ["xmlns:xlink"]: "http://www.w3.org/1999/xlink",
        ["stroke-linecap"]: "round",
        ["stroke-linejoin"]: "round",
        ["stroke-width"]: 0,
        ["fill-rule"]: "evenodd",
        ["clip-rule"]: "evenodd",
        id: "orRjq2U5RysF_bot",
        viewBox: "0 0 0 0",
      },
      defs: [],
      viewBox: [0, 0, 0, 0],
      width: 0,
      height: 0,
      units: "mm",
    },
  },
  dut_configs: [],
  dut_units: [],
  billing_address: null,
  shipping_address: null,
  addresses: [],
});
