import AuthService from "./endpoints";
export default {
  async signIn(context, payload) {
    context.dispatch("ui/loading", "Signing in", { root: true });
    try {
      const response = await AuthService.signIn(payload);
      context.commit(
        "SET_USER_DATA",
        {
          pk: response.data.user.pk,
          user: response.data.user.username,
          email: response.data.user.email,
          is_staff: response.data.user.is_staff,
          token: response.data.access_token,
          refreshToken: response.data.refresh_token,
        },
        false
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async signUp(context, payload) {
    context.dispatch("ui/loading", "Signing up", { root: true });
    try {
      const response = await AuthService.signUp(payload);
      context.dispatch("ui/loading", null, { root: true });
      return response.data.detail;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  updateToken(context, payload) {
    context.commit("SET_TOKEN", payload.access);
  },
  async tryLogin(context) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      context.commit("SET_USER_DATA", user, true);
      const response = await AuthService.getUserByPk(user.pk);
      context.commit("SET_IS_STAFF", response.data?.is_staff);
    }
  },
  async resetPassword(context, payload) {
    context.dispatch("ui/loading", "Resetting password", { root: true });
    try {
      const response = await AuthService.resetPassword(payload);
      context.dispatch("ui/loading", null, { root: true });
      return response.data.detail;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async resetConfirm(context, payload) {
    context.dispatch("ui/loading", "Confirming", { root: true });
    try {
      const response = await AuthService.resetConfirm(payload);
      context.dispatch("ui/loading", null, { root: true });
      return response.data.detail;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async saveUser(context, payload) {
    context.dispatch("ui/loading", "Saving", { root: true });
    try {
      const response = await AuthService.saveUser(payload);
      context.commit("UPDATE_USER_DATA", {
        user: response.data.username,
        email: response.data.email,
      });
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async changePassword(context, payload) {
    context.dispatch("ui/loading", "Updating", { root: true });
    try {
      const response = await AuthService.changePassword(payload);
      context.dispatch("ui/loading", null, { root: true });
      return response.data.detail;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  signout(context) {
    context.commit("CLEAR_USER_DATA");
    context.commit("CLEAR_REDIRECT");
  },
  clearModuleData(context) {
    context.commit("ui/RESET", null, { root: true });
    context.commit("quotes/RESET", null, { root: true });
    context.commit("projects/RESET", null, { root: true });
    context.commit("guidepins/INIT_GUIDE_PINS", null, { root: true });
    context.commit("pressurepins/INIT_PRESSURE_PINS", null, { root: true });
    context.commit("testpoints/INIT_TEST_POINTS", null, { root: true });
    context.commit("fixturedesign/INIT_ALL_STATE", null, { root: true });
    context.commit("dutconfigs/INIT_ALL_STATE", null, { root: true });
    context.commit("tpcb/RESET", null, { root: true });
  },
  clearUserData(context) {
    context.commit("CLEAR_USER_DATA");
    context.dispatch("testpoints/deleteAllTestPoints", null, { root: true });
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key]);
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
