<template>
  <v-toolbar flat min-width="100" class="mt-5 text--warning">
    <v-text-field
      v-model="selection"
      :label="label"
      single-line
      dense
      hide-details
      clearable
      :disabled="isSelectDisabled"
      @click:clear="clearFilters"
      @keydown.enter="
        filterQuotes({
          type: dataType,
          params: { [dataKey]: selection },
        })
      "
      data-test="input-text"
    >
      <template v-slot:prepend>
        <v-icon color="warning">mdi-magnify</v-icon>
      </template></v-text-field
    >
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          color="warning"
          :disabled="!selection"
          @click="
            filterQuotes({
              type: dataType,
              params: { [dataKey]: selection },
            })
          "
          v-on="on"
          data-test="filter-button"
          ><v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
      <span>Apply Project Name Filter</span>
    </v-tooltip>
  </v-toolbar>
</template>
<script>
export default {
  name: "SearchProjectTextField",
  props: {
    label: {
      type: String,
      default: null,
    },
    dataType: {
      type: String,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
    isSelectDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selection: null,
    };
  },
  methods: {
    clearFilters() {
      this.$emit("clearFilters");
    },
    filterQuotes(payload) {
      this.$emit("filterQuotes", payload);
    },
    updateSelection(payload) {
      this.selection = payload;
    },
  },
};
</script>
