import store from "@/store/index.js";

export const TpcbRoutes = {
  path: "/tpcb",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "project/:id",
      name: "tpcb-create",
      component: () => import("./views/TpcbDesigner.vue"),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of TPCB Designer Page");
        try {
          await store.dispatch("fixturedesign/getFixtureDesign", {
            params: { project_id: to.params.id },
          });
          await Promise.all([
            store.dispatch("tpcb/getTPCBConfig", {
              params: {
                fixture_design: store.getters["fixturedesign/fixtureDesign"].pk,
              },
            }),
            store.dispatch(
              "tpcb/setTestPoints",
              store.getters["fixturedesign/fixtureDesign"].dut_configs.reduce(
                (filtered, dut, indexes) => {
                  filtered.push(
                    ...dut.testpoints.map((element, index) => {
                      return {
                        pk: element.pk,
                        dut: indexes,
                        ref: element.ref,
                        net: element.net,
                        x:
                          index + 1 <= 5
                          ? 43
                          : 43 - (2.5 * ((index - (index % 5)) / 5)),
                        y:
                          index + 1 <= 5
                          ? 4 * (index + 1) + ((4 / 2) * indexes)
                          : 4 * ((index % 5) + 1) + ((4 / 2) * indexes),
                        checked: true,
                      };
                    })
                  );
                  return filtered;
                },
                []
              )
            ),
          ]);
          if (Object.keys(store.getters["tpcb/tpcbConfig"]).length > 0) {
            await store.dispatch("tpcb/getConnectorBlocks", {
              params: { tpcb_config: store.getters["tpcb/tpcbConfig"].pk },
            });
          } else {
            store.dispatch("tpcb/clearConnectorBlocks");
          }
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      },
    },
  ]
};