import jwtInterceptor from "@/helpers/interceptor";

export default {
  getPressurePins(params) {
    return jwtInterceptor.get(process.env.VUE_APP_PRESSURE_PINS_URL, params);
  },
  createPressurePin(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_PRESSURE_PINS_URL, payload);
  },
  patchPressurePin(id, data) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_PRESSURE_PINS_URL}${id}/`, data);
  },
  deletePressurePinById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_PRESSURE_PINS_URL}${id}/`);
  }
}
