export default {
  testPoints(state) {
    return state.test_points;
  },
  guidePins(state) {
    return state.guide_pins;
  },
  pressurePins(state) {
    return state.pressure_pins;
  },
  tpcbConfig(state) {
    return state.tpcb_config;
  },
  connectors(state) {
    return state.connectors;
  },
  connectorBlocks(state) {
    return state.connector_blocks;
  }
};