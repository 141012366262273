<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :src="require('@/assets/cover_pcb.jpg')"
      :mini-variant.sync="miniVariant"
      mini-variant-width="70"
      width="260"
      :clipped="clipped"
      fixed
      dark
      app
    >
      <template #img="props">
        <v-img
          gradient="rgba(0, 47, 54, .9), rgba(0, 47, 54, .7)"
          v-bind="props"
        />
      </template>
      <v-list rounded>
        <template v-for="(item, i) in items">
          <template v-if="item.subitems">
            <v-list-group :item="item" :key="i" :value="true" no-action>
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="white--text">{{
                  item.title
                }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(subitem, i) in item.subitems"
                :key="i"
                @click="onSelect(subitem)"
                :class="subitem.meta === $route.name ? 'isActive' : ''"
              >
                <v-list-item-title class="font-italic">{{
                  subitem.title
                }}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>{{ subitem.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </template>
          <v-list-item
            v-else
            :key="i"
            @click="
              menuActionClick({
                action: item.action,
                newtab: false,
                allowContext: item.allowContext,
              })
            "
            @contextmenu="
              activateMenu({
                nextRoute: {
                  action: item.action,
                  newtab: true,
                  allowContext: item.allowContext,
                },
                nextAction: 'menuActionClick',
                event: $event
              })
            "
            :class="item.meta === $route.name ? 'isActive' : ''"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="body-2">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append v-if="isAuthenticated">
        <v-list rounded>
          <v-list-item>
            <v-list-item-avatar color="alert" class="pl-0">
              <span class="white--text headline">{{ avatarInitial }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-light">{{
                user.user
              }}</v-list-item-title>
              <v-list-item-subtitle class="body-2 font-weight-light"
                >{{ user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="user.is_staff">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon small color="amber">mdi-account-tie</v-icon>
                  </v-btn>
                </template>
                <span>Admin Account</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list rounded>
          <v-list-item
            v-for="item in bottom_items"
            :key="item.title"
            @click="
              menuActionClick({
                action: item.action,
                newtab: false,
                allowContext: item.allowContext,
              })
            "
            @contextmenu="
              activateMenu({
                nextRoute: {
                  action: item.action,
                  newtab: true,
                  allowContext: item.allowContext,
                },
                nextAction: 'menuActionClick',
                event: $event
              })
            "
            v-show="!item.adminOnly || isAdmin"
            :class="item.action === $route.name ? 'isActive' : ''"
          >
            <v-list-item-icon left>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="body-2">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.adminOnly">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon small color="amber">mdi-account-tie</v-icon>
                  </v-btn>
                </template>
                <span>Admin Only Access</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" color="primary" fixed dark app>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        data-cy="nav-menu-burger"
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="desktop" icon @click="toggleVariant" v-on="on">
            <v-icon
              >mdi-{{ `chevron-${miniVariant ? "right" : "left"}` }}</v-icon
            >
          </v-btn>
        </template>
        <span>Toggle Mini Variant</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="desktop" icon @click.stop="clipped = !clipped" v-on="on">
            <v-icon>mdi-application</v-icon>
          </v-btn>
        </template>
        <span>Toggle Clip Side Menu</span>
      </v-tooltip>
      <v-btn v-if="desktop" icon @click.stop="fixed = !fixed">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-if="desktop" icon @click="initFullScreen" v-on="on">
            <v-icon>mdi-{{ `fullscreen${fullscreen ? "-exit" : ""}` }}</v-icon>
          </v-btn>
        </template>
        <span
          >{{ `${fullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}` }}
        </span>
      </v-tooltip>
      <span class="ml-10 font-weight-light"
        >Questions? Contact
        <a class="amber--text no-underline" href="mailto:support@fixturfab.com"
          >support@fixturfab.com</a
        ></span
      >
      <v-spacer />
      <v-toolbar-title>
        <v-list-item-avatar>
          <v-img :src="require('@/assets/logo_white.svg')" />
        </v-list-item-avatar>
        <a
          class="white--text ml-n5 no-underline"
          href="https://fixturfab.com"
          >{{ title }}</a
        >
      </v-toolbar-title>
    </v-app-bar>
    <v-footer fixed app>
      <span class="body-2"
        >Made with <span class="red--text">❤</span> FixturFab &copy;
        {{ new Date().getFullYear() }}</span
      >
    </v-footer>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @signOut="signOutHandler"
      @donothing="stopDialog"
    />
    <BaseSubMenuPopup
      :showMenu="showMenu"
      :xCoord="xCoord"
      :yCoord="yCoord"
      :nextAction="nextAction"
      @onClick="subMenuClickHandler"
      @onCopyUrl="subMenuCopyUrl"
    />
  </div>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import SubMenuPopup from "@/mixins/SubMenuPopup";
export default {
  name: "AuthLayout",
  mixins: [BottomDialog, SubMenuPopup],
  data() {
    return {
      clipped: true,
      drawer: true,
      fixed: false,
      items: [
        {
          title: "View Quotes",
          icon: "mdi-paper-roll",
          action: "quotes",
          meta: "quotes",
          allowContext: true,
        },
        {
          title: "Request for Quote",
          icon: "mdi-receipt",
          action: "request-for-quote",
          meta: "request-for-quote",
          allowContext: true,
        },
      ],
      bottom_items: [
        {
          title: "Fixture Designs",
          icon: "mdi-folder",
          action: "projects",
          adminOnly: true,
          allowContext: true,
        },
        {
          title: "Company Profiles",
          icon: "mdi-domain",
          action: "company-profile",
          adminOnly: true,
          allowContext: true,
        },
        {
          title: "User Profile",
          icon: "mdi-account",
          action: "user-profile",
          adminOnly: false,
          allowContext: false,
        },
        {
          title: "Sign Out",
          icon: "mdi-exit-run",
          action: "signout",
          adminOnly: false,
          allowContext: false,
        },
      ],
      right: true,
      rightDrawer: false,
      title: "FixturFab",
      fullscreen: false,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    avatarInitial() {
      return this.user.user.charAt(0).toUpperCase();
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    desktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isAdmin() {
      return this.$store.state.auth.user.is_staff;
    },
    miniVariant: {
      get() {
        return this.$store.getters["ui/miniVariant"];
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    async menuActionClick({ action, newtab }) {
      if (action == "signout") {
        this.confirmAction = "signOut";
        this.confirmMessage = `Are you sure to Sign Out now?`;
        this.bottomDialog = true;
      } else {
        if (action === this.$router.currentRoute.name) return;
        if (newtab) {
          let routeData = this.$router.resolve({ name: action });
          window.open(routeData.href, "_blank");
        } else {
          this.$router.push({ name: action });
        }
      }
    },
    toggleVariant() {
      this.$store.commit("ui/MINI_VARIANT");
    },
    signOutHandler() {
      this.stopDialog();
      this.$store.dispatch("auth/signout");
      this.$router.replace({ name: "signin" });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (!this.fullscreen) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
        this.fullscreen = !this.fullscreen;
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        this.fullscreen = !this.fullscreen;
      }
    },
    async menuActionClickCopyUrl({ action }) {
      const routeFull = new URL(
        this.$router.resolve({
          name: action,
        }).href,
        window.location.origin
      ).href;
      console.log(routeFull);
      await navigator.clipboard.writeText(routeFull);
    },
  },
};
</script>
<style scoped>
.isActive {
  background: #950952;
}
</style>
