import Vue from "vue";
import Vuex from "vuex";

import UiStore from '../modules/common/store';
import AuthStore from '../modules/auth/store';
import ProjectStore from '../modules/project-flow/store';
import QuoteStore from '../modules/quotes/store';
import DutConfigStore from '../modules/dutconfigs/store';
import TestPointStore from '../modules/configure/store/testpoints';
import GuidePinStore from '../modules/configure/store/guidepins';
import PressureStore from '../modules/configure/store/pressurepins';
import FixtureStore from '../modules/design-packages/store';
import TpcbStore from '../modules/tpcb/store';
import SvgDrawingStore from '../modules/svg-drawing/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui: UiStore,
    auth: AuthStore,
    projects: ProjectStore,
    quotes: QuoteStore,
    dutconfigs: DutConfigStore,
    testpoints: TestPointStore,
    guidepins: GuidePinStore,
    pressurepins: PressureStore,
    fixturedesign: FixtureStore,
    tpcb: TpcbStore,
    svgdrawing: SvgDrawingStore,
  }
});