export default {
  UPDATE_TESTPOINTS(state, payload) {
    state.testpoint_plot = payload;
  },
  UPDATE_GUIDEPINS(state, payload) {
    state.guidepin_plot = payload;
  },
  UPDATE_PRESSUREPINS(state, payload) {
    state.pressurepin_plot = payload;
  },
  UPDATE_PROBE_GROUP(state, payload) {
    state.probe_group = payload;
  },
  UPDATE_RECEPTACLE_GROUP(state, payload) {
    state.receptacle_group = payload;
  },
  UPDATE_GUIDEPIN_GROUP(state, payload) {
    state.guidepin_group = payload;
  },
  UPDATE_PRESSUREPIN_GROUP(state, payload) {
    state.pressurepin_group = payload;
  },
  UPDATE_PROBE_COLOR(state, { probePk, index, hex }) {
    state.probe_group[index].color = hex;
    state.testpoint_plot.forEach(element => {
      if (element.probePk == probePk) {
        element.pcolor = hex
      }
    });
  },
  UPDATE_RECEPTACLE_COLOR(state, { receptaclePk, index, hex }) {
    state.receptacle_group[index].color = hex;
    state.testpoint_plot.forEach(element => {
      if (element.receptaclePk == receptaclePk) {
        element.rcolor = hex
      }
    });
  },
  UPDATE_GUIDEPIN_COLOR(state, { guidepinPk, index, hex }) {
    state.guidepin_group[index].color = hex;
    state.guidepin_plot.forEach(element => {
      if (element.guide_pin_assemblyPk == guidepinPk) {
        element.color = hex
      }
    });
  },
  UPDATE_PRESSUREPIN_COLOR(state, { pressurepinPk, index, hex }) {
    state.pressurepin_group[index].color = hex;
    state.pressurepin_plot.forEach(element => {
      if (element.pressure_pin_assemblyPk == pressurepinPk) {
        element.color = hex
      }
    });
  },
};