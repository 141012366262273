import store from "@/store/index.js";

export const ConfigRoutes = {
  path: "/dutconfig",
  component: () => import("../Module.vue"),
  children: [
    {
      path: ":id",
      name: "dutconfig",
      component: () => import("./views/DutConfigCreate.vue"),
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of DUT Config View");
        try {
          store.dispatch("dutconfigs/initQuoteAndDut");
          await store.dispatch("projects/fetchProject", to.params.id);
          await Promise.all([
            store.dispatch("fixturedesign/getFixtureDesign", {
              params: { project_id: to.params.id },
            })
          ]);
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request (DUT Configs), try later."
          );
        }
      },
    },
    {
      path: "project/:id/dut/:did",
      name: "edit-dutconfig",
      component: () => import("./views/DutConfigEdit.vue"),
      props: true,
      meta: { requiresAuth: true, requiresAdmin: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of selected DUT");
        try {
          store.dispatch("dutconfigs/initQuoteAndDut");
          await store.dispatch("fixturedesign/getFixtureDesign", {
            params: { project_id: to.params.id },
          });
          await Promise.all([
            store.dispatch("fixturedesign/getFixtureDesignState"),
            store.dispatch("dutconfigs/getDutConfigsById", to.params.did),
            store.dispatch("dutconfigs/getTestPointOptions"),
            store.dispatch("dutconfigs/getDutDetailsOptions"),
            store.dispatch("dutconfigs/getDutFieldDescriptions"),
            store.dispatch("projects/fetchProject", to.params.id),
          ]);
          if (store.getters["fixturedesign/fixtureDesign"].quote) {
            await store.dispatch("dutconfigs/getQuote", store.getters["fixturedesign/fixtureDesign"].quote);
          }
          if (store.getters["dutconfigs/dutConfigs"].dut_details) {
            await store.dispatch("dutconfigs/getDutDetailsById", store.getters["dutconfigs/dutConfigs"].dut_details);
          }
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request (DUT Configs), try later."
          );
        }
      },
    },
  ]
};
