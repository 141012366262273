<template>
  <v-bottom-sheet v-model="snackbar.status">
    <v-sheet class="text-center" height="200px">
      <div class="my-10 pt-12 warning--text" data-test="message" data-cy="input-warning-dialog-message">
        {{ snackbar.message }}
      </div>
      <v-btn class="py-3" color="primary" @click="emitHandler" data-test="closeButton" data-cy="input-warning-dialog-close-button">
        Close
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "SnackBarDialog",
  props: {
    snackbar: Object,
  },
  methods: {
    emitHandler() {
      this.$emit("snackOff");
    },
  },
};
</script>
