<template>
  <v-bottom-sheet v-model="snackLink.status">
    <v-sheet class="text-center" height="200px">
      <div
        class="my-10 pt-12 warning--text"
        data-test="message"
        data-cy="input-warning-link-message"
      >
        {{ snackLink.message1
        }}<span v-if="snackLink.link" data-test="link"
          ><a
            :target="snackLink.email ? '_self' : '_blank'"
            :href="snackLink.link.url"
            @click="emitHandler"
            >{{ snackLink.link.text }}</a
          >{{ snackLink.message2 }}</span
        >
      </div>
      <v-btn
        class="py-3"
        color="primary"
        @click="emitHandler"
        data-test="closeButton"
        data-cy="input-warning-link-close-button"
      >
        Close
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "SnackLinkDialog",
  props: {
    snackLink: Object,
  },
  methods: {
    emitHandler() {
      this.$emit("snackLinkOff");
    },
  },
};
</script>
