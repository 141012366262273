export default {
  dutConfigs(state) {
    return state.dut_configs;
  },
  receptacles(state) {
    return state.receptacles;
  },
  probes(state) {
    return state.probes;
  },
  guidePins(state) {
    return state.guidepins;
  },
  pressurePins(state) {
    return state.pressurepins;
  },
  testpointLayers(state) {
    return state.testpoint_layers;
  },
  testpointTypes(state) {
    return state.testpoint_types;
  },
  guidepinLayers(state) {
    return state.guidepin_layers;
  },
  renderSettings(state) {
    return state.render_settings;
  },
  dutConfigsList(state) {
    return state.dut_configs_list.sort((a, b) => (a.pk > b.pk ? 1 : -1));
  },
  gerberFiles(state) {
    return state.gerber_files;
  },
  pcbStackup(state) {
    return state.pcb_stackup;
  },
  ppStackup(state) {
    return state.pp_stackup;
  },
  zipGerberFiles(state) {
    return state.zip_gerber_files.sort((a, b) => (a.side > b.side ? 1 : -1));
  },
  parsedJsonBlob(state) {
    return state.parsed_json_blob;
  },
  parsedTestPoints(state) {
    return state.parsed_test_points;
  },
  parsedGuidePins(state) {
    return state.parsed_guide_pins;
  },
  parsedPressurePins(state) {
    return state.parsed_pressure_pins;
  },
  parsedHoles(state) {
    return state.parsed_holes;
  },
  parsedDutData(state) {
    return state.parsed_dut_data;
  },
  parsedMissingHeaders(state) {
    return state.parsed_missing_headers;
  },
  blankSvg(state) {
    return state.blank_svg;
  },
  dutUnits(state) {
    return state.dut_units;
  },
  dutFieldDescriptions(state) {
    return state.dut_field_descriptions;
  },
  quote(state) {
    return state.quote;
  },
  dutDetails(state) {
    return state.dut_details;
  },
};
