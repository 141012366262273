export default () => ({
  project_list: [],
  fixtures_configs: [],
  connection_types: [],
  tip_styles: [],
  selected: {
    project: {
      fixture_design_details: {},
    },
  },
  users_list: [],
  user: null,
  company: null,
  company_list: [],
  dut_configs: [],
  dut_details: [],
  gerber_files: [],
  pcb_stackup: [],
  quote: {},
})