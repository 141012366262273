<template>
  <v-toolbar dense flat dark rounded color="secondary">
    <v-toolbar-title><slot /></v-toolbar-title>
  </v-toolbar>
</template>
<script>
export default {
  name: "PageTitle",
};
</script>
