export default {
  SET_FIXTURE_DESIGN(state, payload) {
    state.fixture_design = payload;
  },
  /* SET_INVOICE(state, payload) {
    state.invoice = payload;
  }, */
  /* SET_CUSTOMERS(state, payload) {
    state.customers = payload.map((o) => ({
      pk: o.id,
      email: o.email,
    }));
  }, */
  /* APPEND_CUSTOMER(state, payload) {
    state.customers.push({
      pk: payload.id,
      email: payload.email,
    });
  }, */
  SET_FIXTURE_DESIGN_STATE(state, payload) {
    state.fd_state = payload.actions.POST.state.choices.map((o) => ({
      pk: o.value,
      description: o.display_name,
    }));
    state.ir_state = payload.actions.POST.internal_review_sub_state.choices.map(
      (o) => ({
        pk: o.value,
        description: o.display_name,
      })
    );
    state.fa_state = payload.actions.POST.fabrication_assembly_sub_state.choices.map(
      (o) => ({
        pk: o.value,
        description: o.display_name,
      })
    );
  },
  SET_DESIGN_FILE_OPTIONS(state, payload) {
    state.design_file_options = payload.reduce(
      (fileUpload, element) => {
        if (element[1].type === "file upload") {
          fileUpload.push({ name: element[0], data: element[1] });
        }
        return fileUpload;
      },
      []
    );
  },
  SET_DESIGN_FILE_PACKAGES(state, payload) {
    state.design_file_packages.push(...payload);
  },
  INIT_DESIGN_FILE_PACKAGES(state) {
    state.design_file_packages.splice(0);
  },
  UPDATE_DESIGN_FILE_PACKAGE(state, payload) {
    const record = state.design_file_packages.find(
      (element) => element.id == payload.id
    );
    if (record) {
      Object.assign(record, payload);
    }
  },
  INIT_ALL_STATE(state) {
    state.fixture_design = {
      design_file_packages: [],
      fixture_config: {
        description: null,
        center_coordinate: null,
      },
      dut_config: {
        testpoints: [],
        guide_pins: [],
      },
    };
    //state.invoice = {};
    //state.customers.splice(0);
    state.fd_state.splice(0);
    state.ir_state.splice(0);
    state.fa_state.splice(0);
    state.design_file_options.splice(0);
    state.design_file_packages.splice(0);
  },
};
