export default {
  SET_USER_DATA(state, payload, autoLogin) {
    state.user =  payload;
    if (!autoLogin) {
      localStorage.setItem("user", JSON.stringify(payload));
    }
  },
  CLEAR_USER_DATA(state) {
    state.user =  null;
    localStorage.removeItem("user");
  },
  SET_TOKEN(state, payload) {
    state.user.token = payload;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  UPDATE_USER_DATA(state, payload) {
    state.user.user = payload.user;
    state.user.email = payload.email;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  SET_IS_STAFF(state, payload) {
    state.user.is_staff = payload;
    localStorage.setItem("user", JSON.stringify(state.user));
  },
  CLEAR_REDIRECT() {
    localStorage.removeItem("redirect");
  },
};