<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          fab
          top
          :absolute="absolute"
          :left="left"
          :right="right"
          :color="color"
          :disabled="disabled"
          class="ma-2"
          @click="gotoLink(route, nextRoute, next)"
          :data-cy="dataCy"
        >
          <v-icon>{{ navIcon }}</v-icon>
        </v-btn>
      </span>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "NavigationButton",
  props: {
    tooltipText: String,
    nextRoute: {
      type: String,
      default: null,
    },
    navIcon: String,
    color: String,
    route: Boolean,
    next: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: "navigation-button",
    },
  },
  methods: {
    gotoLink(route, link) {
      if (route) {
        this.$router.push({ name: link });
      } else {
        this.$emit("action");
      }
    },
  },
};
</script>
