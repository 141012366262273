<template>
  <div class="text-center">
    <v-menu open-on-hover top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          dense
          v-bind="attrs"
          v-on="on"
          class="moduleLink subtitle-1 text-center"
          max-width="390"
          >{{
            activatorText
          }}</v-sheet
        >
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title>{{ item.filename }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "ListMenuPopup",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activatorText: {
      type: String,
      default: null,
    },
  },
  computed: {
    show: {
      get() {
        return this.showMenu;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick", this.nextAction);
    },
    onCopyUrl() {
      this.$emit("onCopyUrl", this.nextAction);
    },
  },
};
</script>
