import store from "@/store/index.js";

export const QuoteRoutes = {
  path: "/quotes",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "/",
      name: "quotes",
      component: () => import("./views/QuotesIndex.vue"),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "request-for-quote",
      name: "request-for-quote",
      component: () => import("./views/RequestForQuote.vue"),
      meta: { requiresAuth: true },
      beforeEnter: async (_, __, next) => {
        store.dispatch("ui/loading", "Pre-loading data of Request for Quote");
        try {
          store.dispatch("quotes/initQuoteAndDut");
          store.dispatch("ui/initQuoteTabs");
          await store.dispatch(
            "projects/fetchUserById",
            store.getters["auth/user"].pk
          );
          await Promise.all([
            store.dispatch("quotes/getQuoteFieldDescriptions"),
            store.dispatch("quotes/getDutFieldDescriptions"),
          ]);
          await Promise.all([
            store.dispatch("quotes/getQuoteStatus"),
            store.dispatch("quotes/getDutDetailsOptions"),
            store.dispatch("dutconfigs/getTestPointOptions"),
          ]);
          if (store.getters["projects/projectUser"].groups.length > 0) {
            await store.dispatch("projects/fetchCompanyById", {
              id: store.getters["projects/projectUser"].groups[0].id,
              editMode: false,
            });
          }
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request (Quotes), try later."
          );
        }
      },
    },
    {
      path: "edit/:id",
      name: "quote-edit",
      component: () => import("./views/UpdateQuote.vue"),
      props: true,
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch("ui/loading", "Pre-loading data of selected Quote");
        try {
          store.dispatch("quotes/initQuoteAndDut");
          store.dispatch("ui/initQuoteTabs");
          store.dispatch("ui/initUIDetails");
          store.dispatch("projects/initUserCompany");
          const response = await store.dispatch(
            "quotes/getQuote",
            to.params.id
          );
          await Promise.all([
            store.dispatch("quotes/getQuoteFieldDescriptions"),
            store.dispatch("quotes/getDutFieldDescriptions"),
            store.dispatch("projects/fetchUserById", response.data.user),
          ]);
          await Promise.all([
            store.dispatch("quotes/getQuoteStatus"),
            store.dispatch("quotes/getDutDetailsOptions"),
            store.dispatch("dutconfigs/getTestPointOptions"),            
          ]);
          await store.dispatch("quotes/getDutDetails", {
            params: { quote_id: to.params.id },
          });
          if (store.getters["projects/projectUser"].groups.length > 0) {
            await store.dispatch("projects/fetchCompanyById", {
              id: store.getters["projects/projectUser"].groups[0].id,
              editMode: true,
            });
          }
          if (response.data.billing_address) {
            await store.dispatch("quotes/getQuoteAddress", { id: response.data.billing_address, isBilling: true });
          }
          if (response.data.shipping_address) {
            await store.dispatch("quotes/getQuoteAddress", { id: response.data.shipping_address, isBilling: false });
          }
          store.dispatch("ui/loading", null);
          next();
        } catch (err) {
          store.dispatch("ui/loading", null);
          store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request (Quotes), try later."
          );
        }
      },
    },
  ],
};
