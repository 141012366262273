import DutConfigService from "./endpoints";
import QuoteService from "@/modules/quotes/store/endpoints";
import pcbStackup from "pcb-stackup";
export default {
  async saveDutConfigs(context, payload) {
    context.dispatch("ui/loading", "One moment please, saving DUT Configs", {
      root: true,
    });
    try {
      const response = await DutConfigService.createDutConfigs(payload);
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async updateDutConfigs(context, payload) {
    try {
      const response = await DutConfigService.patchDutConfigs(payload);
      console.log("PATCH DUT CONFIG", response.data);
      context.commit("SET_DUT_CONFIGS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateDutConfigById(context, payload) {
    try {
      const response = await DutConfigService.patchDutConfigs(payload);
      console.log("PATCH DUT CONFIG", response.data);
      context.commit("SET_DUT_CONFIGS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getAllDutConfigs(context, params) {
    try {
      context.commit("INIT_DUT_CONFIGS_LIST");
      const init_response = await DutConfigService.fetchAllDutConfigs(params);
      context.commit("SET_ALL_DUT_CONFIGS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await DutConfigService.fetchAllDutConfigs({ params: Object.assign(params.params, { page: i })});
          context.commit("SET_ALL_DUT_CONFIGS", response.data.results);
        }
      }
      return init_response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutConfigsById(context, id) {
    try {
      const response = await DutConfigService.getDutConfigsById(id);
      context.commit("SET_DUT_CONFIGS", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async removeDutById(context, payload) {
    context.dispatch("ui/loading", "Deleting DUT Configs", { root: true });
    try {
      await DutConfigService.deleteDutById(payload);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getDutConfigComponents(context, { params, dutParams, dutId, fdId }) {
    try {
      await context.dispatch("getReceptacles");
      await context.dispatch("getProbes");
      await context.dispatch("getGuidePins");
      await context.dispatch("getPressurePins");
      await Promise.all([
        context.dispatch("getTestPointOptions"),
        context.dispatch("getGuidePinOptions"),
        context.dispatch("getFixtureDesignOptions"),
        context.dispatch("getDutConfigsById", dutId),
        context.dispatch("projects/getTipStyles", null, {
          root: true,
        }),
        context.dispatch("projects/getConnectionTypes", null, {
          root: true,
        }),
        context.dispatch(
          "testpoints/getTestPoints",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
        context.dispatch(
          "guidepins/getGuidePinList",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
        context.dispatch(
          "pressurepins/getPressurePinList",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
      ]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getSvgDrawingComponents(context, { params, dutParams, dutId, fdId }) {
    try {
      await context.dispatch("getReceptacles");
      await context.dispatch("getProbes");
      await context.dispatch("getGuidePins");
      await context.dispatch("getPressurePins");
      await Promise.all([
        context.dispatch("getTestPointOptions"),
        context.dispatch("getGuidePinOptions"),
        context.dispatch("getDutConfigsById", dutId),
        context.dispatch(
          "testpoints/getTestPoints",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
        context.dispatch(
          "guidepins/getGuidePinList",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
        context.dispatch(
          "pressurepins/getPressurePinList",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
      ]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getTestPointsData(context, { params, dutParams, fdId }) {
    try {
      await context.dispatch(
        "pressurepins/getPressurePinList",
        { params, dutParams, fdId },
        {
          root: true,
        }
      );
      await Promise.all([
        context.dispatch(
          "testpoints/getTestPoints",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
        context.dispatch(
          "guidepins/getGuidePinList",
          { params, dutParams, fdId },
          {
            root: true,
          }
        ),
      ]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getGuidePins(context) {
    try {
      context.commit("INIT_GUIDE_PINS");
      const init_response = await DutConfigService.getGuidePins({
        params: { page: 1 },
      });
      context.commit("SET_GUIDE_PINS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await DutConfigService.getGuidePins({
            params: { page: i },
          });
          context.commit("SET_GUIDE_PINS", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getPressurePins(context) {
    try {
      context.commit("INIT_PRESSURE_PINS");
      const init_response = await DutConfigService.getPressurePins({
        params: { page: 1 },
      });
      context.commit("SET_PRESSURE_PINS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await DutConfigService.getPressurePins({
            params: { page: i },
          });
          context.commit("SET_PRESSURE_PINS", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getReceptacles(context) {
    try {
      context.commit("INIT_RECEPTACLES");
      const init_response = await DutConfigService.getReceptacles({
        params: { page: 1 },
      });
      context.commit("SET_RECEPTACLES", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await DutConfigService.getReceptacles({
            params: { page: i },
          });
          context.commit("SET_RECEPTACLES", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getProbes(context) {
    try {
      context.commit("INIT_PROBES");
      const init_response = await DutConfigService.getProbes({
        params: { page: 1 },
      });
      context.commit("SET_PROBES", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await DutConfigService.getProbes({
            params: { page: i },
          });
          context.commit("SET_PROBES", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getTestPointOptions(context) {
    try {
      const response = await DutConfigService.getTestPointOptions();
      context.commit("SET_TEST_POINT_OPTIONS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getGuidePinOptions(context) {
    try {
      const response = await DutConfigService.getGuidePinOptions();
      context.commit("SET_GUIDE_PIN_OPTIONS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getFixtureDesignOptions(context) {
    try {
      const response = await DutConfigService.getFixtureDesignOptions();
      context.commit("SET_FIXTURE_DESIGN_OPTIONS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  initFixtureRenderState(context) {
    context.commit("INIT_FIXTURE_RENDER_STATE");
  },
  initDutConfigsState(context) {
    context.commit("INIT_ALL_STATE");
  },
  async saveGerberFile(context, payload) {
    try {
      const response = await DutConfigService.createGerberFile(payload);
      console.log(response);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async patchBulkGerbers(context, payload) {
    try {
      const response = await DutConfigService.patchBulkGerbers(payload);
      console.log("Patch Bulk Gerbers", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async removeGerberById(context, { pk, dutPk }) {
    context.dispatch("ui/loading", "Deleting Gerber File", { root: true });
    try {
      await DutConfigService.deleteGerberById(pk);
      await DutConfigService.getDutConfigsById(dutPk);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  removeAllGerberFile(context) {
    context.state.gerber_files.forEach(async (file, index) => {
      try {
        await DutConfigService.deleteGerberById(file.pk);
        if (context.state.gerber_files.length - 1 === index) {
          context.commit("INIT_GERBER_FILES");
        }
      } catch (err) {
        context.dispatch("errorHandler", err);
      }
    });
  },
  removeOldGerberFile(context, payload) {
    payload.forEach(async (file, index) => {
      try {
        await DutConfigService.deleteGerberById(file.pk);
        if (payload.length - 1 === index) {
          context.commit("INIT_GERBER_FILES");
        }
      } catch (err) {
        context.dispatch("errorHandler", err);
      }
    });
  },
  async getPCBStackup(context) {
    try {
      const layers = await Promise.all(
        context.state.dut_configs.individual_gerber_files.map(
          async (element) => ({
            filename: element.file,
            gerber: await context.dispatch("getFileString", element.file),
          })
        )
      );
      const response = await pcbStackup(layers);
      context.commit("SET_PCB_STACKUP", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getFileString(context, payload) {
    try {
      const data = await fetch(payload);
      const svgString = await data.text();
      return svgString;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  saveZipGerberFiles(context, { valid, payload }) {
    if (valid) {
      context.commit("SAVE_ZIP_GERBER_FILES", payload);
    }
  },
  async parseTestPointFile(context, payload) {
    try {
      const response = await DutConfigService.parseTestPointFile(payload);
      console.log("Parsed TP: ", response);
      context.commit("SET_PARSED_FILE", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetailsOptions(context) {
    try {
      const response = await QuoteService.getDutDetailsOptions();
      context.commit("SET_DUT_DETAILS_OPTIONS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutFieldDescriptions(context) {
    try {
      const response = await QuoteService.getDutFieldDescriptions();
      context.commit("SET_DUT_FIELD_DESCRIPTIONS", response.data.results[0]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getQuote(context, payload) {
    try {
      const response = await QuoteService.getQuoteById(payload);
      console.log("Quote By ID via Dut Configs", response);
      context.commit("SET_QUOTE", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveQuote(context, { editMode, id, data }) {
    try {
      let response;
      if (editMode) {
        response = await QuoteService.updateQuote({ id, data });
      } else {
        response = await QuoteService.saveQuote(data);
      }
      context.commit("SET_QUOTE", response.data);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetails(context, payload) {
    try {
      const response = await QuoteService.getDutDetails(payload);
      console.log("GET DUT Detail from DUT Configs:", response);
      return response.data.results;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetailsById(context, payload) {
    try {
      const response = await QuoteService.getDutDetailsById(payload);
      console.log("Update DUT Detail via Dut Configs", response);
      context.commit("SET_DUT_DETAILS", response.data);
      context.commit("SET_GERBER_FILES", response.data.gerber_layer_file);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveDutDetail(context, { editMode, id, data }) {
    try {
      let response;
      if (editMode) {
        response = await QuoteService.updateDutDetail({ id, data });
        console.log("DUT Detail:", response);
      } else {
        response = await QuoteService.saveDutDetail(data);
        console.log("POST DUT Detail via Dut Configs", response);
      }
      context.commit("SET_DUT_DETAILS", response.data);
      context.commit("SET_GERBER_FILES", response.data.gerber_layer_file);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveTestPointsBlob(context, payload) {
    try {
      const response = await DutConfigService.postTestPoints(payload);
      console.log("Post Test Point Blob", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateTestPointsBlob(context, payload) {
    try {
      const response = await DutConfigService.patchTestPoints(payload);
      console.log("Patch Test Point Blob", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  initQuoteAndDut(context) {
    context.commit("INIT_QUOTE_AND_DUT");
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request (DUT Configs). Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
