export default () => ({
  label_group: {
    designer: null,
    designer_date: null,
    reviewer: null,
    reviewed_date: null,
    size: null,
    scale: null,
    revision: null,
    title: null,
    part_number: null,
    subtitle: null,
    drawing_number: null,
    sheet: null
  },
  testpoint_plot: [],
  guidepin_plot: [],
  pressurepin_plot: [],
  probe_group: [],
  receptacle_group: [],
  guidepin_group: [],
  pressurepin_group: [],
})