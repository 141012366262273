export const FulfillRoutes = {
  path: "/fulfillment",
  component: () => import("../Module.vue"),
  children: [
    {
      path: "project/:id",
      name: "fulfillment",
      component: () => import("./views/FulfillmentPage.vue"),
      meta: { requiresAuth: true },
    },
  ]
};