import TpcbService from "./endpoints";
export default {
  setTestPoints(context, payload) {
    context.commit("SET_TEST_POINTS", payload);
  },
  setGuidePins(context, payload) {
    context.commit("SET_GUIDE_PINS", payload);
  },
  setPressurePins(context, payload) {
    context.commit("SET_PRESSURE_PINS", payload);
  },
  async getTPCBConfig(context, params) {
    try {
      const response = await TpcbService.getTPCBConfig(params);
      context.commit("SET_TPCB_CONFIG", response.data.results[0]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async postTPCBConfig(context, payload) {
    context.dispatch("ui/loading", "Saving TPCB Config", { root: true });
    try {
      const response = await TpcbService.postTPCBConfig(payload);
      context.commit("UPDATE_TPCB_CONFIG", response.data);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getConnectors(context) {
    try {
      context.commit("INIT_CONNECTORS");
      const init_response = await TpcbService.getConnectors({
        params: { page: 1 },
      });
      context.commit("SET_CONNECTORS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await TpcbService.getConnectors({
            params: { page: i },
          });
          context.commit("SET_CONNECTORS", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getConnectorBlocks(context, params) {
    try {
      context.commit("INIT_CONNECTOR_BLOCKS");
      const init_response = await TpcbService.getConnectorBlocks(params);
      context.commit("SET_CONNECTOR_BLOCKS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await TpcbService.getConnectorBlocks({
            params: Object.assign(params.params, { page: i }),
          });
          context.commit("SET_CONNECTOR_BLOCKS", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async postConnectorBlock(context, payload) {
    try {
      const response = await TpcbService.postConnectorBlock(payload);
      context.commit("UPDATE_CONNECTOR_BLOCKS", response.data);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async patchConnectorBlock(context, payload) {
    try {
      const response = await TpcbService.patchConnectorBlock(payload);
      context.commit("PATCH_CONNECTOR_BLOCK", response.data);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async deleteConnectorBlock(context, payload) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await TpcbService.deleteConnectorBlock(payload);
      context.commit("DELETE_CONNECTOR_BLOCK", payload);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async patchPinBlock(context, payload) {
    try {
      const response = await TpcbService.patchPinBlock(payload);
      context.commit("UPDATE_PIN_BLOCK", response.data);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async deleteTPCBConfig(context, pk) {
    context.dispatch("ui/loading", "Deleting TPCB", { root: true });
    try {
      await TpcbService.deleteTPCBById(pk);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async clearConnectorBlocks(context) {
    context.commit("INIT_CONNECTOR_BLOCKS");
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
