<template>
  <v-bottom-sheet persistent v-model="dialog">
    <v-sheet class="mx-auto" outlined>
      <v-row dense class="text-center">
        <v-col>
          <v-btn class="mt-5" color="primary" @click="emitHandler">
            Close
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="items && items.length > 0">
        <v-slide-group class="pa-4" show-arrows>
          <v-slide-item v-for="item in items" :key="item.pk">
            <base-item-card
              class="ma-4"
              :pk="item.pk"
              :name="item.name"
              :description="item.description"
              :thumbnail="item.thumbnail"
              :selected="selected"
              @toggle="toggle"
            />
          </v-slide-item>
        </v-slide-group>
      </div>
      <v-layout v-else class="justify-center pa-4" row>
        <span class="body-2 font-weight-bold">No item to display</span>
      </v-layout>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "BottomSelect",
  props: {
    dialog: Boolean,
    items: Array,
    selected: Object,
    callback: String,
  },
  methods: {
    toggle(payload) {
      this.$emit(this.callback, payload);
    },
    emitHandler() {
      this.$emit("dialogOff");
    },
  },
};
</script>
