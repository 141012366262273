import Vue from "vue";
import VueRouter from "vue-router";
import { AuthRoutes } from "@/modules/auth/router";
import { ProjectRoutes } from "@/modules/project-flow/router";
import { QuoteRoutes } from "@/modules/quotes/router";
import { ConfigRoutes } from "@/modules/dutconfigs/router";
import { ConfigDutRoutes } from "@/modules/configure/router";
import { RenderRoutes } from "@/modules/render/router";
import { FulfillRoutes } from "@/modules/fulfillment/router";
import { DesignRoutes } from "@/modules/design-packages/router";
import { TpcbRoutes } from "@/modules/tpcb/router";
import { ProfileRoutes } from "@/modules/user-profile/router";
import { CompanyRoutes } from "@/modules/company-profile/router";
import { CommonRoutes } from "@/modules/common/router";
import { SvgRoutes } from "@/modules/svg-drawing/router";

import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "signin" },
  },
  { ...AuthRoutes },
  { ...ProjectRoutes },
  { ...QuoteRoutes },
  { ...ConfigRoutes },
  { ...ConfigDutRoutes },
  { ...RenderRoutes },
  { ...FulfillRoutes },
  { ...DesignRoutes },
  { ...TpcbRoutes },
  { ...ProfileRoutes },
  { ...CompanyRoutes },
  { ...CommonRoutes },
  { ...SvgRoutes },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async(to, _, next) => {
  !store.getters["auth/isAuthenticated"] && await store.dispatch("auth/tryLogin");
  if (to.meta.requiresAuth && !store.getters["auth/isAuthenticated"]) {
    next({ name: "signin", query: { redirect: to.fullPath }});
  } else if (to.meta.requiresUnAuth && store.getters["auth/isAuthenticated"]) {
    next("/quotes");
  } else if (to.meta.requiresAdmin && !store.getters["auth/isAdmin"]) {
    next("/notfound");
  } else {
    if (to.fullPath.name == "signin" || to.fullPath.includes("auth") ) {
      store.dispatch("ui/initAllUI");
      store.dispatch("ui/updateRedirectFullpath", null);
      console.log("From Main Router with Auth", to.fullPath);
    } else {
      store.dispatch("ui/updateRedirectFullpath", to.fullPath);
      console.log("From Main Router", to.fullPath);
    }
    next();
  }
});

export default router;
