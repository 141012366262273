export default {
  SET_TEST_POINTS(state, payload) {
    state.test_points = payload;
  },
  SET_GUIDE_PINS(state, payload) {
    state.guide_pins = payload;
  },
  SET_PRESSURE_PINS(state, payload) {
    state.pressure_pins = payload;
  },
  SET_TPCB_CONFIG(state, payload) {
    state.tpcb_config = payload;
  },
  UPDATE_TPCB_CONFIG(state, payload) {
    state.tpcb_config = payload;
  },
  SET_CONNECTORS(state, payload) {
    state.connectors.push(...payload);
  },
  SET_CONNECTOR_BLOCKS(state, payload) {
    state.connector_blocks.push(...payload);
  },
  UPDATE_CONNECTOR_BLOCKS(state, payload) {
    state.connector_blocks.push(payload);
  },
  PATCH_CONNECTOR_BLOCK(state, payload) {
    const record = state.connector_blocks.find(
      (element) => element.pk == payload.pk
    );
    if (record) {
        Object.assign(record, payload);
    }
  },
  DELETE_CONNECTOR_BLOCK(state, id) {
  const record = state.connector_blocks.find((element) => element.pk == id);
    if (record) {
      state.connector_blocks.splice(state.test_points.indexOf(record), 1);
    }
  },
  UPDATE_PIN_BLOCK(state, payload) {
    const record = state.connector_blocks.find(
      (element) => element.pk == payload.connector_block
    );
    if (record) {
      const pinRecord = record.pin_blocks.find(
        (element) => element.pk == payload.pk
      );
      if (pinRecord) {
        Object.assign(pinRecord, payload);
      }
    }
  },
  INIT_CONNECTOR_BLOCKS(state) {
    state.connector_blocks.splice(0);
  },
  INIT_CONNECTORS(state) {
    state.connectors.splice(0);
  },
  RESET(state) {
    state.test_points.splice(0);
    state.guide_pins.splice(0);
    state.pressure_pins.splice(0);
    state.tpcb_config = {},
    state.connectors.splice(0);
    state.connector_blocks.splice(0);
  },
};