import jwtInterceptor from "@/helpers/interceptor";

export default {
  getProjects(params) {
    return jwtInterceptor.get(process.env.VUE_APP_PROJECTS_URL, params);
  },
  getProjectById(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_PROJECTS_URL + id}/`);
  },
  getProjectsByParams(params) {
    return jwtInterceptor.get(process.env.VUE_APP_PROJECTS_URL, params);
  },
  saveProject(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_PROJECTS_URL, payload);
  },
  updateProject({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_PROJECTS_URL + id}/`, data);
  },
  deleteProjectById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_PROJECTS_URL}${id}/`);
  },
  getFixturesConfigs() {
    return jwtInterceptor.get(process.env.VUE_APP_FIXTURES_CONFIGS_URL);
  },
  getConnectionTypes() {
    return jwtInterceptor.get(process.env.VUE_APP_CONNECTION_TYPES_URL);
  },
  getTipStyles() {
    return jwtInterceptor.get(process.env.VUE_APP_TIP_STYLES_URL);
  },
  getUsers(params) {
    return jwtInterceptor.get(process.env.VUE_APP_USERS_URL, params);
  },
  getUserById(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_USERS_URL + id}/`);
  },
  getCompanyById(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_COMPANIES_URL + id}/`);
  },
  updateUserById({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_USERS_URL + id}/`, data);
  },
  updateCompanyById({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_COMPANIES_URL + id}/`, data);
  },
  getCompanies() {
    return jwtInterceptor.get(process.env.VUE_APP_COMPANIES_URL);
  },
  postCompany(data) {
    return jwtInterceptor.post(process.env.VUE_APP_COMPANIES_URL, data);
  },
  deleteCompanyById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_COMPANIES_URL}${id}/`);
  },
  searchCompanies(payload) {
    return jwtInterceptor.get(process.env.VUE_APP_COMPANIES_URL, payload);
  },
  searchUsers(payload) {
    return jwtInterceptor.get(process.env.VUE_APP_USERS_URL, payload);
  },
  patchCompanyToUser({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_USERS_URL + id}/`, data);
  },
}
