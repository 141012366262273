<template>
  <v-app>
    <base-auth-layout v-if="isAuthenticated && !isErrorPage" />
    <v-main :style="!isAuthenticated || isErrorPage ? [bgStyle] : [bgNone]">
      <router-view />
    </v-main>
    <base-snack-announcement
      :snackBanner="!!bannerMessage"
      :bannerMessage="bannerMessage"
      @closeBanner="closeBanner"
    />
    <base-loader-dialog :isLoading="loading" />
    <base-progress-circular
      :isLoading="circularLoading"
      :value="circularValue"
    />
    <base-input-warning-dialog :snackbar="snackBar" @snackOff="snackEmit" />
    <base-input-warning-with-link
      :snackLink="snackLink"
      @snackLinkOff="snackLinkEmit"
    />
  </v-app>
</template>

<script>
import backgroundUrl from "./assets/cover_pcb.jpg";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "App",
  metaInfo: {
    title: "Fixturfab Design Platform",
    titleTemplate: "%s | FixturFab",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8 " },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
    ],
  },
  data() {
    return {
      backgroundUrl,
      bgStyle: {
        backgroundImage: `linear-gradient(to top, rgba(0, 47, 54, 0), rgba(0, 47, 54, 100)),url(${backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
      bgNone: {},
    };
  },
  computed: {
    ...mapGetters({
      quoteUser: "projects/projectUser",
      isAuthenticated: "auth/isAuthenticated",
      loading: "ui/loading",
      snackBar: "ui/snackBar",
      snackLink: "ui/snackLink",
      isErrorPage: "ui/errorPage",
      circularLoading: "ui/circularLoading",
      circularValue: "ui/circularValue",
      bannerMessage: "ui/bannerMessage",
    }),
  },
  methods: {
    ...mapMutations({
      mutateSnackBar: "ui/SNACK_BAR",
      mutateSnackLink: "ui/SNACK_LINK",
    }),
    snackEmit() {
      this.mutateSnackBar(null);
    },
    snackLinkEmit() {
      this.mutateSnackLink({ message: null, link: null });
    },
    closeBanner() {
      this.$store.dispatch("ui/bannerMessage", null);
    },
  },
  mounted() {
    this.isAuthenticated && this.$store.dispatch("ui/getBannerMessage");
  },
};
</script>
<style>
* {
  text-transform: none !important;
}
</style>
