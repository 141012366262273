<template>
  <v-bottom-sheet persistent v-model="dialog" @click:outside="unconfirmed">
    <v-sheet height="200px">
      <v-row>
        <v-col class="d-flex justify-center">
          <v-sheet width="auto">
            <div class="mt-10 text-start" data-app data-test="message">
              {{ message }}
            </div>
            <div
              v-if="subtitle_1"
              class="subtitle-2 text-start"
              data-app
              data-test="subtitle_1"
            >
              - {{ subtitle_1 }}
            </div>
            <div
              v-if="subtitle_2"
              class="subtitle-2 text-start"
              data-app
              data-test="subtitle_2"
            >
              - {{ subtitle_2 }}
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="py-3 mt-5"
      >
        <v-btn
          class="mx-5"
          color="warning"
          dark
          @click="unconfirmed"
          data-test="cancelBtn"
          >Cancel</v-btn
        >
        <v-btn
          class="mx-5"
          color="primary"
          dark
          @click="confirmed"
          data-test="okBtn"
          :data-cy="`bottom-dialog-ok-button${selected}`"
          >OK</v-btn
        >
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "BottomDialog",
  props: {
    message: String,
    dialog: Boolean,
    action: String,
    formError: {
      type: Boolean,
      default: false,
    },
    subtitle_1: {
      type: String,
      default: null,
    },
    subtitle_2: {
      type: String,
      default: null,
    },
    selected: {
      type: String,
      default: '',
    },
  },
  methods: {
    confirmed() {
      this.$emit(this.action, this.formError);
    },
    unconfirmed() {
      this.$emit("donothing");
    },
  },
};
</script>
