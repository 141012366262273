<template>
  <v-dialog v-model="isLoading.status" persistent width="400">
    <v-card color="primary" dark>
      <v-card-title class="caption mb-5" data-test="message">
        {{ isLoading.message }}...
        
      </v-card-title>
      <v-card-subtitle align="center">
        <div class="progress-container my-1" data-test="progress">
          <div class="progress-bar">
            <div class="progress-bar-value"></div>
          </div>
        </div>
      </v-card-subtitle>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LoaderDialog",
  props: {
    isLoading: Object,
  }
};
</script>
