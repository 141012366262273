export default {
  fixtureDesign(state) {
    return state.fixture_design;
  },
  fixtureConfig(state) {
    return state.fixture_design.fixture_config;
  },
  connectionType(state) {
    return state.fixture_design.receptacle_connection_preference;
  },
  tipStyle(state) {
    return state.fixture_design.tip_style_preference;
  },
  /* invoice(state) {
    return state.invoice;
  }, */
  /* customers(state) {
    return state.customers.sort((a, b) => (a.email > b.email ? 1 : -1));
  }, */
  fdState(state) {
    return state.fd_state;
  },
  irState(state) {
    return state.ir_state;
  },
  faState(state) {
    return state.fa_state;
  },
  designFileOptions(state) {
    return state.design_file_options;
  },
  designFilePackages(state) {
    return state.fixture_design.design_file_packages;
  },
};
