import QuoteService from "./endpoints";
import DutConfigService from "@/modules/dutconfigs/store/endpoints";
export default {
  async getQuotes(context) {
    context.dispatch("ui/loading", "Loading Quotes", { root: true });
    try {
      context.commit("INIT_QUOTES_LIST");
      const init_response = await QuoteService.getQuotes({
        params: { page: 1 },
      });
      context.commit("SET_QUOTES", init_response.data.results);
      context.dispatch("ui/loading", null, { root: true });
      if (init_response.data.count > 20) {
        const looper = Math.ceil(init_response.data.count / 20);
        context.dispatch("ui/backgroundLoading", true, { root: true });
        for (let i = 2; i <= looper; i++) {
          const response = await QuoteService.getQuotes({
            params: { page: i },
          });
          context.commit("SET_QUOTES", response.data.results);
        }
        context.dispatch("ui/backgroundLoading", false, { root: true });
      }
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getQuotesByUser(context, params) {
    context.dispatch("ui/loading", "Loading Quotes", { root: true });
    try {
      context.commit("INIT_QUOTES_LIST");
      const init_response = await QuoteService.getQuotesByUser(params);
      context.commit("SET_QUOTES", init_response.data.results);
      context.dispatch("ui/loading", null, { root: true });
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        context.dispatch("ui/backgroundLoading", true, { root: true });
        for (let i = 2; i <= forCycle; i++) {
          const response = await QuoteService.getQuotesByUser({ params: Object.assign(params.params, { page: i })});
          context.commit("SET_QUOTES", response.data.results);
        }
        context.dispatch("ui/backgroundLoading", false, { root: true });
      }
      return init_response;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async searchQuotes(context, params) {
    context.dispatch("ui/loading", "Searching Quotes", { root: true });
    try {
      context.commit("INIT_QUOTES_LIST");
      const init_response = await QuoteService.getQuotesByUser(params);
      context.commit("SET_QUOTES", init_response.data.results);
      context.dispatch("ui/loading", null, { root: true });
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        context.dispatch("ui/backgroundLoading", true, { root: true });
        for (let i = 2; i <= forCycle; i++) {
          const response = await QuoteService.getQuotesByUser({ params: Object.assign(params.params, { page: i })});
          context.commit("SET_QUOTES", response.data.results);
        }
        context.dispatch("ui/backgroundLoading", false, { root: true });
      }
      return init_response.data.results;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getQuote(context, payload) {
    try {
      const response = await QuoteService.getQuoteById(payload);
      console.log("Quote By ID", response);
      context.commit("SET_QUOTE", response.data);
      context.commit("SET_SORTED_DUT", response.data);
      context.dispatch("ui/updateQuoteTabs", response.data, { root: true });
      context.dispatch("ui/updateQuoteFromAPI", response.data, { root: true });
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getQuoteOnly(context, payload) {
    try {
      const response = await QuoteService.getQuoteById(payload);
      console.log("GET Quote Only", response);
      context.commit("SET_QUOTE", response.data);
      context.commit("SET_SORTED_DUT", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveQuote(context, { editMode, id, data }) {
    try {
      if (editMode) {
        const response = await QuoteService.updateQuote({ id, data });
        context.commit("SET_QUOTE", response.data);
        context.dispatch("ui/updateQuoteTabs", response.data, { root: true });
        return response.data;
      } else {
        const response = await QuoteService.saveQuote(data);
        context.commit("SET_QUOTE", response.data);
        context.dispatch("ui/updateQuoteTabs", response.data, { root: true });
        context.dispatch("ui/updateQuoteFromAPI", response.data, {
          root: true,
        });
        return response.data;
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async deleteQuote(context, payload) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await QuoteService.deleteQuoteById(payload);
      context.commit("DELETE_QUOTE", payload);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getQuoteStatus(context) {
    try {
      const response = await QuoteService.getQuoteStatus();
      context.commit("SET_QUOTE_STATUS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetailsOptions(context) {
    try {
      const response = await QuoteService.getDutDetailsOptions();
      context.commit("SET_DUT_DETAILS_OPTIONS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getQuoteFieldDescriptions(context) {
    try {
      const response = await QuoteService.getQuoteFieldDescriptions();
      context.commit("SET_FIELD_DESCRIPTIONS", response.data.results[0]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutFieldDescriptions(context) {
    try {
      const response = await QuoteService.getDutFieldDescriptions();
      context.commit("SET_DUT_FIELD_DESCRIPTIONS", response.data.results[0]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetails(context, payload) {
    try {
      const response = await QuoteService.getDutDetails(payload);
      console.log("Update DUT Detail:", response);
      if (response.data.results.length > 0) {
        context.commit("INIT_GERBER_LAYER_FILES");
        context.commit(
          "SET_DUT_DETAILS",
          response.data.results.sort((a, b) => (a.id > b.id ? 1 : -1))
        );
        context.dispatch("ui/updateQuoteTabsDut", response.data.results, {
          root: true,
        });
        if (response.data.results.length > 0) {
          context.dispatch(
            "ui/updateQuoteFixtureDetails",
            { key: "dut_name_1", value: response.data.results[0].dut_name },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "dut_name_0", value: response.data.results[0].dut_name },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "pcb_thickness_0",
              value: response.data.results[0].pcb_thickness,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_width_0", value: response.data.results[0].pcb_width },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_height_0", value: response.data.results[0].pcb_height },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "flex_board_0", value: response.data.results[0].flex_board },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "dual_sided_probing_0",
              value: response.data.results[0].dual_sided_probing,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "additional_comments_0",
              value: response.data.results[0].additional_comments,
            },
            { root: true }
          );
        }
        if (response.data.results.length > 1) {
          context.dispatch(
            "ui/updateQuoteFixtureDetails",
            { key: "dut_name_2", value: response.data.results[1].dut_name },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "dut_name_1", value: response.data.results[1].dut_name },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "pcb_thickness_1",
              value: response.data.results[1].pcb_thickness,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_width_1", value: response.data.results[1].pcb_width },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_height_1", value: response.data.results[1].pcb_height },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "flex_board_1", value: response.data.results[1].flex_board },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "dual_sided_probing_1",
              value: response.data.results[1].dual_sided_probing,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "additional_comments_1",
              value: response.data.results[1].additional_comments,
            },
            { root: true }
          );
        } else {
          context.commit("ADD_DUT_DETAILS", { dut_name: null });
        }
      }
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetailsView(context, payload) {
    try {
      const response = await QuoteService.getDutDetails(payload);
      console.log("DUT Details View", response);
      context.commit(
        "SET_DUT_DETAILS_VIEW",
        response.data.results.sort((a, b) => (a.id > b.id ? 1 : -1))
      );
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveDutDetail(context, { editMode, id, data }) {
    try {
      let response;
      if (editMode) {
        response = await QuoteService.updateDutDetail({ id, data });
        console.log("PATCH DUT Detail:", response);
      } else {
        response = await QuoteService.saveDutDetail(data);
        console.log("POST DUT Detail:", response);
      }
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async deleteDutDetail(context, { index, id }) {
    try {
      await QuoteService.deleteDutDetail(id);
      context.commit("DELETE_DUT_DETAIL", index);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutConfigsByQuote(context, id) {
    try {
      const response = await DutConfigService.getDutConfigsById(id);
      console.log(response.data);
      context.commit("SET_GERBER_FILES", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutConfigsById(context, id) {
    try {
      const response = await DutConfigService.getDutConfigsById(id);
      console.log(response.data);
      context.commit("SET_DUT_CONFIGS", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async removeAllGerberFile(context, payload) {
    payload.forEach(async (file, index) => {
      try {
        await DutConfigService.deleteGerberById(file.pk);
        if (payload.length - 1 === index) {
          context.commit("INIT_GERBER_FILES");
        }
      } catch (err) {
        context.dispatch("errorHandler", err);
      }
    });
  },
  initQuoteAndDut(context) {
    context.commit("INIT_QUOTE_AND_DUT");
  },
  async getQuotesComponents(context) {
    try {
      if (context.rootGetters["auth/isAdmin"]) {
        await Promise.all([
          context.dispatch("projects/fetchUsers", null, { root: true }),
          context.dispatch("projects/fetchCompanies", null, { root: true }),
        ]);
      } else {
        const response = await context.dispatch(
          "projects/fetchUserById",
          context.rootGetters["auth/user"].pk,
          { root: true }
        );
        response.data.groups.length > 0 &&
          (await context.dispatch(
            "projects/fetchCompanyById",
            response.data.groups[0]?.id,
            { root: true }
          ));
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async parseTestPointFile(context, payload) {
    try {
      const response = await DutConfigService.parseTestPointFile(payload);
      console.log("Parsed TP: ", response);
      context.commit("SET_PARSED_FILE", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateDutConfigs(context, payload) {
    try {
      const response = await DutConfigService.patchDutConfigs(payload);
      console.log("updateDutConfigs from Quote", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveQuoteAddress(context, { editMode, id, data, isBilling }) {
    try {
      let response;
      if (editMode) {
        response = await QuoteService.patchQuoteAddress({ id, data });
      } else {
        response = await QuoteService.postQuoteAddress({ data });
      }
      console.log(response.data);
      if (isBilling) {
        context.commit("SET_BILLING_ADDRESS", response.data);
      } else {
        context.commit("SET_SHIPPING_ADDRESS", response.data);
      }
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getQuoteAddress(context, { id, isBilling }) {
    context.dispatch("ui/loading", "Fetching Billing Address", { root: true });
    try {
      const response = await QuoteService.getQuoteAddress(id);
      if (isBilling) {
        context.commit("SET_BILLING_ADDRESS", response.data);
      } else {
        context.commit("SET_SHIPPING_ADDRESS", response.data);
      }
      context.dispatch("ui/loading", null, { root: true });
      return response;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getQuoteAddresses(context) {
    context.dispatch("ui/loading", "Fetching Quote Addresses", { root: true });
    try {
      context.commit("INIT_ADDRESSES");
      const init_response = await QuoteService.getQuoteAddresses();
      context.commit("SET_ADDRESSES", init_response.data.results);
      context.dispatch("ui/loading", null, { root: true });
      return init_response;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async deleteQuoteAddress(context, id) {
    context.dispatch("ui/loading", "Removing Address", { root: true });
    try {
      await QuoteService.deleteQuoteAddress(id);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async postGerberLayerFile(context, payload) {
    try {
      const response = await QuoteService.postGerberLayerFile(payload);
      console.log("POST Gerber Layer File", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async removeGerberLayerFile(context, { dutDetailsId, index = 0 }) {
    try {
      const response = await QuoteService.deleteGerberLayerFile(dutDetailsId);
      context.commit("INIT_GERBER_FILES_BY_DUT", index);
      console.log("Bulk Delete Gerber Layer File", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  errorHandler(context, err) {
    let error = new Error(
      "Failed to process request (Quotes). Please try again later."
    );
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key]);
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
