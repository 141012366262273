export default {
  toggleAll(state, payload) {
    state.test_pop = payload;
    state.step_pop = payload;
  },
  MINI_VARIANT(state) {
    state.miniVariant = !state.miniVariant;
  },
  LOADING(state, message) {
    state.loading.status = message ? true : false;
    state.loading.message = message;
  },
  SNACK_BAR(state, message) {
    state.snackBar.status = message ? true : false;
    state.snackBar.message = message;
  },
  SNACK_LINK(state, { message1, message2, link }) {
    state.snackLink.status = !state.snackLink.status;
    state.snackLink.message1 = message1;
    state.snackLink.message2 = message2;
    state.snackLink.link = link;
  },
  ERROR_PAGE(state) {
    state.error_page = !state.error_page;
  },
  CIRCULAR_LOADING(state) {
    state.circular_loading = !state.circular_loading;
  },
  CIRCULAR_VALUE(state, value) {
    state.circular_value = value;
  },
  RENDER_EVENT(state) {
    state.render_event = !state.render_event;
  },
  NEW_UPDATE(state, value) {
    state.new_update = value;
    state.new_invoice = value;
  },
  PROJECT_SEARCH(state, { type, value }) {
    state.project_search.type = type;
    state.project_search.value = value;
  },
  DUT_CONFIG_ID(state, value) {
    state.dutconfig_id = value;
  },
  TOGGLE_UPDATE(state) {
    state.new_update = false;
  },
  TOGGLE_INVOICE(state) {
    state.new_invoice = false;
  },
  PROJECT_SEARCH_STRING(state, value) {
    state.project_search_string = value;
  },
  UPDATE_QUOTE_USER(state, { key, value }) {
    state.user_and_company[key] = value;
  },
  UPDATE_QUOTE_TABS(state, payload) {
    state.quote_tabs[2].subtitle = payload.project_name;
    state.quote_tabs.push({ title: "DUT Details" });
    if (payload.number_of_unique_duts > 1) {
      state.quote_tabs.push({ title: "DUT Details" });
    }
  },
  UPDATE_QUOTE_TABS_DUT(state, payload) {
    state.quote_tabs[3].subtitle = payload[0].dut_name;
    if (state.quote_tabs.length > 4 && payload.length > 1) {
      state.quote_tabs[4].subtitle = payload[1].dut_name;
    }
  },
  UPDATE_QUOTE_FIXTURE_DETAILS(state, { key, value }) {
    state.fixture_details[key] = value;
  },
  UPDATE_QUOTE_FROM_API(state, payload) {
     Object.assign(state.fixture_details, payload);
  },
  UPDATE_QUOTE_DUT_DETAILS(state, { key, value }) {
    state.dut_ui_details[key] = value;
  },
  UPDATE_BILLING_ADDRESS(state, { key, value }) {
    state.billing_address[key] = value;
  },
  UPDATE_SHIPPING_ADDRESS(state, { key, value }) {
    state.shipping_address[key] = value;
  },
  UPDATE_SHIPPING_SAME(state, payload) {
    state.shipping_same_as_billing = payload;
  },
  INIT_QUOTE_TABS(state) {
    state.quote_tabs= [
      {
        title: "User and Company",
        subtitle: "."
      },
      {
        title: "Quote Setup",
        subtitle: "."
      },
      {
        title: "Fixture Details",
        subtitle: "."
      },
    ]
  },
  INIT_UI_DETAILS(state) {
    state.fixture_details = {
      project_name: null,
      number_of_unique_duts: null,
      dut_name_1: null,
      dut_name_2: null,
      project_timeline: null,
      project_timeline_details: null,
      estimated_volumes: null,
      project_type: null,
      fixture_wiring: null,
      test_instrumentation_selection_and_integration: false,
      test_software_development: false,
      custom_back_plate: false,
      number_of_fixtures: null,
      panelized_fixture: false,
      duts_in_panel: null,
      duts_per_row: null,
      duts_per_column: null,
      panel_details: null,
      additional_services_details: "",
    };
    state.dut_ui_details = {
      dut_name_0: null,
      pcb_width_0: null,
      pcb_height_0: null,
      pcb_thickness_0: null,
      flex_board_0: false,
      dual_sided_probing_0: false,
      test_point_file_0: null,
      step_file_0: null,
      gerber_zip_0: null,
      zipped_gerbers_0: null,
      odb_zip_0: null,
      ipc_zip_0: null,
      schematic_file_0: null,
      other_file_0: null,
      additional_comments_0: "",
      dut_name_1: null,
      pcb_width_1: null,
      pcb_height_1: null,
      pcb_thickness_1: null,
      flex_board_1: false,
      dual_sided_probing_1: false,
      test_point_file_1: null,
      step_file_1: null,
      gerber_zip_1: null,
      zipped_gerbers_1: null,
      odb_zip_1: null,
      ipc_zip_1: null,
      schematic_file_1: null,
      other_file_1: null,
      additional_comments_1: "",
    };
  },
  INIT_PROJECT_TABS(state) {
    state.project_tabs= [
      {
        title: "User and Company",
        subtitle: "."
      },
      {
        title: "Project Setup",
        subtitle: "."
      }
    ]
  },
  UPDATE_PROJECT_TABS(state, payload) {
    state.project_tabs[1].subtitle = payload.project_name;
    for (let i = 0; i < Object.keys(payload.dut_config_details).length; i++) {
      state.project_tabs.push({ title: `DUT Configuration ${i + 1}`, subtitle: Object.values(payload.dut_config_details)[i].name });
    }
  },
  UPDATE_PROJECT_DETAILS(state, { key, value }) {
    state.project_details[key] = value;
  },
  INIT_ALL_UI(state) {
    state.test_pop = true;
    state.step_pop = true;
    state.miniVariant = false;
    state.loading = {
      status: false,
      message: null
    };
    state.snackBar = {
      status: false,
      message: null,
    };
    state.snackLink = {
      status: false,
      message1: null,
      message2: null,
      link: null,
    };
    state.error_page = false;
    state.circular_loading = false;
    state.circular_value = 0;
    state.render_event = false;
    state.new_update = true;
    state.new_invoice = true;
    state.project_search = {
      type: null,
      value: null,
    };
    state.user_and_company = {
      full_name: "",
      company_request: "",
      billing_address_request: "",
      shipping_same_as_billing_request: null,
      shipping_address_request: "",
    };
    state.fixture_details = {
      project_name: null,
      number_of_unique_duts: null,
      dut_name_1: null,
      dut_name_2: null,
      project_timeline: null,
      project_timeline_details: null,
      estimated_volumes: null,
      project_type: null,
      fixture_wiring: null,
      test_instrumentation_selection_and_integration: false,
      test_software_development: false,
      custom_back_plate: false,
      number_of_fixtures: null,
      panelized_fixture: false,
      duts_in_panel: null,
      duts_per_row: null,
      duts_per_column: null,
      panel_details: null,
      additional_services_details: "",
    };
    state.dut_ui_details = {
      dut_name_0: null,
      pcb_width_0: null,
      pcb_height_0: null,
      pcb_thickness_0: null,
      flex_board_0: false,
      dual_sided_probing_0: false,
      test_point_file_0: null,
      step_file_0: null,
      gerber_zip_0: null,
      zipped_gerbers_0: null,
      odb_zip_0: null,
      ipc_zip_0: null,
      schematic_file_0: null,
      other_file_0: null,
      additional_comments_0: "",
      dut_name_1: null,
      pcb_width_1: null,
      pcb_height_1: null,
      pcb_thickness_1: null,
      flex_board_1: false,
      dual_sided_probing_1: false,
      test_point_file_1: null,
      step_file_1: null,
      gerber_zip_1: null,
      zipped_gerbers_1: null,
      odb_zip_1: null,
      ipc_zip_1: null,
      schematic_file_1: null,
      other_file_1: null,
      additional_comments_1: "",
    };
    state.quote_tabs = [
      {
        title: "User and Company",
        subtitle: "."
      },
      {
        title: "Quote Setup",
        subtitle: "."
      },
      {
        title: "Fixture Details",
        subtitle: "."
      },
    ];
    state.project_tabs = [
      {
        title: "User and Company",
        subtitle: "."
      },
      {
        title: "Project Setup",
        subtitle: "."
      }
    ];
    state.project_details = {
      project_name: null,
      number_of_duts: null,
      dut_name_1: null,
      dut_name_2: null,
    };
    state.redirect_fullpath = null;
  },
  UPDATE_REDIRECT_FULLPATH(state, payload) {
    state.redirect_fullpath = payload
  },
  RESET(state) {
    state.test_pop = true;
    state.step_pop = true;
    state.miniVariant = false;
    state.loading = {
      status: false,
      message: null
    };
    state.snackBar = {
      status: false,
      message: null,
    };
    state.snackLink = {
      status: false,
      message1: null,
      message2: null,
      link: null,
    };
    state.error_page = false;
    state.circular_loading = false;
    state.circular_value = 0;
    state.render_event = false;
    state.new_update = true;
    state.new_invoice = true;
    state.project_search = {
      type: null,
      value: null,
    };
    state.utconfig_id = null;
    state.fd_state_longlist = [
      {
        pk: 1,
        description: "Any updates to this fixture design will be reflected below.",
      },
      { pk: 2,
        description: "This design is now Read Only. Any updates made by FixturFab during the internal review will be reflected below.",
      },
      {
        pk: 3,
        description: "This design has been finalized and will soon be fabricated and assembled.",
      },
      {
        pk: 4,
        description: "The assembled fixture has been shipped!",
      }
    ];
    state.project_search_string = '';
    state.user_and_company = {
      full_name: "",
      company_request: "",
      billing_address_request: "",
      shipping_same_as_billing_request: null,
      shipping_address_request: "",
    };
    state.fixture_details = {
      project_name: null,
      number_of_unique_duts: null,
      dut_name_1: null,
      dut_name_2: null,
      project_timeline: null,
      project_timeline_details: null,
      estimated_volumes: null,
      project_type: null,
      fixture_wiring: null,
      test_instrumentation_selection_and_integration: false,
      test_software_development: false,
      custom_back_plate: false,
      number_of_fixtures: null,
      panelized_fixture: false,
      duts_in_panel: null,
      duts_per_row: null,
      duts_per_column: null,
      panel_details: null,
      additional_services_details: "",
    };
    state.dut_ui_details = {
      dut_name_0: null,
      pcb_width_0: null,
      pcb_height_0: null,
      pcb_thickness_0: null,
      flex_board_0: false,
      dual_sided_probing_0: false,
      test_point_file_0: null,
      step_file_0: null,
      gerber_zip_0: null,
      zipped_gerbers_0: null,
      odb_zip_0: null,
      ipc_zip_0: null,
      schematic_file_0: null,
      other_file_0: null,
      additional_comments_0: "",
      dut_name_1: null,
      pcb_width_1: null,
      pcb_height_1: null,
      pcb_thickness_1: null,
      flex_board_1: false,
      dual_sided_probing_1: false,
      test_point_file_1: null,
      step_file_1: null,
      gerber_zip_1: null,
      zipped_gerbers_1: null,
      odb_zip_1: null,
      ipc_zip_1: null,
      schematic_file_1: null,
      other_file_1: null,
      additional_comments_1: "",
    };
    state.quote_tabs = [
      {
        title: "User and Company",
        subtitle: "."
      },
      {
        title: "Quote Setup",
        subtitle: "."
      },
      {
        title: "Fixture Details",
        subtitle: "."
      },
    ];
    state.project_tabs = [
      {
        title: "User and Company",
        subtitle: "."
      },
      {
        title: "Project Setup",
        subtitle: "."
      }
    ];
    state.project_details = {
      project_name: null,
      number_of_duts: null,
      dut_name_1: null,
      dut_name_2: null,
    };
    state.redirect_fullpath = null;
  },
  BACKGROUND_LOADING(state, payload) {
    state.background_loading = payload;
  },
  SET_HOVER_TEXT(state, payload) {
    state.hover_text = payload;
  },
  SET_BANNER_MESSAGE(state, payload) {
    state.banner_message = payload;
  },
};