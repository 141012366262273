import ProjectService from "./endpoints";
import DutConfigService from "@/modules/dutconfigs/store/endpoints";
import QuoteService from "@/modules/quotes/store/endpoints";
export default {
  async getProjects(context, params) {
    context.dispatch("ui/loading", "Fetching Projects", { root: true });
    try {
      const response = await ProjectService.getProjects(params);
      context.commit("SET_PROJECTS", response.data.results);
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async searchProjects(context, params) {
    context.dispatch("ui/loading", "Searching Projects", { root: true });
    try {
      const response = await ProjectService.getProjectsByParams(params);
      context.commit("SET_PROJECTS", response.data.results);
      context.dispatch("ui/loading", null, { root: true });
      return response.data.results;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getMultipleProjects(context, params) {
    try {
      const response = await ProjectService.getProjects(params);
      context.commit("ADD_PROJECTS", response.data.results);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async fetchProject(context, payload) {
    try {
      const response = await ProjectService.getProjectById(payload);
      context.commit("SET_PROJECT", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async fetchProjectFlow(context, payload) {
    try {
      const response = await ProjectService.getProjectById(payload);
      context.commit("SET_PROJECT", response.data);
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "project_name",
          value: response.data.project_name,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "number_of_duts",
          value: Object.keys(response.data.dut_config_details).length,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "dut_name_1",
          value:
            Object.keys(response.data.dut_config_details).length > 0
              ? Object.values(response.data.dut_config_details)[0].name
              : null,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "dut_name_2",
          value:
            Object.keys(response.data.dut_config_details).length > 1
              ? Object.values(response.data.dut_config_details)[1].name
              : null,
        },
        { root: true }
      );
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async saveProject(context, payload) {
    context.dispatch("ui/loading", "Creating project", { root: true });
    try {
      const response = await ProjectService.saveProject(payload);
      context.commit("SET_PROJECT", response.data);
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async updateProject(context, payload) {
    context.dispatch("ui/loading", "Updating Project", { root: true });
    try {
      const response = await ProjectService.updateProject(payload);
      context.commit("SET_PROJECT", response.data);
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "project_name",
          value: response.data.project_name,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "number_of_duts",
          value: Object.keys(response.data.dut_config_details).length,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "dut_name_1",
          value:
            Object.keys(response.data.dut_config_details).length > 0
              ? Object.values(response.data.dut_config_details)[0].name
              : null,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateProjectDetails",
        {
          key: "dut_name_2",
          value:
            Object.keys(response.data.dut_config_details).length > 1
              ? Object.values(response.data.dut_config_details)[1].name
              : null,
        },
        { root: true }
      );
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async deleteProject(context, payload) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await ProjectService.deleteProjectById(payload);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getFixturesConfigs(context) {
    try {
      context.commit("INIT_FIXTURES_CONFIGS");
      const init_response = await ProjectService.getFixturesConfigs({
        params: { page: 1 },
      });
      context.commit("SET_FIXTURES_CONFIGS", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await ProjectService.getFixturesConfigs({
            params: { page: i },
          });
          context.commit("SET_FIXTURES_CONFIGS", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getConnectionTypes(context) {
    try {
      context.commit("INIT_CONNECTION_TYPES");
      const init_response = await ProjectService.getConnectionTypes({
        params: { page: 1 },
      });
      context.commit("SET_CONNECTION_TYPES", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await ProjectService.getConnectionTypes({
            params: { page: i },
          });
          context.commit("SET_CONNECTION_TYPES", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getTipStyles(context) {
    try {
      context.commit("INIT_TIP_STYLES");
      const init_response = await ProjectService.getTipStyles({
        params: { page: 1 },
      });
      context.commit("SET_TIP_STYLES", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await ProjectService.getTipStyles({
            params: { page: i },
          });
          context.commit("SET_TIP_STYLES", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getProjectCreateComponents(context) {
    try {
      await Promise.all([
        context.dispatch("getFixturesConfigs"),
        context.dispatch("getConnectionTypes"),
        context.dispatch("getTipStyles"),
      ]);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  clearProjects(context) {
    context.commit("CLEAR_PROJECTS");
    context.dispatch("clearProject");
  },
  setProject(context, payload) {
    context.commit("SET_PROJECT", payload);
  },
  setGuidePin(context, payload) {
    context.commit("SET_GUIDE_PIN", payload);
  },
  setPressurePin(context, payload) {
    context.commit("SET_PRESSURE_PIN", payload);
  },
  clearProject(context) {
    context.commit("CLEAR_PROJECT");
  },
  clearSelection(context) {
    context.commit("CLEAR_SELECTION");
  },
  async fetchUsers(context) {
    try {
      context.commit("INIT_USERS_LIST");
      const init_response = await ProjectService.getUsers({
        params: { page_size: 200 },
      });
      context.commit("SET_USERS_LIST", init_response.data.results);
      if (init_response.data.count > 200) {
        const forCycle = Math.ceil(init_response.data.count / 200);
        for (let i = 2; i <= forCycle; i++) {
          const response = await ProjectService.getUsers({
            params: { page: i, page_size: 200 },
          });
          context.commit("SET_USERS_LIST", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async fetchUserById(context, id) {
    try {
      const response = await ProjectService.getUserById(id);
      console.log("Project User:", response);
      context.commit("SET_USER", response.data);
      context.dispatch(
        "ui/updateQuoteUser",
        { key: "full_name", value: response.data.full_name },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteUser",
        { key: "company_request", value: response.data.company_request },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteUser",
        {
          key: "billing_address_request",
          value: response.data.billing_address_request,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteUser",
        {
          key: "shipping_same_as_billing_request",
          value: response.data.shipping_same_as_billing_request,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteUser",
        {
          key: "shipping_address_request",
          value: response.data.shipping_address_request,
        },
        { root: true }
      );
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async fetchCompanies(context) {
    try {
      context.commit("INIT_COMPANY_LIST");
      const init_response = await ProjectService.getCompanies({
        params: { page: 1 },
      });
      context.commit("SET_COMPANY_LIST", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await ProjectService.getCompanies({
            params: { page: i },
          });
          context.commit("SET_COMPANY_LIST", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async fetchCompanyById(context, { id, editMode = false }) {
    try {
      const response = await ProjectService.getCompanyById(id);
      context.commit("SET_COMPANY", response.data);
      context.dispatch(
        "ui/updateQuoteUser",
        {
          key: "billing_address_request",
          value: editMode ? response.data.billing_address : null,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteUser",
        {
          key: "shipping_same_as_billing_request",
          value: editMode ? response.data.shipping_same_as_billing : null,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteUser",
        {
          key: "shipping_address_request",
          value: editMode ? response.data.shipping_address : null,
        },
        { root: true }
      );
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateCompanyById(context, { editMode, id, data }) {
    context.dispatch("ui/loading", "Updating company profile", { root: true });
    try {
      let response;
      if (editMode) {
        response = await ProjectService.updateCompanyById({ id, data });
        context.commit("SET_COMPANY", response.data);
        context.dispatch("ui/loading", null, { root: true });
        return response.data;
      } else {
        response = await ProjectService.postCompany(data);
        context.commit("ADD_TO_COMPANY_LIST", response.data);
        context.dispatch("ui/loading", null, { root: true });
      }
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async saveCompany(context, payload) {
    context.dispatch("ui/loading", "Creating company", { root: true });
    try {
      const response = await ProjectService.postCompany(payload);
      context.commit("SET_COMPANY", response.data);
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async deleteCompany(context, payload) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await ProjectService.deleteCompanyById(payload);
      await context.dispatch("fetchCompanies");
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async searchCompanies(context, payload) {
    try {
      console.log(payload);
      const response = await ProjectService.searchCompanies(payload);
      return response.data.results;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async searchUsers(context, payload) {
    try {
      console.log(payload);
      const response = await ProjectService.searchUsers(payload);
      return response.data.results;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async patchCompanyToUser(context, { id, value, data }) {
    context.dispatch("ui/loading", "Adding user to Company", { root: true });
    try {
      const response = await ProjectService.patchCompanyToUser({ id, data });
      context.commit("SET_USER", response.data);
      context.dispatch("fetchCompanyById", value);
      console.log("PATCH COMPANY TO USER", response);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async patchUserToCompany(context, { id, data }) {
    try {
      const response = await ProjectService.patchCompanyToUser({ id, data });
      context.dispatch("fetchCompanies");
      console.log("PATCH USER TO COMPANY", response);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateUserById(context, payload) {
    context.dispatch("ui/loading", "Saving", { root: true });
    try {
      const response = await ProjectService.updateUserById(payload);
      context.commit("SET_USER", response.data);
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  initCompanyById(context) {
    try {
      context.commit("INIT_COMPANY");
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  initUserCompany(context) {
    context.commit("INIT_USER_AND_COMPANY");
  },
  async getDutConfigsById(context, id) {
    try {
      const response = await DutConfigService.getDutConfigsById(id);
      console.log("Fetch DUT Configs from Template", response);
      context.commit("SET_DUT_CONFIGS", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async createDutConfigs(context, { payload }) {
    try {
      const response = await DutConfigService.createDutConfigs(payload);
      context.commit("SET_DUT_CONFIGS", response.data);
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async updateDutConfigs(context, payload) {
    try {
      const response = await DutConfigService.patchDutConfigs(payload);
      context.commit("SET_DUT_CONFIGS", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  initDutConfigs(context) {
    context.commit("INIT_DUT_CONFIGS");
  },
  async saveDutDetail(context, { editMode, id, data, index }) {
    try {
      let response;
      if (editMode) {
        response = await QuoteService.updateDutDetail({ id, data });
        console.log("DUT Detail:", response);
      } else {
        response = await QuoteService.saveDutDetail(data);
        console.log("POST DUT Detail via Dut Configs", response);
      }
      context.commit("SET_DUT_DETAILS", { payload: response.data, index });
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetailsById(context, { id, index }) {
    if (context.getters.dutDetails.length > 0 && index == 0) {
      context.dispatch("initDutDetails");
    }
    try {
      const response = await QuoteService.getDutDetailsById(id);
      context.commit("SET_DUT_DETAILS", { payload: response.data, index });
      context.dispatch(
        "ui/updateQuoteDutDetails",
        { key: `dut_name_${index}`, value: response.data.dut_name },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteDutDetails",
        {
          key: `pcb_thickness_${index}`,
          value: response.data.pcb_thickness,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteDutDetails",
        { key: `pcb_width_${index}`, value: response.data.pcb_width },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteDutDetails",
        { key: `pcb_height_${index}`, value: response.data.pcb_height },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteDutDetails",
        { key: `flex_board_${index}`, value: response.data.flex_board },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteDutDetails",
        {
          key: `dual_sided_probing_${index}`,
          value: response.data.dual_sided_probing,
        },
        { root: true }
      );
      context.dispatch(
        "ui/updateQuoteDutDetails",
        {
          key: `additional_comments_${index}`,
          value: response.data.additional_comments,
        },
        { root: true }
      );
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDutDetails(context, payload) {
    try {
      const response = await QuoteService.getDutDetails(payload);
      console.log("Update DUT Detail:", response);
      context.dispatch("initDutDetails");
      if (response.data.results.length > 0) {
        context.commit("SET_DUT_DETAILS", {
          payload: response.data.results[0],
          index: 0,
        });
        if (response.data.results.length > 0) {
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "dut_name_0", value: response.data.results[0].dut_name },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "pcb_thickness_0",
              value: response.data.results[0].pcb_thickness,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_width_0", value: response.data.results[0].pcb_width },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_height_0", value: response.data.results[0].pcb_height },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "flex_board_0", value: response.data.results[0].flex_board },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "dual_sided_probing_0",
              value: response.data.results[0].dual_sided_probing,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "additional_comments_0",
              value: response.data.results[0].additional_comments,
            },
            { root: true }
          );
        }
        if (response.data.results.length > 1) {
          context.commit("SET_DUT_DETAILS", {
            payload: response.data.results[1],
            index: 1,
          });
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "dut_name_1", value: response.data.results[1].dut_name },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "pcb_thickness_1",
              value: response.data.results[1].pcb_thickness,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_width_1", value: response.data.results[1].pcb_width },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "pcb_height_1", value: response.data.results[1].pcb_height },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            { key: "flex_board_1", value: response.data.results[1].flex_board },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "dual_sided_probing_1",
              value: response.data.results[1].dual_sided_probing,
            },
            { root: true }
          );
          context.dispatch(
            "ui/updateQuoteDutDetails",
            {
              key: "additional_comments_1",
              value: response.data.results[1].additional_comments,
            },
            { root: true }
          );
        }
      }
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  initDutDetails(context) {
    context.commit("INIT_DUT_DETAILS");
    context.commit("INIT_GERBER_LAYER_FILES");
  },
  async saveQuote(context, { editMode, id, data }) {
    try {
      let response;
      if (editMode) {
        response = await QuoteService.updateQuote({ id, data });
        context.commit("SET_QUOTE", response.data);
      } else {
        response = await QuoteService.saveQuote(data);
        context.commit("SET_QUOTE", response.data);
      }
      return response.data;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getQuote(context, payload) {
    try {
      const response = await QuoteService.getQuoteById(payload);
      console.log("Quote By ID", response);
      context.commit("SET_QUOTE", response.data);
      return response;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  initAllDuts(context) {
    context.commit("INIT_ALL_DUTS");
  },
  errorHandler(context, err) {
    let error = new Error(
      "Failed to process request (Projects). Please try again later."
    );
    if (err.response?.status !== undefined) {
      if (err.response?.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = err;
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
