export default {
  testPoints(state) {
    return state.test_points.sort((a, b) => (a.pk > b.pk ? 1 : -1));
  },
  editedTP(state) {
    return state.edited_tp;
  },
  currentTP(state) {
    return state.current_tp;
  },
  tpCount(state) {
    return state.count;
  },
};
