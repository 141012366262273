import Module from "./Module.vue"
import SignIn from "./views/SignIn.vue"
export const AuthRoutes = {
    path: "/auth",
    component: Module,
    children: [
      {
        path: "/",
        name: "signin",
        component: SignIn,
        props: true,
        meta: { requiresUnAuth: true },
      },
      {
        path: "/signup",
        name: "signup",
        component: () => import("./views/SignUp.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/signup-confirm",
        name: "signup-confirm",
        component: () => import("./views/SignUpConfirm.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/dj-rest-auth/registration/account-confirm-email/:token",
        redirect: { name: "signup-confirm" },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("./views/ResetPassword.vue"),
        meta: { requiresUnAuth: true },
      },
      {
        path: "/reset-confirm/:uuid/:token",
        name: "reset-confirm",
        component: () => import("./views/ResetConfirm.vue"),
        meta: { requiresUnAuth: true },
      },
    ],
  };