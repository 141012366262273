import FixtureDesignService from "./endpoints";

export default {
  async saveFixtureDesign(context, payload) {
    context.dispatch("ui/loading", "Creating fixture design", { root: true });
    try {
      const response = await FixtureDesignService.saveFixtureDesign(payload);
      console.log("saveFixtureDesign", response);
      context.dispatch("ui/loading", null, { root: true });
      return response;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async getFixtureDesign(context, params) {
    try {
      const response = await FixtureDesignService.getFixtureDesign(params);
      console.log("FIXTURE DESIGN", response.data.results[0]);
      context.commit("SET_FIXTURE_DESIGN", response.data.results[0]);
      return response.data.results[0];
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async searchFixtures(context, params) {
    context.dispatch("ui/loading", "Searching Fixture Design", { root: true });
    try {
      const response = await FixtureDesignService.getFixtureDesign(params);
      //console.log("FD Search Results:", response);
      context.dispatch("ui/loading", null, { root: true });
      return response.data;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async patchFixtureDesign(context, payload) {
    context.dispatch(
      "ui/loading",
      "Updating Fixture Design, one moment please",
      { root: true }
    );
    try {
      const response = await FixtureDesignService.patchFixtureDesign(payload);
      console.log("PATCH FD from FixtureDesign", response);
      context.commit("SET_FIXTURE_DESIGN", response.data);
      context.dispatch("ui/newUpdate", false, { root: true });
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async generateFixtureDesign(context, payload) {
    try {
      const response = await FixtureDesignService.genFixtureDesign(payload);
      return response.data.statusMessage;
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getFixtureDesignState(context) {
    try {
      const response = await FixtureDesignService.getFixtureDesignOptions();
      console.log("FD STATE", response);
      context.commit("SET_FIXTURE_DESIGN_STATE", response.data);
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDesignFileOptions(context) {
    try {
      const response = await FixtureDesignService.getDesignFileOptions();
      context.commit("SET_DESIGN_FILE_OPTIONS", Object.entries(response.data.actions.POST));
       //console.log("DFP Options", response, Object.entries(response.data.actions.POST));
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async getDesignFilePackage(context, params) {
    try {
      context.commit("INIT_DESIGN_FILE_PACKAGES");
      const init_response = await FixtureDesignService.getDesignFilePackage(params);
      context.commit("SET_DESIGN_FILE_PACKAGES", init_response.data.results);
      if (init_response.data.count > 20) {
        const forCycle = Math.ceil(init_response.data.count / 20);
        for (let i = 2; i <= forCycle; i++) {
          const response = await FixtureDesignService.getDesignFilePackage({ params: Object.assign(params.params, { page: i })});
          context.commit("SET_DESIGN_FILE_PACKAGES", response.data.results);
        }
      }
    } catch (err) {
      context.dispatch("errorHandler", err);
    }
  },
  async postDesignFilePackage(context, payload) {
    context.dispatch("ui/loading", "Creating Design File Package", { root: true });
    try {
      const response = await FixtureDesignService.postDesignFilePackage(payload);
       //console.log("POST DFP", response);
      context.dispatch("ui/loading", null, { root: true });
      return response;
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async patchDesignFilePackage(context, payload) {
    /* context.dispatch(
      "ui/loading",
      "Updating Design File Package, one moment please",
      { root: true }
    ); */
    try {
      const response = await FixtureDesignService.patchDesignFilePackage(payload);
       //console.log("PATCH DFP", response);
      context.commit("UPDATE_DESIGN_FILE_PACKAGE", response.data);
      /* context.dispatch("ui/loading", null, { root: true }); */
    } catch (err) {
      /* context.dispatch("ui/loading", null, { root: true }); */
      context.dispatch("errorHandler", err);
    }
  },
  async deleteDesignFilePackage(context, id) {
    context.dispatch("ui/loading", "Deleting", { root: true });
    try {
      await FixtureDesignService.deleteDesignFilePackage(id);
      context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  async newUpdate(context, { projectId, fdId, value, from }) {
    if (
      context.rootGetters["fixturedesign/fixtureDesign"].update_invoice &&
      context.rootGetters["fixturedesign/fixtureDesign"].update_render
    )
      return;
    console.log("CALLED FROM:", from);
    const updatePayload = {
      pk: fdId,
      data: {
        update_invoice: value,
        update_render: value,
      },
    };
    const params = {
      params: {
        project_id: projectId,
      },
    };
    try {
      await FixtureDesignService.patchFixtureDesign(updatePayload);
      await context.dispatch("getFixtureDesign", params);
      //context.dispatch("ui/loading", null, { root: true });
    } catch (err) {
      //context.dispatch("ui/loading", null, { root: true });
      context.dispatch("errorHandler", err);
    }
  },
  initFixtureDesignState(context) {
    context.commit("INIT_ALL_STATE");
  },
  errorHandler(context, err) {
    let error = new Error("Failed to process request. Please try again later.");
    if (err.response.status !== undefined) {
      if (err.response.status === 500) {
        error = new Error(
          "We encountered an internal issue, please email support@fixturfab.com."
        );
        throw error;
      } else {
        Object.keys(err.response.data).forEach((key) => {
          if (key !== undefined) {
            error = new Error(err.response.data[key] + "(" + key + ")");
            throw error;
          } else {
            throw error;
          }
        });
      }
    } else {
      throw error;
    }
  },
};
