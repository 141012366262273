import jwtInterceptor from "@/helpers/interceptor";

export default {
  getQuotes(params) {
    return jwtInterceptor.get(process.env.VUE_APP_QUOTES_URL, params);
  },
  getQuotesByUser(params) {
    return jwtInterceptor.get(process.env.VUE_APP_QUOTES_URL, params);
  },
  getQuoteById(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_QUOTES_URL + id}/`);
  },
  saveQuote(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_QUOTES_URL, payload);
  },
  updateQuote({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_QUOTES_URL + id}/`, data);
  },
  deleteQuoteById(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_QUOTES_URL + id}/`);
  },
  getQuoteStatus() {
    return jwtInterceptor.options(process.env.VUE_APP_QUOTES_URL);
  },
  getDutDetailsOptions() {
    return jwtInterceptor.options(process.env.VUE_APP_DUT_DETAILS_URL);
  },
  getQuoteFieldDescriptions() {
    return jwtInterceptor.get(process.env.VUE_APP_QUOTE_FIELDS_URL);
  },
  getDutFieldDescriptions() {
    return jwtInterceptor.get(process.env.VUE_APP_DUT_FIELDS_URL);
  },
  getDutDetails(params) {
    return jwtInterceptor.get(process.env.VUE_APP_DUT_DETAILS_URL, params);
  },
  getDutDetailsById(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_DUT_DETAILS_URL + id}/`);
  },
  saveDutDetail(payload) {
    return jwtInterceptor.post(process.env.VUE_APP_DUT_DETAILS_URL, payload);
  },
  updateDutDetail({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_DUT_DETAILS_URL + id}/`, data);
  },
  deleteDutDetail(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_DUT_DETAILS_URL + id}/`);
  },
  getQuoteAddresses() {
    return jwtInterceptor.get(process.env.VUE_APP_QUOTES_ADDRESS);
  },
  getQuoteAddress(id) {
    return jwtInterceptor.get(`${process.env.VUE_APP_QUOTES_ADDRESS + id}/`);
  },
  postQuoteAddress({ data }) {
    return jwtInterceptor.post(process.env.VUE_APP_QUOTES_ADDRESS, data);
  },
  patchQuoteAddress({ id, data }) {
    return jwtInterceptor.patch(`${process.env.VUE_APP_QUOTES_ADDRESS + id}/`, data);
  },
  deleteQuoteAddress(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_QUOTES_ADDRESS + id}/`);
  },
  postGerberLayerFile(data) {
    return jwtInterceptor.post(process.env.VUE_APP_GERBER_LAYER_URL, data);
  },
  deleteGerberLayerFile(id) {
    return jwtInterceptor.delete(`${process.env.VUE_APP_GERBER_LAYER_URL + id}/`);
  },
}
