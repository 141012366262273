<template>
  <v-tooltip right>
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        dark
        top
        left
        :color="color"
        @click="emitHandler('nextAction')"
        v-on="on"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>
    <span>Refresh Data</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "RefreshButton",
  props: {
    color: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitHandler(action) {
      this.$emit(action);
    },
  },
};
</script>
