<template>
  <v-bottom-sheet persistent v-model="dialog" @click:outside="onCancel" data-test="bottomSheet">
    <v-sheet class="text-center" height="200px">
      <div class="my-10 pt-12" data-app data-test="message">
        {{ message }}
      </div>
      <v-row justify="center" class="py-3">
        <v-btn class="mx-5" color="primary" dark @click="confirmed({ pk: 1 })" data-test="mmBtn" data-cy="mm-to-mils-mm-button"
          >mm</v-btn
        >
        <v-btn class="mx-5" color="primary" dark @click="confirmed({ pk:2 })" data-test="milsBtn" data-cy="mm-to-mils-mils-button">mils</v-btn>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "MmToMilsDialog",
  props: {
    message: String,
    dialog: Boolean,
    action: String,
  },
  methods: {
    confirmed(payload) {
      this.$emit(this.action, payload);
    },
    onCancel() {
      this.$emit("donothing");
    },
  },
};
</script>
