<template>
  <v-menu
    v-model="show"
    absolute
    :position-x="xCoord"
    :position-y="yCoord"
    offset-y
    :close-on-content-click="!showMenu"
  >
    <v-list>
      <v-list-item dense>
        <v-list-item-title class="moduleLink subtitle-1" @click="onClick"
          >{{ buttonText ? buttonText : "Open in new tab" }}
          <v-icon small class="ml-1">
            {{
              buttonText && buttonText != "Open in new tab"
                ? "mdi-link-off"
                : "mdi-open-in-new"
            }}
          </v-icon>
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        dense
        v-if="!buttonText || buttonText == 'Open in new tab'"
      >
        <v-list-item-title
          class="moduleLink subtitle-1"
          @click="onCopyUrl"
          >Copy URL
          <v-icon small class="ml-1">mdi-clipboard-text-multiple</v-icon>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "SubMenuPopup",
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
    xCoord: {
      type: Number,
      default: null,
    },
    yCoord: {
      type: Number,
      default: null,
    },
    nextAction: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: "Open in new tab",
    },
  },
  computed: {
    show: {
      get() {
        return this.showMenu;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick", this.nextAction);
    },
    onCopyUrl() {
      this.$emit("onCopyUrl", this.nextAction);
    },
  },
};
</script>
