<template>
  <v-card flat outlined max-height="200">
    <v-virtual-scroll :items="fileList" :item-height="30" max-height="200" data-test="item">
      <template v-slot:default="{ item }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-row dense align="center">
                <v-col cols="12" class="d-flex justify-center">
                  <span>{{ item }}</span>
                </v-col>
              </v-row></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </v-card>
</template>
<script>
export default {
  name: "ScrollFileList",
  props: {
    fileList: Array,
  },
};
</script>
